import { Recipe } from '@/components/dinner-recipes/Recipe';
import { useAuthenticatedAxios } from '@/context/api-auth';
import { useAuthenticatedApi } from '@/hooks/use-authenticated-api';
import { PaginatedListV1 } from '@/types/api-models';
import { getAssetBase } from '@/utils/asset-base';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { isNil } from 'lodash';
import z from 'zod';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { display } from 'styled-system';
import {
  useRecipeRatingV2,
  useUserRecipeRatings,
} from './use-user-recipe-ratings';

export type Recipe = {
  id: number;
  title: string;
  image: string;
  thumb: string;
  uri: string;
  mainIngredients: string;
  communityRating: number;
  pricePerServing: number;
  ratingCount: number;
  description: string;
  skillLevel: string;
  servingCount: number;
  prepTime: number;
  totalPrepTime: number;
  calories: number;
  protein: number;
  dietaryFiber: number;
  carbohydrates: number;
  totalSugars: number;
  fat: number;
  saturatedFat: number;
  transFattyAcid: number;
  cholesterol: number;
  sodium: number;
  smartPoints: number;
  ingredientsText: string;
  instructionsText: string;
  activePrepTime: number;
  meetsProfileFlag: 'green' | 'yellow' | 'red';
  yellowFields: string[];
  redFields: string[];
  /**
   * @deprecated use meetsProfileFlag
   */
  meetsAllNutritionRequirementsInProfile: boolean;
  /**
   * @deprecated use meetsProfileFlag
   */
  meetsPartiallyNutritionRequirementsInProfile: boolean;
  /**
   * @deprecated use meetsProfileFlag
   */
  meetsNoNutritionRequirementsInProfile: boolean;
  allowServings: number[];
  netCarbohydrates: number;
  source: string;
  sourceUrl: string;
  sourceLogoUrl: string;
  sourceLogoPath: string;
  maxAllowedServings: number;
  minAllowedServings: number;
  tipsText?: string;
};

export type RecipeRating = {
  id: number;
  firstRated: Date;
  lastRated: Date;
  rating: number;
  uri: string;
};

export type FavoriteRecipe = {
  id: number;
  recipeId: number;
  householdId: number;
  notes?: string;
  recipeTags?: Array<string>;
};

/**
 * Given the recipe allowed servings csv field, return the list as an array
 * @param counts
 * @returns
 */
export function getAllowedServings(counts: string | undefined) {
  return counts?.split('|')?.map((x) => Number.parseInt(x, 10)) ?? [];
}

/**
 *
 * @param response
 * @returns
 */
export const transformRecipeResponse = (response: any): Recipe => {
  /* TODO(steve): use runtime validation for transform[x] functions
  Use yup or ad hoc checks to ensure the data is correct before running data conversions.
  This might be difficult, since a number of these functions are run in the main component scope, 
  so exception-safety needs to be ensured
  */
  const assetBase = getAssetBase();

  const allowedServings = getAllowedServings(response.allowed_serving_counts);

  const maxAllowedServings = Math.max(...allowedServings);
  const minAllowedServings = Math.min(...allowedServings);
  let meetsProfileFlag: Recipe['meetsProfileFlag'];

  const yellowFields =
    isNil(response.yellow_fields) || response.yellow_fields === ''
      ? []
      : (response.yellow_fields as string).split(',');
  const redFields =
    isNil(response.red_fields) || response.red_fields === ''
      ? []
      : (response.red_fields as string).split(',');
  if (redFields.length > 0) {
    meetsProfileFlag = 'red';
  } else if (yellowFields.length > 0) {
    meetsProfileFlag = 'yellow';
  } else {
    meetsProfileFlag = 'green';
  }
  const servingCount = Math.floor(parseFloat(response.serving_count));

  return {
    id: response.id,
    title: response.title,
    image: response.image_path && new URL(response.image_path, assetBase).href,
    thumb:
      response.image_thumb_path &&
      new URL(response.image_thumb_path, assetBase).href,
    uri: response.resource_uri,
    communityRating: response.community_rating,
    ratingCount: response.rating_count,
    description: response.description,
    skillLevel: response.skill_level,
    servingCount,
    prepTime: response.active_prep_time,
    totalPrepTime: response.total_time,
    mainIngredients: response.main_ingredients,
    pricePerServing: response.price_per_serving,
    ingredientsText: response.ingredients_text,
    instructionsText: response.instructions_text,
    calories: response.calories_f,
    protein: response.protein_f,
    dietaryFiber: response.dietary_fiber_f,
    carbohydrates: response.carbohydrates_f,
    totalSugars: response.total_sugars_f,
    fat: response.fat_f,
    saturatedFat: response.saturated_fat_f,
    transFattyAcid: response.trans_fatty_acid_f,
    cholesterol: response.cholesterol_f,
    sodium: response.sodium_f,
    smartPoints: response.ww_points_f,
    activePrepTime: response.active_prep_time,
    meetsProfileFlag,
    meetsAllNutritionRequirementsInProfile: meetsProfileFlag === 'green',
    meetsPartiallyNutritionRequirementsInProfile: meetsProfileFlag === 'yellow',
    meetsNoNutritionRequirementsInProfile: meetsProfileFlag === 'red',
    allowServings: allowedServings,
    netCarbohydrates: computeNetCarbs(
      response.carbohydrates_f,
      response.dietary_fiber_f,
    ),
    source: response.source,
    sourceUrl: response.source_url,
    sourceLogoUrl: response.source_logo_url,
    sourceLogoPath: response.source_logo_path,
    maxAllowedServings: maxAllowedServings,
    minAllowedServings: minAllowedServings,
    tipsText: response.tips_text || undefined,
    yellowFields,
    redFields,
  };
};

export function getUseRecipeKey(params: {
  recipeId: number;
  servings?: number | undefined | null;
}) {
  return ['use-recipe', params] as const;
}

export const computeNetCarbs = (
  carbohydrates: number,
  dietaryFiber: number,
) => {
  const result = carbohydrates - dietaryFiber;
  return result > 0 ? result : 0;
};
export interface FormattedIngredients extends Record<string, any> {
  ingredients_text: string;
  ingredients: string[];
}

function formatRecipeIngredients(
  recipe: any,
  servings: number | undefined | null,
  scaledIngredientsObjects: any[],
) {
  const mappedIngredients = scaledIngredientsObjects.map(
    ({
      display_line,
      substitute_display_line,
    }: {
      display_line: string;
      substitute_display_line: string | undefined;
    }) => {
      if (substitute_display_line) {
        return `${display_line} <span class="ing_alternate_or">Or</span> ${substitute_display_line}`;
      } else {
        return display_line;
      }
    },
  );

  const formattedIngredients: FormattedIngredients = {
    ...recipe,
    ...(servings ? { serving_count: servings } : {}),
    ingredients: mappedIngredients,
    ingredients_text: `<ul><li>${mappedIngredients.join(
      '</li><li>',
    )}</li></ul>`,
  };
  return formattedIngredients;
}
export async function fetchRecipe(
  axios: AxiosInstance,
  params: { recipeId: number; servings?: number | undefined | null },
) {
  let { recipeId, servings } = params;
  const getData = <T>(resp: AxiosResponse<T>) => resp.data;
  const scaledIngredientParams: Record<string, any> = {
    recipe: recipeId,
    limit: 1000,
  };
  if (!isNil(servings)) {
    scaledIngredientParams.servings = servings;
  }
  const scaledIngredients = await axios
    .get<PaginatedListV1<any>>('/api/v1/scaled_recipe_ingredient', {
      params: scaledIngredientParams,
    })
    .then(getData);
  let firstIngredient =
    scaledIngredients.objects.length > 0
      ? scaledIngredients.objects[0]
      : undefined;
  if (firstIngredient && !servings) {
    servings = firstIngredient.servings;
  }

  const recipe = firstIngredient
    ? { ...firstIngredient.recipe, servings_count: servings }
    : undefined;
  const formattedRecipe = recipe
    ? formatRecipeIngredients(recipe, servings, scaledIngredients.objects)
    : undefined;

  return { recipe: formattedRecipe, scaledIngredients };
}

export function useRecipe(
  params: {
    recipeId: number;
    servings?: number | undefined | null;
  },
  queryOptions: Pick<UseQueryOptions, 'enabled'> = {},
) {
  const { axios, isAuthenticated } = useAuthenticatedAxios();
  const { recipeId, servings } = params;
  const {
    data,
    isLoading,
    isError,
    refetch: reloadRecipe,
    status,
    ...hook
  } = useQuery(
    getUseRecipeKey(params),
    ({ queryKey }) => fetchRecipe(axios, queryKey[1]),
    { ...queryOptions },
  );

  const { favoriteRecipe, refetch: reloadFavoriteRecipe } = useFavoriteRecipe(
    recipeId.toString(10),
  );
  const { recipeRating, refetch: reloadRecipeRating } = useRecipeRating(
    recipeId.toString(10),
  );

  const newUseRecipeRating = useRecipeRatingV2({ recipeIds: [recipeId] });

  const refetch = async () => {
    reloadRecipe && reloadRecipe();
    reloadFavoriteRecipe && reloadFavoriteRecipe();
    reloadRecipeRating && reloadRecipeRating();
  };
  let recipeData: any = data?.recipe;
  const recipe: Recipe | undefined =
    (recipeData && transformRecipeResponse(recipeData)) ?? undefined;

  return {
    recipe,
    favoriteRecipe,
    recipeRating,
    refetch,
    isLoading,
    isError,
    data,
    status,
    ...hook,
  };
}

export const useFavoriteRecipe = (recipeId: string) => {
  const { data, isLoading, isError, refetch } = useAuthenticatedApi({
    endpoint: `household_saved_recipe?recipe__in=${recipeId}`,
  });
  const favoriteRecipe =
    (data?.length > 0 && {
      id: data[0].id,
      notes: data[0].notes ?? '',
      ...(data[0].recipe_tags.length > 0 && {
        recipeTags: data[0].recipe_tags,
      }),
      recipeId: parseInt(data[0].recipe.replace('/api/v1/recipe/', '')),
      householdId: parseInt(
        data[0].household.replace('/api/v1/household/', ''),
      ),
    }) ||
    undefined;

  return { favoriteRecipe, refetch, isLoading, isError };
};

export const useRecipeRating = (recipeId: string) => {
  const { data, isLoading, isError, refetch } = useAuthenticatedApi({
    endpoint: `household_recipe_rating?recipe__in=${recipeId}`,
  });

  const recipeRating =
    (data?.length > 0 && {
      id: data[0].id,
      uri: data[0].resource_uri,
      firstRated: new Date(data[0].first_rated),
      lastRated: new Date(data[0].last_rated),
      rating: data[0].rating,
    }) ||
    undefined;

  return { recipeRating, refetch, isLoading, isError };
};
