import SlideDown from 'react-slidedown';
import React, { ReactNode } from 'react';
import 'react-slidedown/lib/slidedown.css';

type SlideProps = {
  children?: ReactNode;
};
export const Slide = ({ children }: SlideProps) => {
  return <SlideDown>{children}</SlideDown>;
};
