import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';
import { ReactNode, useLayoutEffect } from 'react';

import { Global, css, useTheme, Theme } from '@emotion/react';

const getStyles = (
  theme: Theme,
  horizontalLayout?: boolean,
  tabContentOverflow?: boolean,
) =>
  css`
    .dnt-tabs {
      color: ${theme.colors.tabsText};

      .dnt-tabs__list {
        background: ${theme.colors.tabsListBackground};
        padding: ${theme.space['xs']}px ${theme.space['xs']}px 0;
        cursor: pointer;
      }

      .dnt-tabs__tab {
        font-size: ${theme.fontSizes['4xs']};
        display: inline-block;
        background: ${theme.colors.tabsTabBackground};
        text-align: center;
        position: relative;
        list-style: none;
        cursor: pointer;
        font-weight: ${theme.fontWeights.bold};
        padding-top: ${theme.space['xs']}px;
        padding-bottom: ${theme.space['xs']}px;
        width: 130px;
        margin-right: ${theme.space['2xs']}px;

        ${theme.mediaQueries.print} {
          display: none;
        }
      }

      .dnt-tabs__tab--selected {
        background: ${theme.colors.white};
      }

      .dnt-tabs__tab--disabled {
        cursor: default;
      }

      .dnt-tabs__tab:focus {
        outline: none;
      }

      .dnt-tabs__tab:focus:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: ${theme.colors.white};
      }

      .dnt-tabs__tab-panel {
        display: none;
        padding: ${theme.space.s}px;
        overflow: ${tabContentOverflow ? 'auto' : 'none'};
      }

      .dnt-tabs__tab-panel--selected {
        display: block;
      }
    }

    ${horizontalLayout &&
    `@media only screen and (max-width: ${theme.breakpoints[3]}) and (min-width: ${theme.breakpoints[1]}) {
        .dnt-tabs {
          display: flex;
          flex-direction: row;

          .dnt-tabs__list {
            display: flex;
            flex-direction: column;
            margin-right: ${theme.space.s}px;
            background-color: ${theme.colors.white};
            border-right: 1px solid ${theme.colors.tabsBorderColor}
          }

          .dnt-tabs__tab {
            margin-right: 0;
            width: 100px;
          }

          .dnt-tabs__tab-panel {
            flex-grow: 1;
          }
        }
      }
    `}

    ${horizontalLayout &&
    `@media only screen and (max-width: ${theme.breakpoints[1]}) {
      .dnt-tabs {
        .dnt-tabs__list {
          display: flex;
          flex-direction: row;
          overflow: auto;

          .dnt-tabs__tab {
            flex-shrink: 0;
          }
        }
      }
    `}
  `;

export type TabItem = {
  title: ReactNode | string;
  content: ReactNode;
};

export type TabsProps = {
  items: TabItem[];
  /*
   * This allows changing the tab that should be open on initial render.
   * This is a zero-based index, so first tab is 0, second tab is 1, ...
   * */
  defaultIndex?: number;
  /*
  This event handler is called every time a tab is about to change.
  It will be called with the index that it will be changed to, the lastIndex which was selected before and the underlying
  event which is usually either a keydown or click event. When index and lastIndex are equal it means the user clicked
  on the currently active tab. The callback can optionally return false to cancel the change to the new tab.
  * */
  onSelect?: (index: number, lastIndex: number, event: Event) => boolean | void;

  /**
   * If true, the tabs will be rendered in a responsive way.
   * This means that the layout of tabs and panel would change from vertical to horizontal on smaller screens.
   */
  horizontalLayout?: boolean;
  tabContentOverflow?: boolean;
};

export const Tabs = ({
  items,
  defaultIndex = 0,
  onSelect,
  horizontalLayout,
  tabContentOverflow,
}: TabsProps) => {
  const theme = useTheme();

  useLayoutEffect(() => {
    if (!window) return;
    const defaultSelectedTab = document.querySelector(
      `.dnt-tabs__tab:nth-child(${defaultIndex})`,
    );

    if (defaultSelectedTab) {
      defaultSelectedTab.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, []);

  return (
    <>
      <Global styles={getStyles(theme, horizontalLayout, tabContentOverflow)} />

      <ReactTabs
        className={'dnt-tabs'}
        defaultIndex={defaultIndex}
        onSelect={onSelect}
      >
        <TabList className={'dnt-tabs__list'}>
          {items.map((x, index) => (
            <Tab
              className={'dnt-tabs__tab'}
              selectedClassName={'dnt-tabs__tab--selected'}
              disabledClassName={'dnt-tabs__tab--disabled'}
              key={`tab-${index}`}
            >
              {x.title}
            </Tab>
          ))}
        </TabList>

        {items.map((x, index) => (
          <TabPanel
            className={'dnt-tabs__tab-panel'}
            selectedClassName={'dnt-tabs__tab-panel--selected'}
            key={`tab-panel-${index}`}
          >
            {x.content}
          </TabPanel>
        ))}
      </ReactTabs>
    </>
  );
};
