import { IngredientFilter } from '@/hooks/use-search-recipes';
import { StateCreator } from 'zustand';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { immer } from 'zustand/middleware/immer';

export interface RecipeBoxSearchOptions {
  keyword?: string;
  /**
   * list of ingredients (referred by rec_engine_id) to filter search by
   */
  ingredients: IngredientFilter[];
  courses: string[];
  ratings: string[];
  defaultTabSelected?: number;
  dinnerDate?: Date;
}
export const initialSearchOptions: RecipeBoxSearchOptions = {
  keyword: '',
  ingredients: [],
  courses: [],
  ratings: [],
};
interface State {
  recipeBoxSearch: RecipeBoxSearchOptions;
  /**
   * Hints the context where the recipe box was opened. If opened from the meal-planner-dropdown,
   * the search parameter's dinner date should be used to create a new dinner
   */
  recipeBoxStatus: 'open' | 'closed';
  recipeBoxIsOpen: () => boolean;
}
interface Actions {
  setRecipeBoxSearch: (search: RecipeBoxSearchOptions | null) => void;
  updateRecipeBoxSearch: (search: Partial<RecipeBoxSearchOptions>) => void;
  setRecipeBoxIngredients: (ingredients: IngredientFilter[]) => void;
}
export type RecipeBoxSlice = Actions & State;
export const createRecipeBoxSlice: StateCreator<
  RecipeBoxSlice,
  [['zustand/immer', never]],
  []
> = (set, get) => ({
  recipeBoxStatus: 'closed',
  recipeBoxSearch: initialSearchOptions,
  updateRecipeBoxSearch: (search) => {
    return get().setRecipeBoxSearch({ ...get().recipeBoxSearch, ...search });
  },
  setRecipeBoxSearch: (search) =>
    set((state) => {
      if (isNil(search)) {
        state.recipeBoxStatus = 'closed';
        state.recipeBoxSearch = initialSearchOptions;
      } else {
        state.recipeBoxStatus = 'open';
        state.recipeBoxSearch = search;
      }
    }),

  setRecipeBoxIngredients: (ingredients) => {
    const newRecipeBox: RecipeBoxSearchOptions = {
      ...(get().recipeBoxSearch ?? initialSearchOptions),
      ingredients: ingredients,
    };
    get().setRecipeBoxSearch(newRecipeBox);
  },
  recipeBoxIsOpen: () => get().recipeBoxStatus === 'open',
});
