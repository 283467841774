import React from 'react';
import { Modal, ModalContent, ModalOverlay } from '@/components/base/modal';
import ReactCalendar from 'react-calendar';
import styled from '@emotion/styled';
import { Box } from '@/components/base/box';
import 'react-calendar/dist/Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Flex } from '@/components/base/flex';
import {
  formatDate,
  getTodayDate,
  isSameDate,
  lastDayOfYear,
} from '@/utils/format-date';
import { useShoppingTrips } from '@/hooks/use-shopping-trips';
import { useHouseholdDinner } from '@/hooks/use-household-dinner';
import { useTheme } from '@emotion/react';

const StyledModalContent = styled(ModalContent)`
  background: none;
`;

const StyledTitle = styled(Flex)`
  background: none;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledDatepicker = styled(Box)`
  .dt-app-react-meal-date-picker {
    border-radius: ${({ theme }) => theme.radii['3xs']}px;
    border: none;
    width: 500px;

    .react-calendar__navigation {
      height: 50px;
      margin-bottom: 0;
      background-color: ${({ theme }) =>
        theme.colors.mealDatePickerCalendarNavigationBackground};
      color: ${({ theme }) => theme.colors.white};
      font-size: ${({ theme }) => theme.fontSizes['2xl']};

      .react-calendar__navigation__label {
        color: ${({ theme }) => theme.colors.white};
        text-transform: uppercase;
        font-size: ${({ theme }) => theme.fontSizes['2xl']};
        font-family: ${({ theme }) => theme.fonts.body};

        .react-calendar__navigation__label__labelText {
          font-weight: ${({ theme }) => theme.fontWeights.extraLight};
        }

        &:hover {
          background-color: ${({ theme }) =>
            theme.colors.mealDatePickerCalendarNavigationBackground};
        }
      }

      .react-calendar__navigation__prev-button[disabled] {
        display: none;
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      text-transform: none;
      text-decoration: none;
      background-color: ${({ theme }) =>
        theme.colors.mealDatePickerCalendarWeekDayBackground};
      color: ${({ theme }) => theme.colors.white};

      & abbr {
        text-decoration: none;
      }
    }

    .dt-app-react-meal-date-picker__tile {
      padding: ${({ theme }) => theme.space['3xs']}px;

      &[disabled] {
        background: none;
        abbr,
        .custom-content {
          color: ${({ theme }) => theme.colors.mealDatePickerCalendarTileText};
          background: ${({ theme }) =>
            theme.colors.mealDatePickerCalendarTileBackground};
          text-decoration: line-through;
        }
      }

      abbr {
        display: none;
      }

      .custom-content {
        width: 72px;
        height: 72px;
        transition: background-color 0.2s ease, background 0.2s ease,
          color 0.2s ease;
        color: ${({ theme }) => theme.colors.bodyText};
        text-align: center;
        font-weight: normal;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          color: ${({ theme }) =>
            theme.colors.mealDatePickerCalendarTileCustomContentHoverText};
          background-color: ${({ theme }) =>
            theme.colors
              .mealDatePickerCalendarTileCustomContentHoverBackground};
        }

        &.is-shopping-date-and-is-meal-planned-date {
          background: linear-gradient(
            -45deg,
            ${({ theme }) =>
                theme.colors.mealDatePickerCalendarTileShoppingTrip}
              0%,
            ${({ theme }) =>
                theme.colors.mealDatePickerCalendarTileShoppingTrip}
              50%,
            ${({ theme }) => theme.colors.mealDatePickerCalendarTileMealPlaned}
              50%
          );
          color: ${({ theme }) => theme.colors.white};
        }

        &.is-meal-planned-date {
          background: ${({ theme }) =>
            theme.colors.mealDatePickerCalendarTileMealPlaned};
          color: ${({ theme }) => theme.colors.white};
        }

        &.is-shopping-date {
          background: ${({ theme }) =>
            theme.colors.mealDatePickerCalendarTileShoppingTrip};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }

    .dt-app-react-meal-date-picker__tile.react-calendar__month-view__days__day--neighboringMonth {
      abbr,
      .custom-content {
        color: ${({ theme }) => theme.colors.mealDatePickerCalendarTileText};
      }
    }

    .react-calendar__tile--now {
      background: none;
    }
  }
`;

const StyledLegend = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.mealDatePickerLegendBackground};
  max-width: 500px;
  position: relative;
  color: white;
`;

const StyledLegendItem = styled(Flex)`
  align-items: center;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  margin: 0;
  padding: 15px;
  transition: background-color 0.2s ease;
  cursor: pointer;

  > * {
    cursor: pointer;
  }
`;

const StyledLegendItemBackground = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: ${({ theme }) => theme.radii['2xs']}px;
  margin-right: ${({ theme }) => theme.space['xs']}px;
`;

const StyledLegendItemText = styled(Box)`
  color: ${({ theme }) => theme.colors.bodyText};
  font-size: ${({ theme }) => theme.fontSizes['2xs']};
`;

const StyledCancelButton = styled(Box)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.space.m}px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.l};
`;

type MealDatePickerProps = {
  isOpen: boolean;
  mealName: string;
  onChange?: (value: Date, dinnerId?: number) => void;
  onClose?: () => void;
};
export const MealDatePicker: React.FC<MealDatePickerProps> = ({
  isOpen,
  mealName,
  onClose = () => {},
  onChange = () => {},
}) => {
  const theme = useTheme();
  const startDate = getTodayDate();
  const endDate = lastDayOfYear(startDate);

  const { shoppingTrips } = useShoppingTrips(startDate, endDate);
  const { dinners = [] } = useHouseholdDinner(startDate, endDate);

  const shoppingDates: Date[] = shoppingTrips.map((x) => x.date);
  const mealPlannedDates: Date[] = dinners.map((x) => x.date);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onClose}
      modalPosition={'top'}
      maxHeight={'100%'}
      marginTop={'8xl'}
    >
      <ModalOverlay />
      <StyledModalContent>
        <StyledTitle justifyContent={'center'} marginBottom={'s'}>
          Select a day to prepare {mealName}
        </StyledTitle>

        <StyledDatepicker>
          <ReactCalendar
            prev2Label={null}
            next2Label={null}
            prevLabel={
              <FontAwesomeIcon
                icon={faChevronLeft}
                color={theme.colors.white}
                size={'sm'}
              />
            }
            nextLabel={
              <FontAwesomeIcon
                icon={faChevronRight}
                color={theme.colors.white}
                size={'sm'}
              />
            }
            calendarType={'US'}
            className={'dt-app-react-meal-date-picker'}
            defaultView={'month'}
            minDate={startDate}
            onChange={(value: Date) => {
              if (onChange) {
                const dinner = dinners.find((x) => isSameDate(x.date, value));
                onChange(value, dinner?.id);
              }
            }}
            tileClassName={'dt-app-react-meal-date-picker__tile'}
            formatShortWeekday={(locale: string, date: Date) => {
              return date
                .toLocaleDateString('en-US', { weekday: 'short' })
                .substring(0, 2);
            }}
            formatMonthYear={(locale: string, date: Date) => {
              return formatDate(date, 'MMMM');
            }}
            showNeighboringMonth={true}
            tileContent={({ date, view }) => {
              const isShoppingDate = shoppingDates?.some((x) =>
                isSameDate(x, date),
              );
              const isMealPlannedDate = mealPlannedDates?.some((x) =>
                isSameDate(x, date),
              );
              let cssClass = '';
              if (isShoppingDate && isMealPlannedDate)
                cssClass = 'is-shopping-date-and-is-meal-planned-date';
              else if (isShoppingDate) cssClass = 'is-shopping-date';
              else if (isMealPlannedDate) cssClass = 'is-meal-planned-date';
              return (
                <div className={`custom-content ${cssClass}`}>
                  {formatDate(date, 'd')}
                </div>
              );
            }}
          />
        </StyledDatepicker>

        <StyledLegend>
          <StyledLegendItem>
            <StyledLegendItemBackground
              backgroundColor={'mealDatePickerCalendarTileShoppingTrip'}
            ></StyledLegendItemBackground>
            <StyledLegendItemText>Shopping Trip</StyledLegendItemText>
          </StyledLegendItem>

          <StyledLegendItem>
            <StyledLegendItemBackground
              backgroundColor={'mealDatePickerCalendarTileMealPlaned'}
            ></StyledLegendItemBackground>
            <StyledLegendItemText>Meal Planned</StyledLegendItemText>
          </StyledLegendItem>
        </StyledLegend>

        <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
      </StyledModalContent>
    </Modal>
  );
};
