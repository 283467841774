import { createContext, useMemo } from 'react';
import { Configuration } from '@/api-client/client-v2/configuration';
import { useAuthenticatedAxios } from './authenticated-axios-provider';
import { clientBaseUrl } from '@/api-client/axios';

export function getV2Configuration(authHeader?: string) {
  const basePath = clientBaseUrl();
  return new Configuration({
    baseOptions: { headers: { Authorization: authHeader } },
    basePath,
  });
}

export function useV2Configuration() {
  const { isAuthenticated, authHeader, axios } = useAuthenticatedAxios();
  const config = useMemo(
    () => getV2Configuration(authHeader),
    [isAuthenticated, authHeader],
  );
  return { config };
}

export type ApiConstructor<TApi> = {
  new (configuration?: Configuration, basePath?: string): TApi;
};

/**
 * Creates API object using configuration based on current authentication session
 * @param ctor
 * @returns
 */
export function useV2Api<TApi>(ctor: {
  new (configuration?: Configuration): TApi;
}) {
  const { config } = useV2Configuration();
  const api = useMemo(() => new ctor(config), [config]);
  return { api };
}
