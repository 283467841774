import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { Text } from '@/components/base/text';
import { TextLink } from '@/components/base/text-link';
import { Box } from '@/components/base/box';
import { Image } from '@/components/base/image';
import { Flex } from '@/components/base/flex';
import { Rating } from '@/components/base/rating';
import { Servings } from '@/components/servings';
import { Favorite } from '@/components/base/favorite';
import { Dish } from '@/hooks/use-household-dinner';
import useCommandRequest from '@/hooks/use-command-request';
import removeFavoriteRecipe from '@/services/remove-favorite-recipe';
import saveFavoriteRecipe from '@/services/save-favorite-recipe';
import updateRateRecipe from '@/services/update-rate-recipe';
import rateRecipe from '@/services/rate-recipe';
import removeRateRecipe from '@/services/remove-rate-recipe';
import { useFavoriteRecipeCheck } from '@/hooks/use-favorite-recipes';
import { useScoreCardEvent } from '@/hooks/use-score-card-event';
import { useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import {
  RECIPE_RATING_QUERY_KEY_ROOT,
  recipeRatingQueryKey,
} from '@/hooks/use-user-recipe-ratings';

const StyledImage = styled(Box)`
  & > a > img {
    border-radius: ${({ theme }) => theme.radii['2xs']};
    box-shadow: 0 1px ${({ theme }) => theme.space['2xs']}px
      ${({ theme }) => theme.colors.textGray55};
    cursor: pointer;
    width: 100%;
    max-height: 200px;
  }
`;

StyledImage.defaultProps = {
  textAlign: 'center',
  paddingBottom: 'm',
};

const StyledHero = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${({ theme }) => theme.mediaQueries.print} {
    display: none;
  }
`;

StyledHero.defaultProps = {
  backgroundColor: 'dinnerRecipeBoxBackgroundColor',
  paddingX: 's',
  paddingTop: 'm',
};

type RecipeContentProps = {
  householdId: number;
  dish: Dish;
  refreshDinner: () => void;
  minDescriptionHeight: number;
  updateMinDescriptionHeight: (height: number) => void;
};

export const RecipeContent = ({
  dish: {
    recipeRating,
    recipe: {
      id,
      thumb,
      description,
      skillLevel,
      communityRating,
      prepTime,
      totalPrepTime,
      ratingCount,
    },
  },
  householdId,
  minDescriptionHeight,
  updateMinDescriptionHeight,
}: RecipeContentProps) => {
  const theme = useTheme();
  const qc = useQueryClient();
  const { send: sendRateRecipeCommand } = useCommandRequest();
  const { send: sendDeleteRateRecipeCommand } = useCommandRequest();
  const { savedFavorite, refreshRecipe } = useFavoriteRecipeCheck(id);
  const isFavorite = !!savedFavorite;
  const { isLoading: isSendingFavorite, send: sendFavoriteCommand } =
    useCommandRequest();
  const toggleFavorite = async () => {
    const result = await sendFavoriteCommand(() =>
      isFavorite
        ? removeFavoriteRecipe({ savedRecipeId: savedFavorite.savedRecipeId })
        : saveFavoriteRecipe({ householdId: householdId, recipeId: id }),
    );

    if (result.completed) {
      await refreshRecipe();
    }
  };
  const { sendScoreCardEvent } = useScoreCardEvent();

  const descriptionRef = useRef<HTMLDivElement>(null);

  const onRateRecipe = async (newValue: number) => {
    const addNewRating = !recipeRating;
    const result = await sendRateRecipeCommand(() =>
      recipeRating
        ? updateRateRecipe({
            recipeId: recipeRating.id,
            rating: newValue,
          })
        : rateRecipe({
            householdId,
            recipeId: id,
            rating: newValue,
          }),
    );
    const promises: Promise<any>[] = [];
    if (result.completed) {
      promises.push(refreshRecipe());
      if (addNewRating) {
        promises.push(sendScoreCardEvent('RATE_RECIPE'));
      }
    }
    await Promise.all(promises);
    await qc.invalidateQueries(RECIPE_RATING_QUERY_KEY_ROOT);
  };

  const onClearRating = async () => {
    if (!recipeRating) return;

    const result = await sendDeleteRateRecipeCommand(() =>
      removeRateRecipe(recipeRating.id),
    );
    if (result.completed) {
      refreshRecipe();
    }
  };

  useEffect(() => {
    if (descriptionRef.current) {
      updateMinDescriptionHeight(descriptionRef.current.offsetHeight);
    }
  }, [descriptionRef.current]);

  return (
    <StyledHero>
      <StyledImage>
        <TextLink href={`/recipes/${id}`}>
          <Image src={thumb} alt="Thumb" />
        </TextLink>
      </StyledImage>
      <Box
        borderBottom={`${theme.radii['4xs']} solid ${theme.colors.borderColor}`}
        marginBottom={'xs'}
        paddingBottom={'xs'}
        minHeight={minDescriptionHeight}
        ref={descriptionRef}
      >
        <Text>{description}</Text>
      </Box>

      <Flex justifyContent={'space-between'} paddingRight={'l'} flexWrap="wrap">
        <Box>
          <Flex>
            <Text>Your Rating:</Text>
            <Rating
              onChange={onRateRecipe}
              onClearRating={onClearRating}
              showTooltip={true}
              highlightFilled={true}
              allowFraction={false}
              value={(recipeRating && recipeRating.rating) ?? 0}
            />
          </Flex>
          <Flex>
            <Text>Community:</Text>
            <Rating value={communityRating} readonly={true} />
            <Text marginLeft={'2xs'}>({ratingCount})</Text>
          </Flex>
        </Box>
        <Servings prepTime={prepTime} totalTime={totalPrepTime} />
      </Flex>
      <Flex
        marginBottom={'xs'}
        marginTop={'s'}
        justifyContent={'space-between'}
      >
        <Text>
          <Text
            as="span"
            variant={'3xsBold'}
            style={{
              marginRight: 4,
            }}
          >
            Skill:
          </Text>
          <Text as="span">{skillLevel}</Text>
        </Text>
        <Box>
          <Favorite
            onToggle={toggleFavorite}
            isActive={isFavorite}
            isLoading={isSendingFavorite}
          />
        </Box>
      </Flex>
    </StyledHero>
  );
};
