import { useNotificationCenterStore } from '@/store/notification-center-store';

export const useNotificationCenter = () => {
  const setNotificationMessage = useNotificationCenterStore(
    (state) => state?.setNotificationMessage,
  );

  const setScoreCardNotificationMessage = useNotificationCenterStore(
    (state) => state?.setScoreCardNotificationMessage,
  );

  const setNotificationBottomText = useNotificationCenterStore(
    (state) => state?.setNotificationBottomText,
  );

  const showSuccessNotification = (text: string) => {
    setNotificationMessage({
      type: 'success',
      text,
    });
  };

  const showInfoNotification = (text: string) => {
    setNotificationMessage({
      type: 'info',
      text,
    });
  };

  const showErrorNotification = (text: string) => {
    setNotificationMessage({
      type: 'error',
      text,
    });
  };

  const showPointsEarned = (text: string) => {
    setScoreCardNotificationMessage({ text });
  };

  const showBottomNotification = (text: string) => {
    setNotificationBottomText(text);
  };

  return {
    showSuccessNotification,
    showInfoNotification,
    showErrorNotification,
    showPointsEarned,
    showBottomNotification,
  };
};
