import { FetchNextPageOptions } from 'react-query';
import { Button } from '../base/buttons';
import { Text } from '../base/text';
export interface InfiniteLoaderControlProps {
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown> | unknown;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
}

export function InfiniteLoaderControl({
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: InfiniteLoaderControlProps) {
  if (isFetchingNextPage) {
    return (
      <Text data-testid="loading-more-recipes-status">
        Loading More Recipes...
      </Text>
    );
  } else if (hasNextPage) {
    return (
      <Button
        data-testid="load-more-recipes-button"
        onClick={() => fetchNextPage()}
      >
        Load More Recipes
      </Button>
    );
  } else {
    return <></>;
  }
}
