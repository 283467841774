/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { PartnerAuth } from '../models';
/**
 * V1PartnerAuthApi - axios parameter creator
 * @export
 */
export const V1PartnerAuthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {PartnerAuth} partnerAuth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerAuthCreate: async (
      partnerAuth: PartnerAuth,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'partnerAuth' is not null or undefined
      assertParamExists('partnerAuthCreate', 'partnerAuth', partnerAuth);
      const localVarPath = `/api/v1/partner-auth/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        partnerAuth,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V1PartnerAuthApi - functional programming interface
 * @export
 */
export const V1PartnerAuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    V1PartnerAuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {PartnerAuth} partnerAuth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerAuthCreate(
      partnerAuth: PartnerAuth,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerAuth>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.partnerAuthCreate(partnerAuth, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V1PartnerAuthApi - factory interface
 * @export
 */
export const V1PartnerAuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V1PartnerAuthApiFp(configuration);
  return {
    /**
     *
     * @param {PartnerAuth} partnerAuth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerAuthCreate(
      partnerAuth: PartnerAuth,
      options?: any,
    ): AxiosPromise<PartnerAuth> {
      return localVarFp
        .partnerAuthCreate(partnerAuth, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for partnerAuthCreate operation in V1PartnerAuthApi.
 * @export
 * @interface V1PartnerAuthApiPartnerAuthCreateRequest
 */
export interface V1PartnerAuthApiPartnerAuthCreateRequest {
  /**
   *
   * @type {PartnerAuth}
   * @memberof V1PartnerAuthApiPartnerAuthCreate
   */
  readonly partnerAuth: PartnerAuth;
}

/**
 * V1PartnerAuthApi - object-oriented interface
 * @export
 * @class V1PartnerAuthApi
 * @extends {BaseAPI}
 */
export class V1PartnerAuthApi extends BaseAPI {
  /**
   *
   * @param {V1PartnerAuthApiPartnerAuthCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1PartnerAuthApi
   */
  public partnerAuthCreate(
    requestParameters: V1PartnerAuthApiPartnerAuthCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1PartnerAuthApiFp(this.configuration)
      .partnerAuthCreate(requestParameters.partnerAuth, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
