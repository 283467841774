import {
  parse,
  getDaysInMonth,
  startOfMonth,
  lastDayOfWeek as D_FNS_lastDayOfWeek,
  sub,
  add,
  isToday,
  compareAsc,
  isSameDay,
  isValid,
  lastDayOfYear as D_FNS_lastDayOfYear,
} from 'date-fns';
import { default as dateFormat } from 'date-fns/format';

export function isValidDate(date: Date) {
  return isValid(date);
}

export function formatDate(date: Date, format: string = 'yyyy-MM-dd'): string {
  return dateFormat(date, format);
}

export function formatDateAsFullDayMonthNumberDay(date: Date): string {
  return dateFormat(date, 'EEEE, LLLL do');
}

export function dateFromStr(
  dateStr: string,
  dateFormat: string = 'yyyy-MM-dd',
): Date {
  return parse(dateStr, dateFormat, new Date());
}

export function getDateSuffix(date: Date): string {
  const dateWithSuffix = formatDate(date, 'do');
  return dateWithSuffix.substring(dateWithSuffix.length - 2);
}

export function getTodayDate() {
  return new Date();
}

export function isDateToday(date: Date): boolean {
  return isToday(date);
}

export function isDateBeforeToday(date: Date): boolean {
  const today = getTodayDate();
  return (
    compareAsc(
      new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    ) === -1
  );
}

export function lastDayOfMonth(month: number, year: number): number {
  return getDaysInMonth(new Date(year, month));
}

export function firstDayOfMonth(date: Date): number {
  return startOfMonth(date).getDate();
}

export function dateNextMonth(date: Date): Date {
  return add(date, { months: 1 });
}

export function lastDayOfWeek(date: Date): Date {
  return D_FNS_lastDayOfWeek(date);
}

export function firstDayOfWeek(date: Date): Date {
  const lastDate = D_FNS_lastDayOfWeek(date);
  return sub(lastDate, { days: 6 });
}

export function lastDayInCalendarView(startDate: Date): Date {
  return sub(add(startDate, { weeks: 4 }), { days: 1 });
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return isSameDay(date1, date2);
}

export function previousDate(date: Date, days: number = 1): Date {
  return sub(date, { days });
}

export function nextDate(date: Date, days: number = 1): Date {
  return add(date, { days });
}

export function previousWeek(date: Date): Date {
  return sub(date, { weeks: 1 });
}

export function nextWeek(date: Date): Date {
  return add(date, { weeks: 1 });
}

export function fromDateStr(date: string) {
  // TODO: Use date-fns parseISO, since datetimes are already
  // serialized with timezone if necessary
  const dt = new Date(date);
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
}

export function lastDayOfYear(date: Date) {
  return D_FNS_lastDayOfYear(date);
}
