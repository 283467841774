/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { OpcoBranding } from '../models';
// @ts-ignore
import { PaginatedOpcoBrandingList } from '../models';
/**
 * V1OpcobrandingApi - axios parameter creator
 * @export
 */
export const V1OpcobrandingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peapodOpcosList: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/peapod-opcos/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} opco
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peapodOpcosRetrieve: async (
      opco: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'opco' is not null or undefined
      assertParamExists('peapodOpcosRetrieve', 'opco', opco);
      const localVarPath = `/api/v1/peapod-opcos/{opco}/`.replace(
        `{${'opco'}}`,
        encodeURIComponent(String(opco)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V1OpcobrandingApi - functional programming interface
 * @export
 */
export const V1OpcobrandingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    V1OpcobrandingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async peapodOpcosList(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedOpcoBrandingList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.peapodOpcosList(
        limit,
        offset,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} opco
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async peapodOpcosRetrieve(
      opco: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpcoBranding>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.peapodOpcosRetrieve(opco, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V1OpcobrandingApi - factory interface
 * @export
 */
export const V1OpcobrandingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V1OpcobrandingApiFp(configuration);
  return {
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peapodOpcosList(
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<PaginatedOpcoBrandingList> {
      return localVarFp
        .peapodOpcosList(limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} opco
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peapodOpcosRetrieve(
      opco: string,
      options?: any,
    ): AxiosPromise<OpcoBranding> {
      return localVarFp
        .peapodOpcosRetrieve(opco, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for peapodOpcosList operation in V1OpcobrandingApi.
 * @export
 * @interface V1OpcobrandingApiPeapodOpcosListRequest
 */
export interface V1OpcobrandingApiPeapodOpcosListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof V1OpcobrandingApiPeapodOpcosList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof V1OpcobrandingApiPeapodOpcosList
   */
  readonly offset?: number;
}

/**
 * Request parameters for peapodOpcosRetrieve operation in V1OpcobrandingApi.
 * @export
 * @interface V1OpcobrandingApiPeapodOpcosRetrieveRequest
 */
export interface V1OpcobrandingApiPeapodOpcosRetrieveRequest {
  /**
   *
   * @type {string}
   * @memberof V1OpcobrandingApiPeapodOpcosRetrieve
   */
  readonly opco: string;
}

/**
 * V1OpcobrandingApi - object-oriented interface
 * @export
 * @class V1OpcobrandingApi
 * @extends {BaseAPI}
 */
export class V1OpcobrandingApi extends BaseAPI {
  /**
   *
   * @param {V1OpcobrandingApiPeapodOpcosListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1OpcobrandingApi
   */
  public peapodOpcosList(
    requestParameters: V1OpcobrandingApiPeapodOpcosListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return V1OpcobrandingApiFp(this.configuration)
      .peapodOpcosList(
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V1OpcobrandingApiPeapodOpcosRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1OpcobrandingApi
   */
  public peapodOpcosRetrieve(
    requestParameters: V1OpcobrandingApiPeapodOpcosRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1OpcobrandingApiFp(this.configuration)
      .peapodOpcosRetrieve(requestParameters.opco, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
