import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { Flex } from '@/components/base/flex';
import { Box } from '@/components/base/box';
import { ItalicText, Text } from '@/components/base/text';
import { Button } from '@/components/base/buttons';
import { getProfileIconColor } from '@/utils/profile-color';
import { HouseholdProfile } from '@/hooks/use-household-profiles';

const StyledDropdown = styled(Flex)`
  :hover {
    background-color: ${({ theme }) => theme.colors.primaryLight200};
    color: ${({ theme }) => theme.colors.white};
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const StyledProfileText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

const StyledProfileIcon = styled(Box)<{ profileId: string }>`
  background-color: ${({ profileId }) => getProfileIconColor(profileId)};
  height: ${({ theme }) => theme.space.m}px;
  min-height: ${({ theme }) => theme.space.m}px;
  min-width: ${({ theme }) => theme.space.m}px;
  width: ${({ theme }) => theme.space.m}px;
`;

const StyledActionButton = styled(Button)`
  margin-left: auto;
`;

type ProfileSubNavItemProps = {
  data: HouseholdProfile;
  confirmDelete: (profile: HouseholdProfile) => void;
};
export const ProfileSubNavItem = ({
  data: { id, name, isDefault },
  data: profile,
  confirmDelete,
}: ProfileSubNavItemProps) => {
  const theme = useTheme();

  return (
    <Link key={id} href={`/household_profiles/${id}/edit`} legacyBehavior>
      <StyledDropdown paddingX="xs">
        <StyledProfileIcon
          profileId={`${id}`}
          borderRadius="xs"
          margin={'xs'}
        />
        <StyledProfileText variant="2xs" margin="2xs">
          {name}{' '}
          {isDefault ? (
            <ItalicText as="span" variant="3xs">
              (default)
            </ItalicText>
          ) : (
            ''
          )}
        </StyledProfileText>
        {!isDefault && (
          <StyledActionButton
            variant="transparent"
            onClick={(e) => {
              e.preventDefault();

              confirmDelete(profile);
            }}
          >
            <FontAwesomeIcon icon={faTrash} color={theme.colors.primary400} />
          </StyledActionButton>
        )}
      </StyledDropdown>
    </Link>
  );
};
