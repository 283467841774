/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { HouseholdSavedRecipe } from '../models';
// @ts-ignore
import { HouseholdSavedRecipeSerializerNoDetails } from '../models';
// @ts-ignore
import { PaginatedHouseholdSavedRecipeSerializerNoDetailsList } from '../models';
// @ts-ignore
import { PatchedHouseholdSavedRecipeSerializerNoDetails } from '../models';
/**
 * V2HouseholdsavedrecipeApi - axios parameter creator
 * @export
 */
export const V2HouseholdsavedrecipeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {HouseholdSavedRecipeSerializerNoDetails} householdSavedRecipeSerializerNoDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesCreate: async (
      householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'householdSavedRecipeSerializerNoDetails' is not null or undefined
      assertParamExists(
        'savedRecipesCreate',
        'householdSavedRecipeSerializerNoDetails',
        householdSavedRecipeSerializerNoDetails,
      );
      const localVarPath = `/api/v2/saved-recipes/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        householdSavedRecipeSerializerNoDetails,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesDestroy: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('savedRecipesDestroy', 'id', id);
      const localVarPath = `/api/v2/saved-recipes/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {number} [recipe]
     * @param {Array<number>} [recipeIn] Multiple values may be separated by commas.
     * @param {string} [search]
     * @param {boolean} [showRecipeDetails] If true, include full recipe details in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesList: async (
      limit?: number,
      offset?: number,
      recipe?: number,
      recipeIn?: Array<number>,
      search?: string,
      showRecipeDetails?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/saved-recipes/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (recipe !== undefined) {
        localVarQueryParameter['recipe'] = recipe;
      }

      if (recipeIn) {
        localVarQueryParameter['recipe__in'] = recipeIn.join(
          COLLECTION_FORMATS.csv,
        );
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (showRecipeDetails !== undefined) {
        localVarQueryParameter['show-recipe-details'] = showRecipeDetails;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {PatchedHouseholdSavedRecipeSerializerNoDetails} [patchedHouseholdSavedRecipeSerializerNoDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesPartialUpdate: async (
      id: number,
      patchedHouseholdSavedRecipeSerializerNoDetails?: PatchedHouseholdSavedRecipeSerializerNoDetails,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('savedRecipesPartialUpdate', 'id', id);
      const localVarPath = `/api/v2/saved-recipes/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedHouseholdSavedRecipeSerializerNoDetails,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('savedRecipesRetrieve', 'id', id);
      const localVarPath = `/api/v2/saved-recipes/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {HouseholdSavedRecipeSerializerNoDetails} householdSavedRecipeSerializerNoDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesUpdate: async (
      id: number,
      householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('savedRecipesUpdate', 'id', id);
      // verify required parameter 'householdSavedRecipeSerializerNoDetails' is not null or undefined
      assertParamExists(
        'savedRecipesUpdate',
        'householdSavedRecipeSerializerNoDetails',
        householdSavedRecipeSerializerNoDetails,
      );
      const localVarPath = `/api/v2/saved-recipes/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        householdSavedRecipeSerializerNoDetails,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V2HouseholdsavedrecipeApi - functional programming interface
 * @export
 */
export const V2HouseholdsavedrecipeApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    V2HouseholdsavedrecipeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {HouseholdSavedRecipeSerializerNoDetails} householdSavedRecipeSerializerNoDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savedRecipesCreate(
      householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSavedRecipeSerializerNoDetails>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.savedRecipesCreate(
          householdSavedRecipeSerializerNoDetails,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savedRecipesDestroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.savedRecipesDestroy(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {number} [recipe]
     * @param {Array<number>} [recipeIn] Multiple values may be separated by commas.
     * @param {string} [search]
     * @param {boolean} [showRecipeDetails] If true, include full recipe details in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savedRecipesList(
      limit?: number,
      offset?: number,
      recipe?: number,
      recipeIn?: Array<number>,
      search?: string,
      showRecipeDetails?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedHouseholdSavedRecipeSerializerNoDetailsList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.savedRecipesList(
          limit,
          offset,
          recipe,
          recipeIn,
          search,
          showRecipeDetails,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {PatchedHouseholdSavedRecipeSerializerNoDetails} [patchedHouseholdSavedRecipeSerializerNoDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savedRecipesPartialUpdate(
      id: number,
      patchedHouseholdSavedRecipeSerializerNoDetails?: PatchedHouseholdSavedRecipeSerializerNoDetails,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSavedRecipeSerializerNoDetails>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.savedRecipesPartialUpdate(
          id,
          patchedHouseholdSavedRecipeSerializerNoDetails,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savedRecipesRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSavedRecipe>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.savedRecipesRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {HouseholdSavedRecipeSerializerNoDetails} householdSavedRecipeSerializerNoDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savedRecipesUpdate(
      id: number,
      householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSavedRecipeSerializerNoDetails>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.savedRecipesUpdate(
          id,
          householdSavedRecipeSerializerNoDetails,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V2HouseholdsavedrecipeApi - factory interface
 * @export
 */
export const V2HouseholdsavedrecipeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V2HouseholdsavedrecipeApiFp(configuration);
  return {
    /**
     *
     * @param {HouseholdSavedRecipeSerializerNoDetails} householdSavedRecipeSerializerNoDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesCreate(
      householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails,
      options?: any,
    ): AxiosPromise<HouseholdSavedRecipeSerializerNoDetails> {
      return localVarFp
        .savedRecipesCreate(householdSavedRecipeSerializerNoDetails, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesDestroy(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .savedRecipesDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {number} [recipe]
     * @param {Array<number>} [recipeIn] Multiple values may be separated by commas.
     * @param {string} [search]
     * @param {boolean} [showRecipeDetails] If true, include full recipe details in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesList(
      limit?: number,
      offset?: number,
      recipe?: number,
      recipeIn?: Array<number>,
      search?: string,
      showRecipeDetails?: boolean,
      options?: any,
    ): AxiosPromise<PaginatedHouseholdSavedRecipeSerializerNoDetailsList> {
      return localVarFp
        .savedRecipesList(
          limit,
          offset,
          recipe,
          recipeIn,
          search,
          showRecipeDetails,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {PatchedHouseholdSavedRecipeSerializerNoDetails} [patchedHouseholdSavedRecipeSerializerNoDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesPartialUpdate(
      id: number,
      patchedHouseholdSavedRecipeSerializerNoDetails?: PatchedHouseholdSavedRecipeSerializerNoDetails,
      options?: any,
    ): AxiosPromise<HouseholdSavedRecipeSerializerNoDetails> {
      return localVarFp
        .savedRecipesPartialUpdate(
          id,
          patchedHouseholdSavedRecipeSerializerNoDetails,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<HouseholdSavedRecipe> {
      return localVarFp
        .savedRecipesRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this household saved recipe.
     * @param {HouseholdSavedRecipeSerializerNoDetails} householdSavedRecipeSerializerNoDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savedRecipesUpdate(
      id: number,
      householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails,
      options?: any,
    ): AxiosPromise<HouseholdSavedRecipeSerializerNoDetails> {
      return localVarFp
        .savedRecipesUpdate(
          id,
          householdSavedRecipeSerializerNoDetails,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for savedRecipesCreate operation in V2HouseholdsavedrecipeApi.
 * @export
 * @interface V2HouseholdsavedrecipeApiSavedRecipesCreateRequest
 */
export interface V2HouseholdsavedrecipeApiSavedRecipesCreateRequest {
  /**
   *
   * @type {HouseholdSavedRecipeSerializerNoDetails}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesCreate
   */
  readonly householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails;
}

/**
 * Request parameters for savedRecipesDestroy operation in V2HouseholdsavedrecipeApi.
 * @export
 * @interface V2HouseholdsavedrecipeApiSavedRecipesDestroyRequest
 */
export interface V2HouseholdsavedrecipeApiSavedRecipesDestroyRequest {
  /**
   * A unique integer value identifying this household saved recipe.
   * @type {number}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesDestroy
   */
  readonly id: number;
}

/**
 * Request parameters for savedRecipesList operation in V2HouseholdsavedrecipeApi.
 * @export
 * @interface V2HouseholdsavedrecipeApiSavedRecipesListRequest
 */
export interface V2HouseholdsavedrecipeApiSavedRecipesListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesList
   */
  readonly offset?: number;

  /**
   *
   * @type {number}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesList
   */
  readonly recipe?: number;

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesList
   */
  readonly recipeIn?: Array<number>;

  /**
   *
   * @type {string}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesList
   */
  readonly search?: string;

  /**
   * If true, include full recipe details in response
   * @type {boolean}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesList
   */
  readonly showRecipeDetails?: boolean;
}

/**
 * Request parameters for savedRecipesPartialUpdate operation in V2HouseholdsavedrecipeApi.
 * @export
 * @interface V2HouseholdsavedrecipeApiSavedRecipesPartialUpdateRequest
 */
export interface V2HouseholdsavedrecipeApiSavedRecipesPartialUpdateRequest {
  /**
   * A unique integer value identifying this household saved recipe.
   * @type {number}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedHouseholdSavedRecipeSerializerNoDetails}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesPartialUpdate
   */
  readonly patchedHouseholdSavedRecipeSerializerNoDetails?: PatchedHouseholdSavedRecipeSerializerNoDetails;
}

/**
 * Request parameters for savedRecipesRetrieve operation in V2HouseholdsavedrecipeApi.
 * @export
 * @interface V2HouseholdsavedrecipeApiSavedRecipesRetrieveRequest
 */
export interface V2HouseholdsavedrecipeApiSavedRecipesRetrieveRequest {
  /**
   * A unique integer value identifying this household saved recipe.
   * @type {number}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for savedRecipesUpdate operation in V2HouseholdsavedrecipeApi.
 * @export
 * @interface V2HouseholdsavedrecipeApiSavedRecipesUpdateRequest
 */
export interface V2HouseholdsavedrecipeApiSavedRecipesUpdateRequest {
  /**
   * A unique integer value identifying this household saved recipe.
   * @type {number}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesUpdate
   */
  readonly id: number;

  /**
   *
   * @type {HouseholdSavedRecipeSerializerNoDetails}
   * @memberof V2HouseholdsavedrecipeApiSavedRecipesUpdate
   */
  readonly householdSavedRecipeSerializerNoDetails: HouseholdSavedRecipeSerializerNoDetails;
}

/**
 * V2HouseholdsavedrecipeApi - object-oriented interface
 * @export
 * @class V2HouseholdsavedrecipeApi
 * @extends {BaseAPI}
 */
export class V2HouseholdsavedrecipeApi extends BaseAPI {
  /**
   *
   * @param {V2HouseholdsavedrecipeApiSavedRecipesCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2HouseholdsavedrecipeApi
   */
  public savedRecipesCreate(
    requestParameters: V2HouseholdsavedrecipeApiSavedRecipesCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2HouseholdsavedrecipeApiFp(this.configuration)
      .savedRecipesCreate(
        requestParameters.householdSavedRecipeSerializerNoDetails,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2HouseholdsavedrecipeApiSavedRecipesDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2HouseholdsavedrecipeApi
   */
  public savedRecipesDestroy(
    requestParameters: V2HouseholdsavedrecipeApiSavedRecipesDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2HouseholdsavedrecipeApiFp(this.configuration)
      .savedRecipesDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2HouseholdsavedrecipeApiSavedRecipesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2HouseholdsavedrecipeApi
   */
  public savedRecipesList(
    requestParameters: V2HouseholdsavedrecipeApiSavedRecipesListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return V2HouseholdsavedrecipeApiFp(this.configuration)
      .savedRecipesList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.recipe,
        requestParameters.recipeIn,
        requestParameters.search,
        requestParameters.showRecipeDetails,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2HouseholdsavedrecipeApiSavedRecipesPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2HouseholdsavedrecipeApi
   */
  public savedRecipesPartialUpdate(
    requestParameters: V2HouseholdsavedrecipeApiSavedRecipesPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2HouseholdsavedrecipeApiFp(this.configuration)
      .savedRecipesPartialUpdate(
        requestParameters.id,
        requestParameters.patchedHouseholdSavedRecipeSerializerNoDetails,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2HouseholdsavedrecipeApiSavedRecipesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2HouseholdsavedrecipeApi
   */
  public savedRecipesRetrieve(
    requestParameters: V2HouseholdsavedrecipeApiSavedRecipesRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2HouseholdsavedrecipeApiFp(this.configuration)
      .savedRecipesRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2HouseholdsavedrecipeApiSavedRecipesUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2HouseholdsavedrecipeApi
   */
  public savedRecipesUpdate(
    requestParameters: V2HouseholdsavedrecipeApiSavedRecipesUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2HouseholdsavedrecipeApiFp(this.configuration)
      .savedRecipesUpdate(
        requestParameters.id,
        requestParameters.householdSavedRecipeSerializerNoDetails,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
