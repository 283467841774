/**
 * generic utility functions for arrays, objects, and other core datatypes
 */

import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

export function omitNilValues<T extends Record<string, unknown>>(
  obj: T,
): Partial<T> {
  return omitBy(obj, (val) => isNil(val)) as Partial<T>;
}
