import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faFilter,
  faInbox,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Box } from '@/components/base/box';
import { ItalicText, Text } from '@/components/base/text';
import React, { ComponentType, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@/components/base/flex';
import { theme } from '@/theme/index';
import { Recipe } from '@/hooks/use-recipe';

/**
 *
 */
interface TrafficLightStyleProps {
  dishWidth: string;
  mobileDishWidth: string;
}

const StyledDishTrafficLight = styled(Box)<TrafficLightStyleProps>`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 20px;
  width: ${(props) => props.mobileDishWidth};
  padding-top: ${({ theme }) => theme.space['3xs']}px;
  padding-bottom: ${({ theme }) => theme.space['3xs']}px;

  ${({ theme }) => theme.mediaQueries.m} {
    width: ${(props) => props.dishWidth};
  }
`;

interface Props extends TrafficLightStyleProps {
  recipe: Recipe;
}

const TRAFFIC_LIGHT_CIRCLE_WIDTH = 14;
const TRAFFIC_LIGHT_CIRCLE_HEIGHT = 14;

type TrafficLightProps = {
  showBorder?: boolean;
};

const StyledGreenTrafficLight = styled(Flex)<TrafficLightProps>`
  border: ${({ showBorder }) => (showBorder ? '1px' : '0px')} solid
    ${({ theme }) => theme.colors.primaryLight200};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii['5xl']};
  width: ${TRAFFIC_LIGHT_CIRCLE_WIDTH}px;
  height: ${TRAFFIC_LIGHT_CIRCLE_HEIGHT}px;
  margin-right: ${({ theme }) => theme.space['2xs']}px;
`;

const StyledOrangeTrafficLight = styled(Box)<TrafficLightProps>`
  border: ${({ showBorder }) => (showBorder ? '1px' : '0px')} solid
    ${({ theme }) => theme.colors.trafficLightOrange};
  background: white;
  border-radius: ${({ theme }) => theme.radii['5xl']};
  width: ${TRAFFIC_LIGHT_CIRCLE_WIDTH}px;
  height: ${TRAFFIC_LIGHT_CIRCLE_HEIGHT}px;
  margin-right: ${({ theme }) => theme.space['2xs']}px;
  line-height: ${({ theme }) => theme.lineHeights['11xs']};
`;

const StyledRedTrafficLight = styled(Box)<TrafficLightProps>`
  border: ${({ showBorder }) => (showBorder ? '1px' : '0px')} solid
    ${({ theme }) => theme.colors.trafficLightRed};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii['5xl']};
  width: ${TRAFFIC_LIGHT_CIRCLE_WIDTH}px;
  height: ${TRAFFIC_LIGHT_CIRCLE_HEIGHT}px;
  line-height: ${({ theme }) => theme.lineHeights['11xs']};
`;

export function DishTrafficLights({ recipe, ...styleProps }: Props) {
  return (
    <StyledDishTrafficLight {...styleProps}>
      <Flex justifyContent={'center'}>
        <StyledGreenTrafficLight
          showBorder={recipe.meetsProfileFlag !== 'green'}
        >
          {recipe.meetsProfileFlag === 'green' && (
            <FontAwesomeIcon
              color={theme.colors.primaryLight200}
              icon={faCircleCheck}
              size={'sm'}
            />
          )}
        </StyledGreenTrafficLight>
        <StyledOrangeTrafficLight
          showBorder={recipe.meetsProfileFlag !== 'yellow'}
        >
          {recipe.meetsProfileFlag === 'yellow' && (
            <FontAwesomeIcon
              color={theme.colors.trafficLightOrange}
              icon={faCircleExclamation}
              size={'sm'}
            />
          )}
        </StyledOrangeTrafficLight>
        <StyledRedTrafficLight showBorder={recipe.meetsProfileFlag !== 'red'}>
          {recipe.meetsProfileFlag === 'red' && (
            <FontAwesomeIcon
              color={theme.colors.trafficLightRed}
              icon={faCircleXmark}
              size={'sm'}
            />
          )}
        </StyledRedTrafficLight>
      </Flex>
    </StyledDishTrafficLight>
  );
}
