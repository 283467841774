import { Recipe, transformRecipeResponse } from '@/hooks/use-recipe';
import { useAuthenticatedApi } from '@/hooks/use-authenticated-api';
import {
  InfiniteData,
  useInfiniteQuery,
  type QueryKey,
  QueryFunction,
} from 'react-query';
import { useAuthenticatedAxios } from '@/context/api-auth';
import { PaginatedListV1 } from '@/types/api-models';
import { AxiosInstance, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { isNil, omitBy } from 'lodash';
import { omitNilValues } from '@/utils/data-types';
import { logger } from '@/services/logging';

export interface IngredientFilter {
  id: number | string;
  name: string;
}

export type SearchRecipesFilter = {
  q: string;
  ingredients?: IngredientFilter[];
  courses: string[];
  ratings: string[];
  limit?: number;
  offset?: number;
};

export function isSearchEnabled({
  q,
  courses,
  ratings,
  ingredients,
}: SearchRecipesFilter): boolean {
  return (
    !!q ||
    (courses?.length ?? 0) > 0 ||
    (ratings?.length ?? 0) > 0 ||
    (ingredients?.length ?? 0) > 0
  );
}

export function getSearchRecipesQueryKey(params: SearchRecipesFilter) {
  return ['use-search-recipes', { ...params }] as const;
}

interface SearchRecipesResponseData extends PaginatedListV1<any> {
  transformedObjects: Recipe[];
}

function arrayToUrlParam(list: string[]) {
  return list.length > 0 ? list.join(',') : undefined;
}

export function fetchSearchRecipes(
  axios: AxiosInstance,
): QueryFunction<
  SearchRecipesResponseData,
  ReturnType<typeof getSearchRecipesQueryKey>
> {
  return async (queryParams) => {
    let resp: AxiosResponse<PaginatedListV1<any>>;

    if (!queryParams.pageParam) {
      let { q } = queryParams.queryKey[1];
      const {
        courses = [],
        ratings = [],
        limit = 30,
        ingredients = [],
      } = queryParams.queryKey[1];
      // remove condiment and beverages param from courses, and add it to main query
      // this is a temporary fix since the backend doesn't properly index these two courses
      const realCourseParams: string[] = [];
      const addToQueryParams: string[] = [];
      for (const courseType of courses) {
        realCourseParams.push(courseType);
      }
      if (addToQueryParams.length > 0) {
        q = [q, ...addToQueryParams].join(' ');
      }

      const coursesParam = arrayToUrlParam(realCourseParams);
      const ratingsParam = arrayToUrlParam(ratings);
      const ingredientParam = arrayToUrlParam(
        ingredients.map((x) => x.id.toString()),
      );
      resp = await axios.get('/api/v1/household_recipe_profile_search', {
        params: omitNilValues({
          courses: coursesParam,
          ratings: ratingsParam,
          query_text__icontains: q,
          ingredients: ingredientParam,
          limit,
        }),
      });
    } else {
      resp = await axios.get(queryParams.pageParam);
    }
    const transformedObjects = resp.data.objects.map(transformRecipeResponse);
    return { ...resp.data, transformedObjects };
  };
}

export const useSearchRecipes = (params: SearchRecipesFilter) => {
  const { axios, isAuthenticated } = useAuthenticatedAxios();
  const { q, courses, ratings } = params;
  const enabled = isSearchEnabled(params) && isAuthenticated;
  const hook = useInfiniteQuery(
    getSearchRecipesQueryKey(params),
    fetchSearchRecipes(axios),
    {
      enabled,
      getNextPageParam: (lastPage, pages) => lastPage?.meta?.next ?? undefined,
      getPreviousPageParam: (lastPage, pages) =>
        lastPage.meta?.previous ?? undefined,
      select: ({ pages, pageParams }) => {
        return { pages, pageParams };
      },
    },
  );

  const recipes = useMemo(() => {
    const pages = hook.data?.pages;
    return isNil(pages) ? [] : pages.flatMap((page) => page.transformedObjects);
  }, [hook.data?.pages, hook.data?.pages.length]);
  return { ...hook, recipes };
};
