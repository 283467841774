import { post } from '@/services/httpClient';

export type SaveFavoriteRecipeRequest = {
  householdId: number;
  recipeId: number;
};

const saveFavoriteRecipe = ({
  householdId,
  recipeId,
}: SaveFavoriteRecipeRequest): Promise<any> => {
  return post('api/v1/household_saved_recipe', {
    recipe: `/api/v1/recipe/${recipeId}`,
    household: `/api/v1/household/${householdId}`,
  });
};

export default saveFavoriteRecipe;
