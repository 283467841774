import React from 'react';
import styled from '@emotion/styled';

import { Text } from '@/components/base/text';
import {
  ModalFooter,
  ModalTitle,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalContent,
} from '@/components/base/modal';
import { Box } from '@/components/base/box';
import { Button } from '@/components/base/buttons';
import { Flex } from '@/components/base/flex';

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space.s}px;
`;

StyledButton.defaultProps = {
  variant: 'primary',
  isFullWidth: true,
};

const StyledBox = styled(Box)``;

StyledBox.defaultProps = {
  alignSelf: 'center',
};

type RemoveProfileModalProps = {
  isOpen: boolean;
  onRemove: () => void;
  onClose: () => void;
  onToggle: () => void;
  data?: any;
  removeButtonLoading?: boolean;
};

const MODAL_INNER_WIDTH = 300;

export const DELETE_PROFILE_MODAL_TYPE = 'DeleteProfile';

export const RemoveProfileModal: React.FC<RemoveProfileModalProps> = ({
  isOpen,
  onRemove,
  onToggle,
  onClose,
  data,
  removeButtonLoading,
}) => {
  if (!data) {
    return null;
  }

  const { name } = data;

  return (
    <Modal isOpen={isOpen} setIsOpen={onToggle}>
      <ModalOverlay />
      <ModalContent width="560px">
        <ModalHeader>
          <ModalTitle variant="s" paddingX={'m'}>
            Are you sure you want to remove this profile?
          </ModalTitle>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody flexDirection="column">
          <StyledBox textAlign="center">
            <Text
              variant="mBold"
              marginTop="3xl"
              marginBottom="l"
              color="textGray20"
            >
              {name}
            </Text>
          </StyledBox>
        </ModalBody>
        <ModalFooter flexDirection={'column'}>
          <Flex justifyContent="center">
            <Button
              variant="warning"
              loading={removeButtonLoading}
              disabled={removeButtonLoading}
              marginRight={'xs'}
              onClick={onRemove}
            >
              <Text variant="2xs">Remove</Text>
            </Button>
            <Button
              variant="secondary"
              disabled={removeButtonLoading}
              onClick={onClose}
            >
              <Text variant="2xs">Cancel</Text>
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
