import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@/components/base/modal';
import { Flex } from '@/components/base/flex';
import { Box } from '@/components/base/box';
import { Text } from '@/components/base/text';
import { useHousehold } from '@/hooks/use-household';
import { formatDate } from '@/utils/format-date';
import { Fade } from '@/components/base/fade';
import { Slide } from '@/components/base/slide/Slide';
import { getYear } from 'date-fns';

const CURRENT_YEAR = getYear(new Date());

type ScoreCardBreakdownItemType = {
  id: number;
  name: string;
  description: string;
  points: number;
  frequency: string;
};
const ScoreCardBreakdownData: ScoreCardBreakdownItemType[] = [
  {
    id: 9,
    name: 'Create your first profile',
    description:
      'The "Usual" profile reflects the requirements for your household you\'ve already told us. This profile will be used as the default for every day going forward, but you can always edit it if you need to do so.',
    points: 10,
    frequency: '',
  },
  {
    id: 1, // #
    name: 'Create a grocery trip',
    description:
      'Creating a grocery trip is the first step to getting great, nutritious food on your table. By clicking the green "Start New Meal Plan" button, we will generate suggestions based on the meal profile selected for each day.',
    points: 7,
    frequency: 'per week',
  },
  {
    id: 3,
    name: 'View your meal plan',
    description:
      'Planning meals is super important to saving time and money, as well as eating healthier! Plan ahead and shop ahead and the stress will melt away!',
    points: 5,
    frequency: 'per day',
  },
  {
    id: 4,
    name: 'Edit your meal plan',
    description:
      "Click on the picture of a meal in the calendar to see more details about a specific day's meal. From here you can switch your entree, sides, or just ask for a totally new suggestion!",
    points: 5,
    frequency: 'per week',
  },
  {
    id: 5,
    name: 'View your grocery list',
    description:
      "By clicking on \"Grocery\" in the navigation bar, you can see an up-to-date reflection of what you need to grab from the store. You can cross out anything that you already have, then print it out or simply bring your smartphone to the store and cross items out as you make your way around the store. For added convenience, we've categorized items by grocery store department, and don't forget you can add anything else you need so you don't forget.",
    points: 5,
    frequency: 'per week',
  },
  {
    id: 6,
    name: 'Edit your grocery list',
    description:
      'Check out your shopping list after you plan and add anything else you may need. You can do this at any time as you think of items. And be sure to use your shopping list when you go to the store as it makes it super easy to check off items as you shop. Make your trip easier to manage. Share the list with whoever is doing the shopping and no more "don’t forget the milk, honey!',
    points: 10,
    frequency: 'per week',
  },
  {
    id: 7,
    name: 'Add to your Pantry from a grocery list',
    description:
      'Keep track of your spice drawer, refrigerator, freezer, pantry, garden and general supplies! Add items quickly and easily. We’ll keep track of what you already have so that you don’t buy too much and use what you already have. It’s simple to do, yet incredibly powerful!',
    points: 10,
    frequency: 'per week',
  },
  {
    id: 8,
    name: 'Edit your Pantry',
    description:
      'Indicate "Use Soon" if you want your next meal plan to use those ingredients. Avoid waste! If you indicate "Running Low" on an item we’ll suggest it the next time you shop. And we’ll look out for special promotions, too!',
    points: 10,
    frequency: 'per week',
  },
  {
    id: 9,
    name: 'View your All-in-One recipe page',
    description:
      'After clicking on a meal, you can select "View All Recipes". This tool is perfect for multi-tasking in the kitchen, and gives you the ability to have each piece of your meal hot and ready all at the same time, just like a professional chef.',
    points: 5,
    frequency: 'per week',
  },
  {
    id: 10,
    name: 'Rate a recipe',
    description:
      'By clicking "Rate Recipes" under the Recipes menu in the navigation bar, DinnerTime will learn more detail about your household\'s preferences. The more you rate, the better the suggested meals become.',
    points: 5,
    frequency: 'per day',
  },
  {
    id: 11,
    name: 'Search your recipe box',
    description:
      'The "Recipe Box" under the Recipes menu in the navigation bar allows you to save your favorite recipes in a convenient place. The Recipe Box also gives you access to DinnerTime\'s always expanding database of recipes. You can search and save any recipe, then add it to an existing meal to make it perfect.',
    points: 5,
    frequency: 'per week',
  },
  {
    id: 12,
    name: 'Add a recipe to your recipe box',
    description:
      'Add items to your recipe box by either navigating to the individual recipe page by clicking on the name of any recipe or by searching the database in the recipe box.',
    points: 5,
    frequency: 'per week',
  },
  {
    id: 13,
    name: 'Add a recipe to a meal from recipe box',
    description:
      'After selecting a specific day, you can add any item saved in your recipe box to any meal by just clicking on the green "Add From Recipe Box" button.',
    points: 10,
    frequency: '',
  },
  {
    id: 14,
    name: 'Change the profile for a meal',
    description:
      'Switching between profiles is easy! Just click the dropdown menu under the date to pick something that better suits your plans.',
    points: 10,
    frequency: '',
  },
  {
    id: 15,
    name: 'Require an ingredient for a meal',
    description:
      'You can even tell DinnerTime to suggest a meal with a specific ingredient. In the profile selector dropdown menu, navigate to the bottom of your list, and type the ingredient.',
    points: 10,
    frequency: '',
  },
  {
    id: 3, // #
    name: 'Edit a profile',
    description:
      "We've given you some profiles to start with, but they may need some editing! Just click on the one you want to edit.",
    points: 10,
    frequency: '',
  },
  {
    id: 2, // #
    name: 'Add a new profile',
    description:
      'You can add your own profiles to pick from as well. Just click on the link under your list of current profiles. This could be a picnic for soccer practice, or one for when the in laws are in town.',
    points: 5,
    frequency: 'per week',
  },
];

const StyledModalContent = styled(ModalContent)`
  width: 100%;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.l} {
    width: 998px;
  }
`;

const StyledModalTitle = styled(ModalTitle)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  line-height: ${({ theme }) => theme.lineHeights.m};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 10px 0;
`;

const StyledModalBody = styled(Box)`
  position: relative;
  overflow-y: auto;
  max-height: 400px;
`;

const StyledInstructions = styled(Box)`
  width: 60%;
  background-color: ${({ theme }) =>
    theme.colors.scoreCardSecondSummaryInstructionsBackground};
  padding: 10px ${({ theme }) => theme.space.l}px 0;

  & p {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.light};
    font-size: ${({ theme }) => theme.fontSizes['2xs']};
  }
`;

const StyledTotalPoints = styled(Box)`
  background-color: ${({ theme }) =>
    theme.colors.scoreCardSecondSummaryTotalPointsBackground};
  width: 40%;
  padding: ${({ theme }) => theme.space.s}px ${({ theme }) => theme.space.l}px;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledMonthlyPointCount = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes['6xl']};
  line-height: 50px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const StyledScoreCardMonth = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 40px;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const StyledTotalPointCount = styled(Text)`
  font-size: 26px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 0 5px;
`;

const StyledTotalPointLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes['2xs']};
`;

const StyledScoreBreakdownWrap = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes['2xs']};
  color: ${({ theme }) => theme.colors.textGray20};
`;

const StyledFooter = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  text-align: left;
  position: relative;
  padding: 14px 15px 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightBorder};
`;

const StyledFooterText = styled(Text)`
  font-size: 11.2px;
  color: ${({ theme }) => theme.colors.textGray20};
`;

const StyledMoreHelper = styled(Box)`
  position: absolute;
  right: 5px;
  top: -10px;
  font-size: ${({ theme }) => theme.fontSizes['6xs']};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 3px;
  padding: 0 5px;
  cursor: default;
  color: ${({ theme }) => theme.colors.textGray20};
`;

const StyledMoreHelperText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes['6xs']};
  line-height: ${({ theme }) => theme.lineHeights['4xs']};
  color: ${({ theme }) => theme.colors.textGray20};
`;

type ScoreCardModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const ScoreCardModal = ({
  isOpen,
  onClose = () => {},
}: ScoreCardModalProps) => {
  const { household } = useHousehold();
  const [showMoreHelper, setShowMoreHelper] = useState(true);

  const handleScroll = (e: any) => {
    const reachedBottom =
      e.target.scrollHeight - e.target.scrollTop - 1 === e.target.clientHeight;
    setShowMoreHelper(!reachedBottom);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={onClose}>
      <ModalOverlay />
      <StyledModalContent>
        <ModalHeader backgroundColor="primaryLight200">
          <StyledModalTitle>Scorecard</StyledModalTitle>
          <ModalCloseButton
            iconWidth={18}
            iconHeight={18}
            top={'xl'}
            hoverBackgroundColor={'rgba(0, 0, 0, 0.25)'}
          />
        </ModalHeader>

        <StyledModalBody onScroll={handleScroll}>
          {household && (
            <>
              <Flex backgroundColor={'scoreCardSecondSummaryBackground'}>
                <StyledInstructions>
                  <Text>Earn points each week.</Text>
                  <Text>The more you use DinnerTime, the more you win!</Text>
                  <Text>
                    Earn at least 50 points per month to be eligible for special
                    rewards and contests from DinnerTime and our partners.
                    <sup>*</sup>
                  </Text>
                  <Text>It&apos;s easy and it&apos;s fun!</Text>
                </StyledInstructions>

                <StyledTotalPoints>
                  <StyledMonthlyPointCount>
                    {household.monthlyPoints}
                  </StyledMonthlyPointCount>
                  <StyledScoreCardMonth>
                    points earned in {formatDate(new Date(), 'MMM yyyy')}
                  </StyledScoreCardMonth>
                  <StyledTotalPointCount display={'inline-block'}>
                    {household.yearToDatePoints}
                  </StyledTotalPointCount>
                  <StyledTotalPointLabel display={'inline-block'}>
                    points earned in {CURRENT_YEAR}
                  </StyledTotalPointLabel>
                  <StyledTotalPointCount display={'inline-block'}>
                    {household.totalPoints}
                  </StyledTotalPointCount>
                  <StyledTotalPointLabel display={'inline-block'}>
                    Total Points
                  </StyledTotalPointLabel>
                </StyledTotalPoints>
              </Flex>
            </>
          )}

          <StyledScoreBreakdownWrap>
            {ScoreCardBreakdownData.map((x, index) => (
              <ScoreBreakdownItem key={index} item={x} />
            ))}
          </StyledScoreBreakdownWrap>
        </StyledModalBody>

        <StyledFooter>
          <StyledFooterText>
            * your employer or other sponsors may offer additional rewards and
            contests!
          </StyledFooterText>

          <Fade visible={showMoreHelper}>
            <StyledMoreHelper>
              <FontAwesomeIcon icon={faDownLong} size="sm" />

              <StyledMoreHelperText marginLeft={'2xs'} as={'span'}>
                more
              </StyledMoreHelperText>
            </StyledMoreHelper>
          </Fade>
        </StyledFooter>
      </StyledModalContent>
    </Modal>
  );
};

const StyledScoreBreakdownItem = styled(Flex)`
  line-height: ${({ theme }) => theme.lineHeights['4xs']};
  justify-content: space-between;
`;

const StyledScoreBreakdownTitle = styled(Box)`
  width: 60%;
  padding: 5px ${({ theme }) => theme.space.l}px;
`;

const StyledDescription = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes['4xs']};
  line-height: ${({ theme }) => theme.lineHeights['9xs']};
  padding: 5px 0;
`;

const StyledScoreBreakdownPointDetail = styled(Box)`
  padding: 5px ${({ theme }) => theme.space.l}px;
  width: 40%;
  background-color: rgba(246, 246, 245, 0.5);
  cursor: pointer;
  > * {
    cursor: pointer;
  }

  p {
    display: inline-block;
  }
`;

const StyledPointValue = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  padding-right: 5px;
  width: 20px;
  text-align: right;
`;

const StyledPointFrequency = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes['5xs']};
  line-height: ${({ theme }) => theme.lineHeights['11xs']};
`;

type ScoreBreakdownItemProps = {
  item: ScoreCardBreakdownItemType;
};
const ScoreBreakdownItem = ({ item }: ScoreBreakdownItemProps) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <StyledScoreBreakdownItem
      onClick={() => {
        setShowDescription(!showDescription);
      }}
      backgroundColor={
        item.id % 2 === 0 ? 'white' : 'scoreCardBreakdownItemOddBackground'
      }
    >
      <StyledScoreBreakdownTitle>
        <Flex
          cursor={'pointer'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box cursor={'pointer'}>{item.name}</Box>
          <Box cursor={'pointer'}>
            <FontAwesomeIcon
              icon={showDescription ? faMinus : faPlus}
              size="sm"
            />
          </Box>
        </Flex>
        <Slide>
          {showDescription && (
            <StyledDescription cursor={'pointer'}>
              {item.description}
            </StyledDescription>
          )}
        </Slide>
      </StyledScoreBreakdownTitle>
      <StyledScoreBreakdownPointDetail>
        <StyledPointValue>{item.points}</StyledPointValue>
        <Text marginRight={'3xs'}>points</Text>
        <StyledPointFrequency>
          ({item.frequency ? `1x ${item.frequency}` : 'once'})
        </StyledPointFrequency>
      </StyledScoreBreakdownPointDetail>
    </StyledScoreBreakdownItem>
  );
};
