import React from 'react';

import { ItalicText } from '@/components/base/text';
import {
  ModalFooter,
  ModalTitle,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalContent,
} from '@/components/base/modal';
import { Button } from '@/components/base/buttons';
import { Flex } from '@/components/base/flex';
import {
  ShoppingTrip,
  useDeleteShoppingTrip,
} from '@/hooks/use-shopping-trips';
import { ShoppingTripTitle } from './ShoppingTripTitle';
import useCommandRequest from '@/hooks/use-command-request';
import { deleteShoppingTrip } from '@/services/shopping-trip';
import { Box } from '../base/box';
import { useQueryClient } from 'react-query';

type RemoveShoppingListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  shoppingTrip: ShoppingTrip;
};

export const RemoveShoppingListModal: React.FC<
  RemoveShoppingListModalProps
> = ({ isOpen, onClose, shoppingTrip }) => {
  const deleteShoppingTrip = useDeleteShoppingTrip();

  const removeTrip = async () => {
    try {
      await deleteShoppingTrip.mutateAsync({
        uri: shoppingTrip.uri,
      });
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={onClose}>
      <ModalOverlay />
      <ModalContent width="560px">
        <ModalHeader>
          <ModalTitle variant="s" paddingX={'m'}>
            Are you sure you want to remove this grocery trip?
          </ModalTitle>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody flexDirection="column" justifyContent="center">
          <Box paddingY="xl">
            <ShoppingTripTitle trip={shoppingTrip} variant="large" />
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Flex flexDirection="column">
            <ItalicText variant="3xs" marginBottom={'m'}>
              All meals associated with this trip will be removed.
            </ItalicText>
            <Flex flexDirection="row" justifyContent="center">
              <Button variant="warning" onClick={removeTrip}>
                Remove
              </Button>
              <Button variant="secondary" onClick={onClose} marginLeft={'xs'}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
