import React from 'react';
import { Box } from '@/components/base/box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { useTheme } from '@emotion/react';

export type StarsProps = {
  totalStars: number;
  showEmptyStars?: boolean;
  color?: string;
};

export const Stars = ({
  totalStars,
  color,
  showEmptyStars = false,
}: StarsProps) => {
  const theme = useTheme();
  const totalEmptyStars = 5 - totalStars;

  return (
    <Box display={'inline-block'} cursor={'pointer'}>
      {Array.from(Array(totalStars), (e, i) => {
        return (
          <FontAwesomeIcon
            color={color ?? theme.colors.starOrange}
            key={i}
            icon={faStar}
            size="sm"
          />
        );
      })}

      {showEmptyStars &&
        Array.from(Array(totalEmptyStars), (e, i) => {
          return (
            <FontAwesomeIcon
              color={color ?? theme.colors.starOrange}
              key={i}
              // @ts-ignore
              icon={faStarEmpty}
              size="sm"
            />
          );
        })}
    </Box>
  );
};
