import { ApiError, isAxiosError } from '@/utils/errors';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { getApiClient } from '../api-client/axios';

export function request<T extends any = any>(options: AxiosRequestConfig<T>) {
  return getApiClient()
    .request<T>({
      ...options,
      headers: {
        ...getAuthorizationHeaders(),
        ...options.headers,
      },
    })
    .then((response) =>
      Promise.resolve(response.status === 204 ? null : response.data),
    )
    .catch((err) => {
      if (isAxiosError(err)) {
        return Promise.reject(new ApiError(err));
      } else {
        return err?.response ?? err;
      }
    });
}

export function get<T extends any = any>(
  url: string,
  params = {},
  options: AxiosRequestConfig<T> = {},
): Promise<T> {
  return request({ ...options, method: 'GET', url, params });
}

export function post<T extends any = any>(
  url: string,
  data: any,
  options: AxiosRequestConfig<T> = {},
): Promise<T> {
  return request<T>({ ...options, method: 'POST', data, url });
}

export function patch<T extends any = any>(
  url: string,
  data: any,
  options: AxiosRequestConfig<T> = {},
): Promise<T> {
  return request({ ...options, method: 'PATCH', data, url });
}

export function put<T extends any = any>(
  url: string,
  data: any,
  options: AxiosRequestConfig<T> = {},
): Promise<T> {
  return request({ ...options, method: 'PUT', data, url });
}

//I Named it deleteRequest because delete is keyword
export function deleteRequest<T extends any = any>(
  url: string,
  data: any,
  options: AxiosRequestConfig<T> = {},
): Promise<T> {
  return request({ ...options, method: 'DELETE', data, url });
}

const API_KEY_NAME = 'api-key';

function getAuthorizationHeaders() {
  return {
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: getUserApiKey(),
  };
}

function getUserApiKey(): string {
  return Cookies.get(API_KEY_NAME) ?? '';
}
