import { useEffect } from 'react';
import {
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import omit from 'lodash/omit';
import { getApiClient } from 'api-client/axios';
import AuthService, { AuthenticatedApiProps } from '@/services/auth-service';
import { useAuthenticatedAxios } from '@/context/api-auth';
import { AxiosInstance } from 'axios';

export const useAuthenticatedApi = <TData = any>({
  endpoint,
  postData = undefined,
  method,
  type = 'GET',
  enabled = true,
  queryOptions,
}: AuthenticatedApiProps) => {
  const apiKey = AuthService.getUserApiKey() ?? '';
  const [path, queryParams] = endpoint.split('?');
  const defaultMethod = postData ? 'POST' : 'GET';
  method = method ?? type ?? defaultMethod;

  const { refetch, data, isLoading, isFetching, isError, ...queryHooks } =
    useQuery(
      ['query-api-authenticated', path, queryParams],
      async () => {
        const resp = await getApiClient().request({
          method,
          url: `api/v1/${endpoint}`,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: apiKey,
          },
        });
        if (endpoint === 'pantry_section') {
          return (resp.data as any).objects;
        }
        // don't check if response is success, axios already throws error if it's not
        if (!resp.data) {
          return {
            success: true,
          };
        }

        if (Array.isArray((resp.data as any)?.objects)) {
          return (resp.data as any).objects;
        }

        return resp.data;
      },
      {
        enabled,
        retry: 0,
        refetchOnWindowFocus: false,
        ...(queryOptions as any),
      },
    );

  useEffect(() => {
    if (
      enabled &&
      !!apiKey &&
      !data &&
      isLoading === false &&
      isError === false
    ) {
      refetch();
    }
  }, [data, apiKey, isLoading, isError]);

  if (apiKey === '') {
    return {
      isError: true,
      error: 'Authentication key is not valid',
    };
  }
  return {
    status: queryHooks.status,
    isLoading,
    isFetching,
    isError,
    isIdle: queryHooks.isIdle,
    data: data as TData,
    rootHooks: queryHooks,
    refetch,
  };
};
