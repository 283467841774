import { patch } from '@/services/httpClient';

const setServingsForDish = (dishId: number, servings: number): Promise<any> => {
  return patch(`api/v1/household_dinner_dish/${dishId}`, {
    status_code: 'U',
    servings: servings,
  });
};

export default setServingsForDish;
