import React, { useCallback, useMemo, useState } from 'react';
import { Tabs } from '@/components/base/tabs';
import { faMagnifyingGlass, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@/components/base/text';

import styled from '@emotion/styled';
import { theme } from '@/theme/index';
import { useFavoriteRecipesPaginated } from '@/hooks/use-favorite-recipes';
import { RecipesSearchTabContent } from '@/components/recipes/RecipesSearchFavoriteTab/RecipesSearchTabContent';
import { RecipesFavoritesTabContent } from '@/components/recipes/RecipesSearchFavoriteTab/RecipesFavoritesTabContent';
import {
  IngredientFilter,
  SearchRecipesFilter,
  useSearchRecipes,
} from '@/hooks/use-search-recipes';

import useCommandRequest from '@/hooks/use-command-request';
import removeFavoriteRecipe from '@/services/remove-favorite-recipe';
import saveFavoriteRecipe from '@/services/save-favorite-recipe';
import {
  RecipeRatingDetails,
  useUserRecipeRatings,
} from '@/hooks/use-user-recipe-ratings';
import updateRateRecipe from '@/services/update-rate-recipe';
import rateRecipe from '@/services/rate-recipe';
import removeRateRecipe from '@/services/remove-rate-recipe';
import { useScoreCardEvent } from '@/hooks/use-score-card-event';
import { debounce } from 'lodash';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect';
import { useMealPlanCalendarStore } from '@/store/meal-plan-calendar-store';
import { useShallow } from 'zustand/react/shallow';
import { getItem } from '@/utils/lang';
const StyledTitle = styled(Text)`
  margin-left: ${theme.space['2xs']}px;
`;

type RecipeSearchFavoriteTabProps = {
  householdId: number;
  defaultTabSelected?: number;
  showSelectMealDateOption?: boolean;
  onTabSelected?: (
    index: number,
    lastIndex: number,
    event: Event,
  ) => boolean | void;
  onAddRecipeToDinner?: (
    recipeId: number,
    date?: Date,
    dinnerId?: number,
  ) => void;
};

export function useRecipeSearchFilters() {
  const [recipeBox, setRecipeBox] = useMealPlanCalendarStore(
    useShallow((s) => [s.recipeBoxSearch, s.setRecipeBoxSearch]),
  );

  const filter: SearchRecipesFilter = useMemo(() => {
    return recipeBox
      ? {
          q: recipeBox.keyword ?? '',
          courses: recipeBox.courses ?? [],
          ratings: recipeBox.ratings ?? [],
          ingredients: recipeBox.ingredients,
        }
      : { q: '', courses: [], ratings: [], ingredients: [] };
  }, [recipeBox]);
  const setFilter = useCallback(
    (val: SearchRecipesFilter) => {
      return setRecipeBox({
        dinnerDate: recipeBox.dinnerDate,
        defaultTabSelected: recipeBox.defaultTabSelected,
        keyword: val.q,
        courses: val.courses,
        ratings: val.ratings,
        ingredients: val.ingredients ?? [],
      });
    },
    [setRecipeBox, recipeBox],
  );
  return [filter, setFilter] as const;
}

export function RecipesSearchFavoriteTab({
  householdId,
  defaultTabSelected = 0,
  showSelectMealDateOption = false,
  onTabSelected = () => {},
  // TODO refactor these numerous nested callbacks to simplify the logic

  onAddRecipeToDinner = () => {},
}: RecipeSearchFavoriteTabProps) {
  // this is the 'live' search text state that is updated whenever form text is input
  const [searchTextSavedRecipe, setSearchTextSavedRecipe] = useState('');
  // this is the debounced search state that is used as the input for the favorite recipes
  // search
  const [debouncedSearchTextSavedRecipe, setDebouncedSearchTextSavedRecipe] =
    useState('');
  const debounceTimeMS = 500;
  useDebouncedEffect(
    () => {
      setDebouncedSearchTextSavedRecipe(searchTextSavedRecipe);
    },
    [searchTextSavedRecipe],
    debounceTimeMS,
  );
  // debounce search text so search isn't performed every time the user inputs. This debounced
  // function is passed to RecipeFavoritesTabContent as the `setSearchText` prop

  let {
    totalCount,
    allFavorites: favoriteRecipes,
    status,
    error,
    refetch: refetchFavorites,
    fetchNextPage,
    ...favoritesHook
  } = useFavoriteRecipesPaginated({
    pageSize: 1000,
    search: debouncedSearchTextSavedRecipe || undefined,
  });
  favoriteRecipes = favoriteRecipes ?? [];

  const { recipesRatings, refetch: refetchRecipeRatings } =
    useUserRecipeRatings();
  const [filter, setFilter] = useRecipeSearchFilters();
  const {
    recipes,
    isLoading,
    fetchNextPage: fetchNextRecipePage,
    isFetchingNextPage: isFetchingNextRecipePage,
    hasNextPage: hasNextRecipePage,
    refetch: refetchSearchRecipes,
    ...searchRecipes
  } = useSearchRecipes(filter);

  const { send: sendFavoriteCommand } = useCommandRequest();

  const toggleFavoriteRecipe = async (
    recipeId: number,
    savedRecipeId?: number,
  ) => {
    const saveFavorite = !savedRecipeId;
    const result = await sendFavoriteCommand(() =>
      savedRecipeId
        ? removeFavoriteRecipe({ savedRecipeId: savedRecipeId })
        : saveFavoriteRecipe({
            householdId: householdId,
            recipeId: recipeId,
          }),
    );

    if (result.completed) {
      await refetchFavorites();

      if (saveFavorite) {
        await sendScoreCardEvent('SAVE_RECIPE');
      }
    }
  };

  const { send: sendRateRecipeCommand } = useCommandRequest();

  const onRateRecipe = async (
    newValue: number,
    recipeId: number,
    recipeRatingId?: number,
  ) => {
    const addNewRating = !recipeRatingId;
    const result = await sendRateRecipeCommand(() =>
      recipeRatingId
        ? updateRateRecipe({
            recipeId: recipeRatingId,
            rating: newValue,
          })
        : rateRecipe({
            householdId,
            recipeId: recipeId,
            rating: newValue,
          }),
    );

    if (result.completed) {
      await refetchFavorites();
      if (refetchSearchRecipes) await refetchSearchRecipes();
      if (refetchRecipeRatings) await refetchRecipeRatings();
      if (addNewRating) await sendScoreCardEvent('RATE_RECIPE');
    }
  };

  const { send: sendDeleteRateRecipeCommand } = useCommandRequest();

  const onClearRating = async (recipeRatingId: number) => {
    const result = await sendDeleteRateRecipeCommand(() =>
      removeRateRecipe(recipeRatingId),
    );

    if (result.completed) {
      await refetchFavorites();
      if (refetchSearchRecipes) await refetchSearchRecipes();
      if (refetchRecipeRatings) await refetchRecipeRatings();
    }
  };

  const { sendScoreCardEvent } = useScoreCardEvent();

  const onFilterChange = async (newValues: SearchRecipesFilter) => {
    setFilter({ ...newValues });

    if (filter.q || filter.courses.length > 0 || filter.ratings.length > 0)
      await sendScoreCardEvent('SEARCH_RECIPE_BOX');
  };

  return (
    <Tabs
      defaultIndex={defaultTabSelected ?? 0}
      onSelect={onTabSelected}
      items={[
        {
          title: (
            <>
              <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
              <StyledTitle as={'span'}>Recipe Search</StyledTitle>
            </>
          ),
          content: (
            <RecipesSearchTabContent
              items={recipes}
              favoriteItems={favoriteRecipes}
              recipesRatings={recipesRatings as RecipeRatingDetails[]}
              filter={filter}
              loading={isLoading}
              showSelectMealDateOption={showSelectMealDateOption}
              onFilterChange={onFilterChange}
              onToggleFavoriteRecipe={toggleFavoriteRecipe}
              onAddRecipe={(...args) => {
                return onAddRecipeToDinner(...args);
              }}
              onRateRecipe={onRateRecipe}
              onClearRating={onClearRating}
              fetchNextPage={fetchNextRecipePage}
              isFetchingNextPage={isFetchingNextRecipePage}
              hasNextPage={hasNextRecipePage}
            />
          ),
        },
        {
          title: (
            <>
              <FontAwesomeIcon icon={faHeart} size="sm" />
              <StyledTitle as={'span'}>
                Favorites
                {totalCount && totalCount > 0 ? ` (${totalCount})` : ''}
              </StyledTitle>
            </>
          ),
          content: (
            <RecipesFavoritesTabContent
              items={favoriteRecipes}
              totalCount={totalCount}
              recipesRatings={recipesRatings as RecipeRatingDetails[]}
              showSelectMealDateOption={showSelectMealDateOption}
              onToggleFavoriteRecipe={toggleFavoriteRecipe}
              onAddRecipe={onAddRecipeToDinner}
              onRateRecipe={onRateRecipe}
              onClearRating={onClearRating}
              itemsError={error}
              itemsStatus={status}
              hasMoreRecipes={favoritesHook.hasNextPage ?? false}
              isLoadingMore={favoritesHook.isFetchingNextPage}
              onLoadMore={() => {
                return fetchNextPage();
              }}
              searchText={searchTextSavedRecipe}
              setSearchText={setSearchTextSavedRecipe}
            />
          ),
        },
      ]}
    />
  );
}
