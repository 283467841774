import { Text } from '@/components/base/text';

type NutrittionItemProps = {
  label: string;
  value: string | number;
};

export const NutrittionItem = ({ label, value }: NutrittionItemProps) => {
  return (
    <Text className="nutrittion-item">
      {label}:
      <Text as="span" className="values">
        {value}
      </Text>
    </Text>
  );
};
