/**
 * The search box UI for the favorites tab
 */

import styled from 'styled-components';

import { theme } from '@/theme/index';
import { Box } from '@/components/base/box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faInbox } from '@fortawesome/free-solid-svg-icons';
import { ItalicText, Text } from '@/components/base/text';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Flex } from '@/components/base/flex';
import { TextLink } from '@/components/base/text-link';

import { SavedRecipesList } from '@/components/recipes/RecipesSearchFavoriteTab/SavedRecipesList';

import { Form, Formik, FormikProvider, useFormik } from 'formik';
import { FormikTextInputField } from '@/components/base/formik/FormikTextInputField';
import { SavedRecipe } from '@/hooks/use-favorite-recipes';
import { RecipeRatingDetails } from '@/hooks/use-user-recipe-ratings';

interface FormData {
  query: string;
}
export interface FavoritesSearchFormProps {
  value: string;
  onSetValue: (value: string) => unknown;
}

export function FavoritesSearchForm({
  value,
  onSetValue,
}: FavoritesSearchFormProps) {
  const formik = useFormik({
    initialValues: { query: value },
    onSubmit(values) {},
  });
  return (
    <StyledFilterWrap
      marginBottom={'s'}
      minHeight={'36px'}
      alignItems={'center'}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormikTextInputField
            showClearText={true}
            onClearTextClick={() => onSetValue('')}
            width={'100%'}
            placeholder={'Search by Recipe or Ingredient...'}
            name="query"
            value={value}
            onChange={(newValue) => onSetValue(newValue.target.value)}
          />
        </Form>
      </FormikProvider>
    </StyledFilterWrap>
  );
}

const StyledFilterWrap = styled(Flex)`
  border-bottom: 1px solid
    ${theme.colors.recipeSearchFavoriteTabFiltersBorderBottom};
  padding-left: ${theme.space.s}px;
  padding-right: ${theme.space.s}px;
  margin-left: -${theme.space.s}px;
  margin-right: -${theme.space.s}px;
  padding-bottom: ${theme.space.s}px;

  & form {
    width: 100%;
  }
`;
