import { Box } from '@/components/base/box';
import { Text } from '@/components/base/text';
import { Flex } from '@/components/base/flex';
import styled from '@emotion/styled';
import {
  formatMinutesToHumanReadableMinsOrHrs,
  formatMinutesToHumanReadableNumberOfMinsOrHours,
} from '@/utils/format-number';

const StyledRow = styled(Flex)``;

StyledRow.defaultProps = {
  backgroundColor: 'gray82',
  color: 'textGray20',
  justifyContent: 'space-evenly',
  marginBottom: '4xs',
  height: 50,
  width: 100,
};

const StyledBoldText = styled(Text)`
  text-transform: uppercase;
`;

StyledBoldText.defaultProps = {
  variant: '2xsBold',
};

const StyledMins = styled(Text)``;

StyledMins.defaultProps = {
  marginBottom: -5,
  marginTop: 5,
};

const StyledQuantity = styled(Text)`
  width: 36%;
`;

StyledQuantity.defaultProps = {
  variant: '4xl',
  textAlign: 'right',
};

type ServingsProps = {
  prepTime: number;
  totalTime: number;
};

export const Servings = ({ prepTime, totalTime }: ServingsProps) => {
  return (
    <Box>
      <StyledRow borderTopLeftRadius="xs" borderTopRightRadius="xs">
        <StyledQuantity color={'white'}>
          {formatMinutesToHumanReadableNumberOfMinsOrHours(prepTime)}
        </StyledQuantity>
        <Box>
          <StyledMins>
            {formatMinutesToHumanReadableMinsOrHrs(prepTime)}
          </StyledMins>
          <StyledBoldText>prep</StyledBoldText>
        </Box>
      </StyledRow>
      <StyledRow borderBottomLeftRadius="xs" borderBottomRightRadius="xs">
        <StyledQuantity>
          {formatMinutesToHumanReadableNumberOfMinsOrHours(totalTime)}
        </StyledQuantity>
        <Box>
          <StyledMins>
            {formatMinutesToHumanReadableMinsOrHrs(totalTime)}
          </StyledMins>
          <StyledBoldText>total</StyledBoldText>
        </Box>
      </StyledRow>
    </Box>
  );
};
