import { faHeart, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RoundedButton } from '@/components/base/buttons';
import { useTheme } from '@emotion/react';

type FavoriteProps = {
  onToggle?: () => void;
  isActive?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
};

export const Favorite = ({
  onToggle = () => {},
  isActive = false,
  isLoading = false,
  disabled = false,
}: FavoriteProps) => {
  const theme = useTheme();
  const iconColor = isActive ? theme.colors.warning : theme.colors.buttonGray35;

  return (
    <RoundedButton disabled={disabled || isLoading} onClick={onToggle}>
      {isLoading ? (
        <FontAwesomeIcon spin={true} icon={faSpinner} width={14} height={14} />
      ) : (
        <FontAwesomeIcon icon={faHeart} size="lg" color={iconColor} />
      )}
    </RoundedButton>
  );
};
