import React, { MouseEventHandler, ReactNode } from 'react';
import styled from '@emotion/styled';

const StyledLabel = styled.label`
  padding: ${({ theme }) => theme.space.s}px 5px
    ${({ theme }) => theme.space.s}px 26px;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes['2xs']};
  line-height: ${({ theme }) => theme.lineHeights['4xs']};
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
`;

const StyledInput = styled.input`
  float: left;
  margin-left: -${({ theme }) => theme.space.l}px;
  width: auto;
  min-height: 20px;
`;

export type SimpleCheckboxProps = {
  label: string | ReactNode;
  name?: string;
  defaultValue?: string | number | ReadonlyArray<string>;
  value?: string | ReadonlyArray<string> | number;
  checked?: boolean;
  defaultChecked?: boolean;
  onClick?: MouseEventHandler<any>;
};

export const SimpleCheckbox = ({
  label,
  name,
  defaultValue,
  value,
  onClick,
  checked,
  defaultChecked,
}: SimpleCheckboxProps) => {
  return (
    <StyledLabel>
      <StyledInput
        defaultValue={defaultValue}
        value={value}
        defaultChecked={defaultChecked}
        checked={checked}
        type="checkbox"
        name={name}
        onClick={onClick}
      />
      {label}
    </StyledLabel>
  );
};
