import { create } from 'zustand';
import { Dish } from '@/hooks/use-household-dinner';

interface AllRecipesState {
  recipesToHide: Dish[];
  setRecipesToHide: (recipesToHide: Dish[]) => void;
  fullscreen: boolean;
  setFullscreen: (fullscreen: boolean) => void;
}

export const useAllRecipesStore = create<AllRecipesState>((set) => {
  return {
    recipesToHide: [],
    setRecipesToHide: (recipesToHide) => set({ recipesToHide: recipesToHide }),
    fullscreen: false,
    setFullscreen: (fullscreen) => set({ fullscreen: fullscreen }),
  };
});
