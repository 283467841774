import { deleteRequest } from '@/services/httpClient';

export type RemoveFavoriteRecipeRequest = {
  savedRecipeId: number;
};

const removeFavoriteRecipe = ({
  savedRecipeId,
}: RemoveFavoriteRecipeRequest): Promise<any> => {
  return deleteRequest(`/api/v1/household_saved_recipe/${savedRecipeId}`, {});
};

export default removeFavoriteRecipe;
