import { hasWindowProperty } from '@/utils/window';
import { useLayoutEffect, useState, useCallback, useMemo } from 'react';

/*
 * An array of media queries which conform to the
 * [matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia)
 * spec.
 */
export type MediaQueries = Array<string>;

/*
 * An ordered array of booleans which are true if the related query in
 * MediaQueries is matched, false if not.
 */
export type MatchedMedia = Array<boolean>;

export function useMatchMedia(
  queries: MediaQueries,
  defaultValues: MatchedMedia = [],
): MatchedMedia {
  const initialValues =
    defaultValues.length > 0
      ? defaultValues
      : Array(queries.length).fill(false);

  const getValue = (): MatchedMedia => {
    if (hasWindowProperty('matchMedia') == false) {
      return initialValues;
    }

    // Return the value for the given queries
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));
    const matchedQueries = mediaQueryLists?.map((mql) => mql.matches);

    return matchedQueries;
  };

  const [value, setValue] = useState(getValue);

  useLayoutEffect(() => {
    if (hasWindowProperty('matchMedia')) {
      const mediaQueryLists = queries.map((q) => window.matchMedia(q));
      // Event listener callback
      // Note: By defining getValue outside useEffect we ensure that it has ...
      // ... current values of hook args (as this hook only runs on mount/dismount).
      const handler = (): void => setValue(getValue);
      mediaQueryLists.forEach((mql) => mql.addListener(handler));

      return (): void =>
        mediaQueryLists.forEach((mql) => mql.removeListener(handler));
      // this dep array is intentionally empty
    }
  }, []);

  return value;
}
