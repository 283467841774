import { Recipe } from '@/hooks/use-recipe';

import { Box } from '@/components/base/box';
import { Flex } from '@/components/base/flex';
import { Image } from '@/components/base/image';
import { Rating } from '@/components/base/rating';
import { Text } from '@/components/base/text';
import { TextLink } from '@/components/base/text-link';
import {
  formatNumber2Decimal,
  formatNumberWithoutDecimal,
} from '@/utils/format-number';
import styled from '@emotion/styled';
import {
  faCalendar,
  faClock,
  faHeart,
  faPieChart,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DishTrafficLights } from './DishTrafficLights';

import { RecipeRatingDetails } from '@/hooks/use-user-recipe-ratings';

import { NutritionFact } from './NutritionFact';
import { formatFields } from '@/utils/lang';

const StyledRecipesListItem = styled(Flex)`
  padding: 15px;
`;
// TODO: Change this to responsive design and units (not px)
// or at least integrate this into the theme config, so it's not
// hardcoded in js
const DISH_WIDTH = 140;
const DISH_HEIGHT = 140;

const MOBILE_DISH_WIDTH = 100;
const MOBILE_DISH_HEIGHT = 100;

const RestrictionList = styled(Flex)`
  & > ul {
    list-style: none;
  }
`;

const StyledDishWrap = styled(TextLink)`
  display: block;
  position: relative;
  cursor: pointer;
`;

const StyledDishImage = styled(Image)`
  width: ${MOBILE_DISH_WIDTH}px;
  height: ${MOBILE_DISH_HEIGHT}px;
  border-radius: ${({ theme }) => theme.radii['2xs']};
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.m} {
    width: ${DISH_WIDTH}px;
    height: ${DISH_HEIGHT}px;
  }
`;

const StyledRecipeTitle = styled(TextLink)`
  color: ${({ theme }) => theme.colors.textGray20};
  font-size: ${({ theme }) => theme.fontSizes.s};
  ${({ theme }) => theme.mediaQueries.l} {
    font-size: ${({ theme }) => theme.fontSizes.l};
  }
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin-bottom: ${({ theme }) => theme.space['xs']}px;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mediaQueries.m} {
    font-size: ${({ theme }) => theme.fontSizes.l};
  }
`;

const StyledRecipeStats = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space['xs']}px;
  display: inline-block;
  white-space: nowrap;
`;

const StyledRecipeStateName = styled(Text)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  padding-right: ${({ theme }) => theme.space['3xs']}px;
  padding-left: ${({ theme }) => theme.space['3xs']}px;
  font-size: ${({ theme }) => theme.fontSizes['5xs']};
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxLeftArrowColor};
`;

const StyledRecipeStateValue = styled(Text)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-right: ${({ theme }) => theme.space['3xs']}px;
  padding-left: ${({ theme }) => theme.space['3xs']}px;
  font-size: ${({ theme }) => theme.fontSizes['5xs']};
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxLeftArrowColor};
`;

const StyleRecipeStatsSeparator = styled(StyledRecipeStats)`
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxRecipeStatSeparatorColor};
  padding-right: ${({ theme }) => theme.space['3xs']}px;
  padding-left: ${({ theme }) => theme.space['3xs']}px;
`;

const StyledRecipeActionWrap = styled(Flex)`
  border-radius: 50%;
  font-size: ${({ theme }) => theme.fontSizes['xl']};
  width: 44px;
  height: 44px;
  padding: ${({ theme }) => theme.space['xs']};
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid ${({ theme }) => theme.colors.gray82};
  position: relative;
  cursor: pointer;
  margin: ${({ theme }) => theme.space['3xs']}px;
  justify-content: center;
  align-items: center;
`;

const StyledRecipeActionWrapPlus = styled(StyledRecipeActionWrap)`
  &:hover {
    color: ${({ theme }) => theme.colors.primaryLight200};
  }
`;

const StyledRecipeActionWrapHeart = styled(StyledRecipeActionWrap)`
  &:hover {
    color: red;
  }
`;

function getListedNutritionalFacts(recipe: Recipe): {
  hasWarning: 'yellow' | undefined;
  keyword: string;
  name: string;
  value: string;
}[] {
  const yellowFields = new Set(recipe.yellowFields ?? []);
  const nutrients = [
    {
      keyword: 'calories_f',
      name: 'Calories',
      value: formatNumberWithoutDecimal(recipe.calories),
    },
    {
      keyword: 'protein_f',
      name: 'Protein',
      value: `${formatNumberWithoutDecimal(recipe.protein)} g`,
    },
    {
      keyword: 'net_carbohydrates_f',
      name: 'Net Carbs',
      value: `${formatNumberWithoutDecimal(recipe.netCarbohydrates)} g`,
    },
    {
      keyword: 'fat_f',
      name: 'Total Fat',
      value: `${formatNumberWithoutDecimal(recipe.fat)} g`,
    },

    {
      keyword: 'saturated_fat_f',
      name: 'Saturated Fat',
      value: `${formatNumberWithoutDecimal(recipe.saturatedFat)} g`,
    },
    {
      keyword: 'sodium_f',
      name: 'Sodium',
      value: `${formatNumberWithoutDecimal(recipe.sodium)} mg`,
    },
    {
      keyword: 'ww_points_f',
      name: 'smartPoints',
      value: `${formatNumberWithoutDecimal(recipe.smartPoints)}`,
    },
  ].map((item) =>
    yellowFields.has(item.keyword)
      ? { ...item, hasWarning: 'yellow' as const }
      : { ...item, hasWarning: undefined },
  );
  if (recipe.yellowFields.length > 0) {
    // debugger
  }
  return nutrients;
}
export interface RecipePreviewCardProps {
  recipe: Recipe;
  recipeRating: RecipeRatingDetails | undefined;
  isFavorite: boolean;
  isFavoriteStatus?: 'idle' | 'error' | 'loading' | 'success';
  showSelectMealDateOption?: boolean;
  onToggleFavoriteRecipe?: (isFavorite: boolean) => void;
  // TODO refactor these numerous nested callbacks to simplify the logic

  onAddRecipe?: (date?: Date, dinnerId?: number) => void;
  onRateRecipe?: (newValue: number) => void;
  onClearRating?: (recipeRatingId?: number) => void;
}

export function RecipePreviewCard({
  recipe,
  recipeRating,
  isFavorite,
  showSelectMealDateOption = false,
  onToggleFavoriteRecipe = () => {},
  onAddRecipe = () => {},
  onRateRecipe = () => {},
  onClearRating = () => {},
}: RecipePreviewCardProps) {
  const rating = recipeRating?.rating ?? recipe.communityRating;

  // const nutrients = getListedNutritionalFacts(recipe);
  const nutrients: ReturnType<typeof getListedNutritionalFacts> =
    getListedNutritionalFacts(recipe);
  return (
    <StyledRecipesListItem key={recipe.id}>
      <StyledDishWrap href={`/recipes/${recipe.id}`} target={'_blank'}>
        <StyledDishImage alt={recipe.title} src={recipe.thumb} />
        <DishTrafficLights
          mobileDishWidth={`${MOBILE_DISH_WIDTH}px`}
          dishWidth={`${DISH_WIDTH}px`}
          recipe={recipe}
        ></DishTrafficLights>
      </StyledDishWrap>

      <Box width={'100%'} marginLeft={'s'}>
        <Flex justifyContent={'space-between'}>
          <Box>
            <StyledRecipeTitle href={`/recipes/${recipe.id}`} target={'_blank'}>
              {recipe.title}
            </StyledRecipeTitle>

            <Flex>
              <Rating
                onClearRating={() => {
                  if (recipeRating) {
                    onClearRating(recipeRating.id);
                  }
                }}
                onChange={(newValue: number) => {
                  onRateRecipe(newValue);
                }}
                value={rating}
                readonly={false}
                highlightFilled={!!recipeRating}
              />
              <Text marginLeft={'2xs'}>({recipe.ratingCount})</Text>
            </Flex>

            <Box>
              <StyledRecipeStats>
                <StyledRecipeStateName>Active</StyledRecipeStateName>
                <StyledRecipeStateValue>
                  <FontAwesomeIcon icon={faClock} width={11} height={11} />{' '}
                  {recipe.activePrepTime} mins
                </StyledRecipeStateValue>
              </StyledRecipeStats>

              <StyleRecipeStatsSeparator>|</StyleRecipeStatsSeparator>

              <StyledRecipeStats>
                <StyledRecipeStateName>Total</StyledRecipeStateName>
                <StyledRecipeStateValue>
                  <FontAwesomeIcon icon={faClock} width={11} height={11} />{' '}
                  {recipe.totalPrepTime} mins
                </StyledRecipeStateValue>
              </StyledRecipeStats>

              <StyleRecipeStatsSeparator>|</StyleRecipeStatsSeparator>

              <StyledRecipeStats>
                <StyledRecipeStateName>Per Serving</StyledRecipeStateName>
                <StyledRecipeStateValue>
                  ${formatNumber2Decimal(recipe.pricePerServing, false)}
                </StyledRecipeStateValue>
              </StyledRecipeStats>

              <StyleRecipeStatsSeparator>|</StyleRecipeStatsSeparator>

              <StyledRecipeStats>
                <StyledRecipeStateName>Servings</StyledRecipeStateName>
                <StyledRecipeStateValue>
                  <FontAwesomeIcon icon={faPieChart} size={'sm'} />{' '}
                  {recipe.allowServings.length == 1
                    ? recipe.allowServings[0]
                    : 'Varies'}
                </StyledRecipeStateValue>
              </StyledRecipeStats>
            </Box>
          </Box>

          <Flex flexDirection={['column', 'column', 'row']}>
            <StyledRecipeActionWrapPlus
              onClick={() => {
                onAddRecipe();
              }}
            >
              <FontAwesomeIcon
                icon={showSelectMealDateOption ? faCalendar : faPlus}
              />
            </StyledRecipeActionWrapPlus>

            <StyledRecipeActionWrapHeart
              onClick={() => {
                onToggleFavoriteRecipe(!isFavorite);
              }}
              color={isFavorite ? 'heartRed' : 'textGray20'}
            >
              <FontAwesomeIcon icon={faHeart} />
            </StyledRecipeActionWrapHeart>
          </Flex>
        </Flex>

        <Flex>
          {nutrients.map(({ value, keyword, name, hasWarning }) => (
            <NutritionFact
              key={keyword}
              factName={name}
              factValue={value}
              hasWarning={hasWarning}
            />
          ))}
        </Flex>
        {recipe.redFields.length > 0 && (
          <RestrictionList>
            <Text color="warning">
              This Recipe has ingredients incompatible with your default
              profile: {recipe.redFields.map((x) => formatFields(x)).join(', ')}
              .
            </Text>
          </RestrictionList>
        )}
      </Box>
    </StyledRecipesListItem>
  );
}
