import { post } from '@/services/httpClient';

export type RateRecipeRequest = {
  householdId: number;
  recipeId: number;
  rating: number;
};
const rateRecipe = (request: RateRecipeRequest): Promise<any> => {
  return post(`api/v1/household_recipe_rating`, {
    household: `/api/v1/household/${request.householdId}`,
    recipe: `/api/v1/recipe/${request.recipeId}`,
    rating: request.rating,
  });
};

export default rateRecipe;
