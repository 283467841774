import { formatDate, fromDateStr } from '@/utils/format-date';
import { useAuthenticatedApi } from '@/hooks/use-authenticated-api';
import {
  FavoriteRecipe,
  Recipe,
  RecipeRating,
  transformRecipeResponse,
} from '@/hooks/use-recipe';
import { useQuery } from 'react-query';
import { useAuthenticatedAxios } from '@/context/api-auth';
import { PaginatedListV1 } from '@/types/api-models';
import { startOfDay } from 'date-fns';
export type Dish = {
  id: number;
  uri: string;
  main: boolean;
  servings: number;
  pricePerServing: number;
  recipe: Recipe;
  recipeRating?: RecipeRating;
  favoriteRecipe?: FavoriteRecipe;
  saleSavings: number;
};

export type HouseholdDinner = {
  id: number;
  date: Date;
  dishes: Dish[];
  uri: string;
  householdProfileUri: string;
  baseIngredient?: string;
  caloriesPerServing: number;
  pricePerServing: number;
  pointsPlusPerServing: number;
  proteinPerServing: number;
  sodiumPerServing: number;
  totalTime: number;
  netCarbohydratesPerServing: number;
  fatPerServing: number;
  saturatedFatPerServing: number;
  otherPlans: boolean;
  shoppingTrip?: string;
};

const transformHouseholdDinner = (response: any): HouseholdDinner => {
  return {
    id: response.id,
    date: fromDateStr(response.dinner_date),
    uri: response.resource_uri,
    householdProfileUri: response.profile,
    shoppingTrip: response.shopping_trip,
    dishes: (response.dinner_dishes || []).map((dish: any) => ({
      id: dish.id,
      uri: dish.resource_uri,
      main: dish.is_main_dish,
      recipe: transformRecipeResponse(dish.recipe),
      ...(dish.recipe_rating && {
        recipeRating: {
          id: dish.recipe_rating.id,
          uri: dish.recipe_rating.resource_uri,
          firstRated: new Date(dish.recipe_rating.first_rated),
          lastRated: new Date(dish.recipe_rating.last_rated),
          rating: dish.recipe_rating.rating,
        },
      }),
      ...(dish.saved_recipe && {
        favoriteRecipe: {
          id: dish.saved_recipe.id,
          notes: dish.saved_recipe.notes ?? '',
          ...(dish.saved_recipe.recipe_tags.length > 0 && {
            recipeTags: dish.saved_recipe.recipe_tags,
          }),
          recipeId: parseInt(
            dish.saved_recipe.recipe.replace('/api/v1/recipe/', ''),
          ),
          householdId: parseInt(
            dish.saved_recipe.household.replace('/api/v1/household/', ''),
          ),
        },
      }),
      pricePerServing: dish.price_per_serving,
      saleSavings: dish.sale_savings,
      servings: dish.servings,
    })),
    baseIngredient: response.base_ingredient ?? '',
    caloriesPerServing: response.calories_per_serving,
    pricePerServing: response.price_per_serving,
    pointsPlusPerServing: response.points_plus_per_serving,
    proteinPerServing: response.protein_per_serving,
    sodiumPerServing: response.sodium_per_serving,
    totalTime: response.total_time,
    netCarbohydratesPerServing: response.net_carbohydrates_per_serving,
    fatPerServing: response.fat_per_serving,
    saturatedFatPerServing: response.saturated_fat_per_serving,
    otherPlans: !!response.no_meal,
  };
};

export function getApiEndpoint(startDate: Date, endDate: Date) {
  return `household_dinner?dinner_date__range=${formatDate(
    startDate,
  )},${formatDate(endDate)}` as const;
}

export interface DateRange {
  start: Date;
  end: Date;
}
export interface HouseholdDinnerParams {
  dinnerDateRange: DateRange;
}
export function getQueryKey({ dinnerDateRange }: HouseholdDinnerParams) {
  return [
    'use-household-dinner',
    {
      dinnerDateRange: {
        start: startOfDay(dinnerDateRange.start),
        end: startOfDay(dinnerDateRange.end),
      },
    },
  ] as const;
}

export const useHouseholdDinner = (startDate: Date, endDate: Date) => {
  // const {
  //   data = [],
  //   isLoading,
  //   isFetching,
  //   isError,
  //   refetch,
  //   status,
  //   ...rest
  // } = useAuthenticatedApi({
  //   endpoint: getApiEndpoint(startDate, endDate),
  // });

  // const dinners = data.map(transformHouseholdDinner) as HouseholdDinner[];
  const { axios } = useAuthenticatedAxios();
  const {
    data: dinners = [],
    isLoading,
    isFetching,
    isError,
    refetch,
    status,
    ...rest
  } = useQuery({
    queryKey: getQueryKey({
      dinnerDateRange: { start: startDate, end: endDate },
    }),
    queryFn: async ({ queryKey }) => {
      const { dinnerDateRange } = queryKey[1];

      const res = await axios.get<PaginatedListV1<unknown>>(
        '/api/v1/household_dinner',
        {
          params: {
            dinner_date__range: `${formatDate(
              dinnerDateRange.start,
            )},${formatDate(dinnerDateRange.end)}`,
          },
        },
      );
      return res.data;
    },
    select: (data) => {
      return data.objects.map(transformHouseholdDinner);
    },
  });
  return { ...rest, dinners, isLoading, isFetching, isError, refetch, status };
};

export function sortDishFn(a: Dish, b: Dish): number {
  if (a.main && b.main) {
    return a.id - b.id;
  } else if (a.main) {
    return -1;
  } else if (b.main) {
    return 1;
  } else {
    return a.id - b.id;
  }
}
