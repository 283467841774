import styled from '@emotion/styled';
import { Box } from '@/components/base/box';
import { Flex } from '@/components/base/flex';
import { TextLink } from '@/components/base/text-link';
import { Image } from '@/components/base/image';
import { BaseText, Text } from '@/components/base/text';
import { Recipe } from '@/hooks/use-recipe';
import { RecipePreviewCard } from './RecipePreviewCard';
import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { DishTrafficLights } from './DishTrafficLights';

import { SavedRecipe } from '@/hooks/use-favorite-recipes';
import { RecipeRatingDetails } from '@/hooks/use-user-recipe-ratings';

import { MealDatePicker } from '@/components/meal-planner/MealDatePicker';
import { formatFields } from '@/utils/lang';
import { NutritionFact } from './NutritionFact';
const StyledRecipesList = styled(Box)``;

type SavedRecipeListProps = {
  items: Recipe[];
  favoriteItems: SavedRecipe[];
  showSelectMealDateOption?: boolean;
  recipesRatings?: RecipeRatingDetails[];
  onToggleFavoriteRecipe?: (recipeId: number, savedRecipeId?: number) => void;
  onAddRecipe?: (recipeId: number, date?: Date, dinnerId?: number) => void;
  onRateRecipe?: (
    newValue: number,
    recipeId: number,
    recipeRatingId?: number,
  ) => void;
  onClearRating?: (recipeRatingId: number) => void;
};

/**
 * TODO: rename to RecipeBoxList or similar. It's not
 * only used by saved (favorite) recipes
 * @param props
 * @returns
 */
export const SavedRecipesList = ({
  items = [],
  favoriteItems,
  showSelectMealDateOption = false,
  recipesRatings = [],
  onToggleFavoriteRecipe = () => {},
  onAddRecipe = () => {},
  onRateRecipe = () => {},
  onClearRating = () => {},
}: SavedRecipeListProps) => {
  const theme = useTheme();
  const [recipeToAddForDatePicker, setRecipeToAddForDatePicker] =
    useState<Recipe | null>(null);
  const favoriteItemsByRecipeId = new Map(favoriteItems.map((i) => [i.id, i]));

  return (
    <>
      <StyledRecipesList>
        {items.map((recipe) => {
          const savedRecipe = favoriteItemsByRecipeId.get(recipe.id);
          // const currentRating = ratingMap.get(recipe.id);
          const currentRating = recipesRatings.find(
            (r) => r.recipeId === recipe.id,
          );
          const isFavorite = !!savedRecipe;

          return (
            <RecipePreviewCard
              key={recipe.id}
              recipe={recipe}
              isFavorite={isFavorite}
              /// temporarily set to "success since we don't dynamically
              /// fetch favorites
              isFavoriteStatus="success"
              recipeRating={currentRating}
              // TODO refactor these numerous nested callbacks to simplify the logic

              onAddRecipe={(date, dinnerId) => {
                if (showSelectMealDateOption) {
                  setRecipeToAddForDatePicker(recipe);
                } else {
                  onAddRecipe(recipe.id, date, dinnerId);
                }
              }}
              onToggleFavoriteRecipe={(_newValue) => {
                onToggleFavoriteRecipe(recipe.id, savedRecipe?.id);
              }}
              onRateRecipe={(newValue) => {
                onRateRecipe(newValue, recipe.id, currentRating?.id);
              }}
              onClearRating={() => {
                if (currentRating) {
                  onClearRating(currentRating.id);
                }
              }}
            />
          );
        })}
      </StyledRecipesList>

      {recipeToAddForDatePicker && (
        <MealDatePicker
          isOpen={true}
          mealName={recipeToAddForDatePicker.title}
          onClose={() => setRecipeToAddForDatePicker(null)}
          onChange={(date, dinnerId) => {
            onAddRecipe(recipeToAddForDatePicker.id, date, dinnerId);
            setRecipeToAddForDatePicker(null);
          }}
        />
      )}
    </>
  );
};
