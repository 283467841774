import { post } from '@/services/httpClient';

type suggestDishRequest = {
  householdId: number;
  householdDinnerId: number;
  isMainDish?: boolean;
};

const suggestDish = ({
  householdId,
  householdDinnerId,
  isMainDish = false,
}: suggestDishRequest): Promise<any> => {
  return post(`api/v1/household_dinner_dish`, {
    status_code: 'R',
    household: `/api/v1/household/${householdId}`,
    household_dinner: `/api/v1/household_dinner/${householdDinnerId}`,
    is_main_dish: isMainDish,
  });
};

export default suggestDish;
