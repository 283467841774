import styled from '@emotion/styled';

import { Box } from '@/components/base/box';
import { Details } from '@/components/dinner-recipes/RecipeDetails';
import { RecipeContent } from '@/components/dinner-recipes/RecipeContent';
import { MainHeading } from '@/components/dinner-recipes/RecipeHeading';
import { Dish } from '@/hooks/use-household-dinner';

type StyledRecipeWrapperProps = {
  totalShowing: number;
};

const StyledRecipeWrapper = styled(Box)<StyledRecipeWrapperProps>`
  border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  transition: all 0.5s ease-in-out;
  width: ${({ totalShowing }) => (1 / totalShowing) * 100}%;
  ${({ theme }) => theme.mediaQueries.print} {
    break-after: page;
    width: 100%;
  }
`;

StyledRecipeWrapper.defaultProps = {};

type RecipeProps = {
  orderNumber: number;
  totalShowing: number;
  householdId: number;
  dish: Dish;
  refreshDinner: () => void;
  minDescriptionHeight: number;
  updateMinDescriptionHeight: (height: number) => void;
};

export const Recipe = ({
  orderNumber,
  totalShowing,
  householdId,
  dish,
  refreshDinner,
  minDescriptionHeight,
  updateMinDescriptionHeight,
}: RecipeProps) => {
  return (
    <StyledRecipeWrapper totalShowing={totalShowing}>
      <MainHeading orderNumber={orderNumber} dish={dish} />
      <RecipeContent
        dish={dish}
        householdId={householdId}
        refreshDinner={refreshDinner}
        minDescriptionHeight={minDescriptionHeight}
        updateMinDescriptionHeight={updateMinDescriptionHeight}
      />
      <Details dish={dish} />
    </StyledRecipeWrapper>
  );
};
