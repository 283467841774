/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { CreateUser } from '../models';
// @ts-ignore
import { PaginatedUserList } from '../models';
// @ts-ignore
import { PatchedUser } from '../models';
// @ts-ignore
import { User } from '../models';
/**
 * V2UserApi - axios parameter creator
 * @export
 */
export const V2UserApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersCreate: async (
      createUser: CreateUser,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUser' is not null or undefined
      assertParamExists('usersCreate', 'createUser', createUser);
      const localVarPath = `/api/v2/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUser,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDestroy: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersDestroy', 'id', id);
      const localVarPath = `/api/v2/users/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersList: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {PatchedUser} [patchedUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPartialUpdate: async (
      id: number,
      patchedUser?: PatchedUser,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersPartialUpdate', 'id', id);
      const localVarPath = `/api/v2/users/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedUser,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersRetrieve', 'id', id);
      const localVarPath = `/api/v2/users/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdate: async (
      id: number,
      user: User,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUpdate', 'id', id);
      // verify required parameter 'user' is not null or undefined
      assertParamExists('usersUpdate', 'user', user);
      const localVarPath = `/api/v2/users/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        user,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V2UserApi - functional programming interface
 * @export
 */
export const V2UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = V2UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersCreate(
      createUser: CreateUser,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreate(
        createUser,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDestroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersDestroy(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersList(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedUserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersList(
        limit,
        offset,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {PatchedUser} [patchedUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPartialUpdate(
      id: number,
      patchedUser?: PatchedUser,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersPartialUpdate(
          id,
          patchedUser,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersRetrieve(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUpdate(
      id: number,
      user: User,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdate(
        id,
        user,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V2UserApi - factory interface
 * @export
 */
export const V2UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V2UserApiFp(configuration);
  return {
    /**
     *
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersCreate(
      createUser: CreateUser,
      options?: any,
    ): AxiosPromise<CreateUser> {
      return localVarFp
        .usersCreate(createUser, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDestroy(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersList(
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<PaginatedUserList> {
      return localVarFp
        .usersList(limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {PatchedUser} [patchedUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPartialUpdate(
      id: number,
      patchedUser?: PatchedUser,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .usersPartialUpdate(id, patchedUser, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersRetrieve(id: number, options?: any): AxiosPromise<User> {
      return localVarFp
        .usersRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this user.
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdate(id: number, user: User, options?: any): AxiosPromise<User> {
      return localVarFp
        .usersUpdate(id, user, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for usersCreate operation in V2UserApi.
 * @export
 * @interface V2UserApiUsersCreateRequest
 */
export interface V2UserApiUsersCreateRequest {
  /**
   *
   * @type {CreateUser}
   * @memberof V2UserApiUsersCreate
   */
  readonly createUser: CreateUser;
}

/**
 * Request parameters for usersDestroy operation in V2UserApi.
 * @export
 * @interface V2UserApiUsersDestroyRequest
 */
export interface V2UserApiUsersDestroyRequest {
  /**
   * A unique integer value identifying this user.
   * @type {number}
   * @memberof V2UserApiUsersDestroy
   */
  readonly id: number;
}

/**
 * Request parameters for usersList operation in V2UserApi.
 * @export
 * @interface V2UserApiUsersListRequest
 */
export interface V2UserApiUsersListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof V2UserApiUsersList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof V2UserApiUsersList
   */
  readonly offset?: number;
}

/**
 * Request parameters for usersPartialUpdate operation in V2UserApi.
 * @export
 * @interface V2UserApiUsersPartialUpdateRequest
 */
export interface V2UserApiUsersPartialUpdateRequest {
  /**
   * A unique integer value identifying this user.
   * @type {number}
   * @memberof V2UserApiUsersPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedUser}
   * @memberof V2UserApiUsersPartialUpdate
   */
  readonly patchedUser?: PatchedUser;
}

/**
 * Request parameters for usersRetrieve operation in V2UserApi.
 * @export
 * @interface V2UserApiUsersRetrieveRequest
 */
export interface V2UserApiUsersRetrieveRequest {
  /**
   * A unique integer value identifying this user.
   * @type {number}
   * @memberof V2UserApiUsersRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for usersUpdate operation in V2UserApi.
 * @export
 * @interface V2UserApiUsersUpdateRequest
 */
export interface V2UserApiUsersUpdateRequest {
  /**
   * A unique integer value identifying this user.
   * @type {number}
   * @memberof V2UserApiUsersUpdate
   */
  readonly id: number;

  /**
   *
   * @type {User}
   * @memberof V2UserApiUsersUpdate
   */
  readonly user: User;
}

/**
 * V2UserApi - object-oriented interface
 * @export
 * @class V2UserApi
 * @extends {BaseAPI}
 */
export class V2UserApi extends BaseAPI {
  /**
   *
   * @param {V2UserApiUsersCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2UserApi
   */
  public usersCreate(
    requestParameters: V2UserApiUsersCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2UserApiFp(this.configuration)
      .usersCreate(requestParameters.createUser, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UserApiUsersDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2UserApi
   */
  public usersDestroy(
    requestParameters: V2UserApiUsersDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2UserApiFp(this.configuration)
      .usersDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UserApiUsersListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2UserApi
   */
  public usersList(
    requestParameters: V2UserApiUsersListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return V2UserApiFp(this.configuration)
      .usersList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UserApiUsersPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2UserApi
   */
  public usersPartialUpdate(
    requestParameters: V2UserApiUsersPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2UserApiFp(this.configuration)
      .usersPartialUpdate(
        requestParameters.id,
        requestParameters.patchedUser,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UserApiUsersRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2UserApi
   */
  public usersRetrieve(
    requestParameters: V2UserApiUsersRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2UserApiFp(this.configuration)
      .usersRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UserApiUsersUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2UserApi
   */
  public usersUpdate(
    requestParameters: V2UserApiUsersUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2UserApiFp(this.configuration)
      .usersUpdate(requestParameters.id, requestParameters.user, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
