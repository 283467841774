/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { PaginatedPeapodPriceZoneList } from '../models';
/**
 * V1PeapodpricezoneApi - axios parameter creator
 * @export
 */
export const V1PeapodpricezoneApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peapodPriceZonesList: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/peapod-price-zones/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V1PeapodpricezoneApi - functional programming interface
 * @export
 */
export const V1PeapodpricezoneApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    V1PeapodpricezoneApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async peapodPriceZonesList(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedPeapodPriceZoneList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.peapodPriceZonesList(
          limit,
          offset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V1PeapodpricezoneApi - factory interface
 * @export
 */
export const V1PeapodpricezoneApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V1PeapodpricezoneApiFp(configuration);
  return {
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peapodPriceZonesList(
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<PaginatedPeapodPriceZoneList> {
      return localVarFp
        .peapodPriceZonesList(limit, offset, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for peapodPriceZonesList operation in V1PeapodpricezoneApi.
 * @export
 * @interface V1PeapodpricezoneApiPeapodPriceZonesListRequest
 */
export interface V1PeapodpricezoneApiPeapodPriceZonesListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof V1PeapodpricezoneApiPeapodPriceZonesList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof V1PeapodpricezoneApiPeapodPriceZonesList
   */
  readonly offset?: number;
}

/**
 * V1PeapodpricezoneApi - object-oriented interface
 * @export
 * @class V1PeapodpricezoneApi
 * @extends {BaseAPI}
 */
export class V1PeapodpricezoneApi extends BaseAPI {
  /**
   *
   * @param {V1PeapodpricezoneApiPeapodPriceZonesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1PeapodpricezoneApi
   */
  public peapodPriceZonesList(
    requestParameters: V1PeapodpricezoneApiPeapodPriceZonesListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return V1PeapodpricezoneApiFp(this.configuration)
      .peapodPriceZonesList(
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
