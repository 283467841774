import { useState } from 'react';

export type CommandResponse<T> = {
  errors: string[];
  data: T | null;
  hasErrors: boolean;
  completed: boolean;
  errorsAsOneString: string;
};

const ok = <T>(data: any): CommandResponse<T> => {
  return {
    errors: [],
    hasErrors: false,
    data,
    completed: true,
    errorsAsOneString: '',
  };
};

const badRequest = <T>(errors: string[]): CommandResponse<T> => {
  return {
    errors,
    hasErrors: true,
    data: null,
    completed: false,
    errorsAsOneString: errors.join(','),
  };
};

function useCommandRequest<T = any>(): {
  isLoading: boolean;
  send: (func: () => Promise<T>) => Promise<CommandResponse<T>>;
} {
  const [isLoading, setIsLoading] = useState(false);

  const sendCommandRequest = async (
    func: () => Promise<T>,
  ): Promise<CommandResponse<T>> => {
    try {
      setIsLoading(true);
      const result = await func();
      return ok(result);
    } catch (err: any) {
      //TODO: we need to understand custom errors coming from API
      if (err && err.status === 401) {
        (window.location as any) = '/signin';
        return badRequest<any>([]);
      }

      if (err && err.status === 400) {
        const errors = parseErrorsFromRequest(err.data);
        return badRequest<any>(errors as any);
      }

      return badRequest<any>([
        'An error occurred while processing your request.',
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading: isLoading,
    send: sendCommandRequest,
  };
}

const parseErrorsFromRequest = (data: any) => {
  let errors = [];
  try {
    errors = data.map((x: any) => x.message);
  } catch (e) {}
  return errors;
};

export default useCommandRequest;
