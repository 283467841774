import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faInbox,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Box } from '@/components/base/box';
import { ItalicText, Text } from '@/components/base/text';
import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@/components/base/flex';
import { theme } from '@/theme/index';
import { Form, Formik } from 'formik';
import { FormikTextInputField } from '@/components/base/formik/FormikTextInputField';
import {
  isSearchEnabled,
  SearchRecipesFilter,
} from '@/hooks/use-search-recipes';
import { SavedRecipesList } from '@/components/recipes/RecipesSearchFavoriteTab/SavedRecipesList';
import { SavedRecipe } from '@/hooks/use-favorite-recipes';
import { Recipe } from '@/hooks/use-recipe';
import { RecipeRatingDetails } from '@/hooks/use-user-recipe-ratings';
import { SimpleCheckbox } from '@/components/base/simple-checkbox';
import { Stars } from '@/components/base/stars';
import { FetchNextPageOptions } from 'react-query';
import { Button } from '@/components/base/buttons';
import { Center } from '@/components/base/center';
import { InfiniteLoaderControl } from '../InfiniteLoaderControl';
import { useMealPlanCalendarStore } from '@/store/meal-plan-calendar-store';
import { useTheme } from '@emotion/react';

const StyledFilterWrap = styled(Box)`
  border-bottom: 1px solid
    ${theme.colors.recipeSearchFavoriteTabFiltersBorderBottom};
  padding-left: ${theme.space.s}px;
  padding-right: ${theme.space.s}px;
  margin-left: -${theme.space.s}px;
  margin-right: -${theme.space.s}px;

  & form {
    width: 100%;
  }
`;

const StyledFilterText = styled(Text)`
  font-weight: ${theme.fontWeights.bold};
  margin-left: ${theme.space.m}px;
  margin-right: ${theme.space['2xs']}px;
  color: ${theme.colors.recipeSearchFavoriteTabFiltersText};
  font-size: ${theme.fontSizes['3xs']};
`;

const StyledFilterIconWrap = styled(Box)`
  border-radius: ${theme.radii['50%']};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  border: 1px solid ${theme.colors.recipeSearchFavoriteTabFiltersIconWrapBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors
      .recipeSearchFavoriteTabFiltersIconWrapBorder};
  }
`;

const StyledFilterOptionsWrap = styled(Box)`
  position: absolute;
  line-height: ${theme.lineHeights['4xs']};
  top: 40px;
  right: 0;
  text-align: left;
  background-color: ${theme.colors.recipeBoxFilterOptionsBackground};
  border: none;
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  overflow-y: auto;
  padding: ${theme.space.m}px;
  color: ${theme.colors.recipeBoxFilterOptionsText};
  z-index: ${theme.zIndices.planDayCalendarMoveImage};

  & label {
    padding-top: ${theme.space['3xs']}px;
    padding-bottom: ${theme.space['3xs']}px;
  }
`;

const StyledFilterOptionsSectionTitle = styled(Text)`
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.bold}px;
  font-size: ${theme.fontSizes['4xs']};
  letter-spacing: 1px;
  font-variant-caps: small-caps;
  border-bottom: 1px solid
    ${theme.colors.recipeBoxFilterOptionsSectionTitleBorderBottom};
  margin-bottom: ${theme.space['xs']}px;
`;

const StyledFilterBug = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes['5xs']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  border-radius: 20px;
  padding: ${({ theme }) => theme.space['3xs']}px 10px;
  margin: ${({ theme }) => theme.space['3xs']}px
    ${({ theme }) => theme.space['2xs']}px;
  white-space: nowrap;
  cursor: pointer;
  min-height: 16px;
  height: 20px;
  background-color: ${({ theme }) =>
    theme.colors.recipeBoxFilterOptionsBackground};
  color: ${({ theme }) => theme.colors.recipeBoxFilterBugText};
  border: 1px solid ${({ theme }) => theme.colors.recipeBoxFilterBugBorder};

  &.ingredient-bug {
    background-color: ${({ theme }) => theme.colors.primaryLight100};
  }
`;

const StyledFilterBugText = styled(Text)`
  font-size: ${theme.fontSizes['5xs']};
  font-weight: ${theme.fontWeights.bold};
`;

const StyledFilterBugIcon = styled(Text)`
  font-size: ${theme.fontSizes['5xs']};

  &:hover {
    color: red;
  }
`;

const StyledMessage = styled(Flex)`
  padding: ${theme.space.s}px;
  margin-top: 64px;
  margin-bottom: 64px;
  color: ${theme.colors.recipeSearchFavoriteTabMessageText};
`;

const StyleMessageSearchIcon = styled(Box)`
  position: absolute;
  font-size: 250px;
  color: rgba(237, 237, 237, 0.5);
  left: 0;
  z-index: ${theme.zIndices.behind};

  ${({ theme }) => theme.mediaQueries.l} {
    left: -210px;
  }
`;

const StyledMessageTitle = styled(ItalicText)`
  font-size: ${theme.fontSizes.l};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.recipeSearchFavoriteTabMessageTitle};
`;

const StyledNoResultsFoundMessageTitle = styled(ItalicText)`
  font-size: ${theme.fontSizes.l};
  color: ${theme.colors.recipeSearchFavoriteTabMessageTitle};
`;

const StyledRecipeSearchFavoriteTabMessageBottoms = styled(Text)`
  font-weight: ${theme.fontWeights.bold};
`;

const coursesOptions: { value: string; text: string }[] = [
  {
    value: 'main dish',
    text: 'Main Dish',
  },
  {
    value: 'side dish',
    text: 'Side Dish',
  },
  {
    value: 'appetizer',
    text: 'Appetizer',
  },
  {
    value: 'dessert',
    text: 'Dessert',
  },
  {
    value: 'breakfast',
    text: 'Breakfast',
  },
  {
    value: 'lunch',
    text: 'Lunch',
  },
  {
    value: 'snack',
    text: 'Snack',
  },
  {
    value: 'beverage',
    text: 'Beverage',
  },
  {
    value: 'condiment',
    text: 'Condiment',
  },
];

const starOptions: {
  stars: number;
  value: string;
  text: string;
}[] = [
  { stars: 4, value: '4+ star recipes', text: '4+ star recipes' },
  { stars: 3, value: '3+ star recipes', text: '3+ star recipes' },
  { stars: 2, value: '2+ star recipes', text: '2+ star recipes' },
  { stars: 0, value: 'Not yet rated', text: 'Not Yet rated' },
];

type RecipesSearchTabContentProps = {
  items: Recipe[];
  favoriteItems: SavedRecipe[];
  filter: SearchRecipesFilter;
  recipesRatings?: RecipeRatingDetails[];
  loading?: boolean;
  showSelectMealDateOption?: boolean;
  onFilterChange?: (filter: SearchRecipesFilter) => void;
  onToggleFavoriteRecipe?: (recipeId: number, savedRecipeId?: number) => void;
  // TODO refactor these numerous nested callbacks to simplify the logic

  onAddRecipe?: (recipeId: number, date?: Date, dinnerId?: number) => void;
  onRateRecipe?: (
    newValue: number,
    recipeId: number,
    recipeRatingId?: number,
  ) => void;
  onClearRating?: (recipeRatingId: number) => void;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
};

function CourseOptionFilters(props: {
  options: { value: string; text: string }[];
  onRemoveFilter: (value: string) => void;
}) {
  if (props.options.length === 0) {
    return <></>;
  }
  return (
    <>
      {props.options.map((x) => (
        <StyledFilterBug
          key={x.value}
          data-testid="course-filter-bug"
          onClick={() => props.onRemoveFilter(x.value)}
        >
          <StyledFilterBugText marginRight={'2xs'}>
            {x.text}
          </StyledFilterBugText>

          <StyledFilterBugIcon>
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </StyledFilterBugIcon>
        </StyledFilterBug>
      ))}
    </>
  );
}

function RemoveAllFiltersBug({
  show,
  onClick,
}: {
  show: boolean;
  onClick: () => void;
}) {
  return show ? (
    <StyledFilterBug onClick={onClick}>
      <StyledFilterBugText marginRight={'2xs'}>Clear all</StyledFilterBugText>

      <StyledFilterBugIcon>
        <FontAwesomeIcon icon={faTimes} size="sm" />
      </StyledFilterBugIcon>
    </StyledFilterBug>
  ) : (
    <></>
  );
}

export const RecipesSearchTabContent = ({
  items,
  favoriteItems,
  filter,
  recipesRatings = [],
  loading = false,
  showSelectMealDateOption = false,
  onFilterChange = () => {},
  onToggleFavoriteRecipe = () => {},
  onAddRecipe = () => {},
  onRateRecipe = () => {},
  onClearRating = () => {},
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}: RecipesSearchTabContentProps) => {
  const [ingredients, setIngredients] = useMealPlanCalendarStore((state) => [
    state.recipeBoxSearch?.ingredients,
    state.setRecipeBoxIngredients,
  ]);
  const [searchValue, setSearchValue] = useState(filter.q);
  const [filterOptionsVisible, setFilterOptionsVisible] = useState(false);
  const isFilterModeOn = isSearchEnabled(filter);

  const coursesOptionsSelected = useMemo(
    () =>
      coursesOptions.filter((x) =>
        filter.courses.some((courseName) => courseName === x.value),
      ),
    [coursesOptions, filter],
  );

  const ratingsOptionsSelected = useMemo(
    () =>
      starOptions.filter((x) => filter.ratings.some((xx) => xx === x.value)),
    [starOptions, filter],
  );

  //This prevents from calling API after every key pressed
  useEffect(() => {
    const timer = setTimeout(() => {
      onFilterChange({ ...filter, q: searchValue });
    }, 500);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const onCoursesOptionChecked = (checked: boolean, course: any) => {
    onFilterChange({
      ...filter,
      courses: checked
        ? [...filter.courses, course.value]
        : filter.courses.filter((xx) => xx != course.value),
    });
  };

  const onRatingsOptionChecked = (checked: boolean, rating: any) => {
    onFilterChange({
      ...filter,
      ratings: checked
        ? [...filter.ratings, rating.value]
        : filter.ratings.filter((xx) => xx != rating.value),
    });
  };

  const removeCourseFilter = (value: string) => {
    onFilterChange({
      ...filter,
      courses: filter.courses.filter((xx) => xx != value),
    });
  };

  const removeRatingFilter = (value: string) => {
    onFilterChange({
      ...filter,
      ratings: filter.ratings.filter((xx) => xx != value),
    });
  };

  const removeIngredientFilter = (id: string | number) => {
    onFilterChange({
      ...filter,
      ingredients: (filter.ingredients ?? []).filter(
        (ingredient) => !(ingredient.id === id),
      ),
    });
  };

  const removeAllFilters = () => {
    onFilterChange({
      ...filter,
      courses: [],
      ratings: [],
      ingredients: [],
    });
  };

  const showClearAll = useMemo(
    () =>
      coursesOptionsSelected.length > 0 ||
      ratingsOptionsSelected.length > 0 ||
      ingredients?.length,
    [coursesOptionsSelected, ratingsOptionsSelected, ingredients],
  );
  const theme = useTheme();

  return (
    <>
      <Formik
        initialValues={{ q: searchValue }}
        enableReinitialize
        validateOnChange={false}
        onSubmit={() => {}}
      >
        <Form>
          <StyledFilterWrap>
            <Flex marginBottom={'xs'} minHeight={'36px'} alignItems={'center'}>
              <FormikTextInputField
                onChange={(e: any) => setSearchValue(e.target.value)}
                onClearTextClick={() => setSearchValue('')}
                showClearText={true}
                width={'100%'}
                placeholder={'Search by Recipe or Ingredient...'}
                name={'q'}
                loading={loading}
                value={searchValue}
              />

              <StyledFilterText>Filter</StyledFilterText>

              <Box position={'relative'}>
                <StyledFilterIconWrap
                  cursor={'pointer'}
                  onClick={() => setFilterOptionsVisible(!filterOptionsVisible)}
                >
                  <FontAwesomeIcon icon={faFilter} size="sm" />
                </StyledFilterIconWrap>

                {filterOptionsVisible && (
                  <StyledFilterOptionsWrap>
                    <Box marginBottom={'xs'}>
                      <StyledFilterOptionsSectionTitle>
                        Courses
                      </StyledFilterOptionsSectionTitle>
                      {coursesOptions.map((course) => (
                        <SimpleCheckbox
                          onClick={(e: any) => {
                            onCoursesOptionChecked(e.target.checked, course);
                          }}
                          key={course.value}
                          label={course.text}
                          value={course.value}
                          checked={filter.courses.some(
                            (f) => f === course.value,
                          )}
                        />
                      ))}
                    </Box>

                    <Box>
                      <StyledFilterOptionsSectionTitle>
                        Rating
                      </StyledFilterOptionsSectionTitle>
                      {starOptions.map((rating) => (
                        <SimpleCheckbox
                          onClick={(e: any) => {
                            onRatingsOptionChecked(e.target.checked, rating);
                          }}
                          key={rating.value}
                          label={
                            rating.stars > 0 ? (
                              <>
                                <Stars totalStars={rating.stars} /> & Up
                              </>
                            ) : (
                              'Not Yet Rated'
                            )
                          }
                          value={rating.value}
                          checked={filter.ratings.some(
                            (f) => f === rating.value,
                          )}
                        />
                      ))}
                    </Box>
                  </StyledFilterOptionsWrap>
                )}
              </Box>
            </Flex>
            <Flex marginBottom={'xs'} className="foo">
              <CourseOptionFilters
                options={coursesOptionsSelected}
                onRemoveFilter={removeCourseFilter}
              ></CourseOptionFilters>

              {ratingsOptionsSelected.map((x) => (
                <StyledFilterBug
                  key={x.value}
                  onClick={() => removeRatingFilter(x.value)}
                  data-testid="rating-filter-bug"
                >
                  <StyledFilterBugText marginRight={'2xs'}>
                    {x.text}
                  </StyledFilterBugText>

                  <StyledFilterBugIcon>
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                  </StyledFilterBugIcon>
                </StyledFilterBug>
              ))}

              {(filter.ingredients ?? []).map((ingredient, i) => (
                <StyledFilterBug
                  key={'ingredient' + ingredient.id}
                  onClick={() => removeIngredientFilter(ingredient.id)}
                  className="ingredient-bug"
                  data-testid="ingredient-filter-bug"
                >
                  <StyledFilterBugText marginRight={'2xs'}>
                    {ingredient.name}
                  </StyledFilterBugText>

                  <StyledFilterBugIcon>
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                  </StyledFilterBugIcon>
                </StyledFilterBug>
              ))}
              <RemoveAllFiltersBug
                show={!!showClearAll}
                onClick={removeAllFilters}
              />
            </Flex>
          </StyledFilterWrap>
        </Form>
      </Formik>

      {!isFilterModeOn && items.length === 0 && (
        <StyledMessage justifyContent={'center'} alignItems={'center'}>
          <Box maxWidth={'500px'} textAlign={'center'} position={'relative'}>
            <StyleMessageSearchIcon>
              <FontAwesomeIcon icon={faSearch} />
            </StyleMessageSearchIcon>

            <StyledMessageTitle marginBottom={'xl'}>
              Search for recipes by typing in the search field above
            </StyledMessageTitle>

            <Text marginBottom={'s'}>
              You can search for an ingredient (e.g. eggplant, etc) the name or
              part of the name of a recipe (lasagna), a cuisine (Italian), type
              of dish (e.g. soup), an occasion (Thanksgiving, breakfast,
              tailgating, etc).
            </Text>

            <StyledRecipeSearchFavoriteTabMessageBottoms
              color={'recipeSearchFavoriteTabMessageGreen'}
            >
              Note that searched recipes are not personalized recommendations
              and may not match your &apos;Usual&apos; dietary profile, food
              allergies etc.
            </StyledRecipeSearchFavoriteTabMessageBottoms>
          </Box>
        </StyledMessage>
      )}

      {isFilterModeOn && items.length === 0 && !loading && (
        <StyledMessage justifyContent={'center'} alignItems={'center'}>
          <Box maxWidth={'500px'} textAlign={'center'} position={'relative'}>
            <StyleMessageSearchIcon>
              <FontAwesomeIcon icon={faInbox} />
            </StyleMessageSearchIcon>

            <StyledNoResultsFoundMessageTitle marginBottom={'xl'}>
              We didn&apos;t find matching recipes, please try again
            </StyledNoResultsFoundMessageTitle>

            <ItalicText marginBottom={'s'} variant={'default'}>
              You can search for an ingredient (e.g. eggplant, etc) the name or
              part of the name of a recipe (lasagna), a cuisine (Italian), type
              of dish (e.g. soup), an occasion (Thanksgiving, breakfast,
              tailgating, etc).
            </ItalicText>
          </Box>
        </StyledMessage>
      )}

      {isFilterModeOn && items.length > 0 && (
        <SavedRecipesList
          items={items}
          favoriteItems={favoriteItems}
          recipesRatings={recipesRatings}
          showSelectMealDateOption={showSelectMealDateOption}
          // TODO refactor these numerous nested callbacks to simplify the logic

          onAddRecipe={onAddRecipe}
          onToggleFavoriteRecipe={onToggleFavoriteRecipe}
          onRateRecipe={onRateRecipe}
          onClearRating={onClearRating}
        />
      )}
      <Center>
        <InfiniteLoaderControl
          {...{ fetchNextPage, isFetchingNextPage, hasNextPage }}
        />
      </Center>
    </>
  );
};
