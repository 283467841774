import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';

import { Box } from '@/components/base/box';
import { Button } from '@/components/base/buttons';
import { Flex } from '@/components/base/flex';
import { ItalicText, Text } from '@/components/base/text';
import {
  ModalFooter,
  ModalTitle,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalContent,
} from '@/components/base/modal';
import {
  DropDownSelect,
  DropdownSelectOption,
} from '@/components/base/dropdown';

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space.s}px;
`;

StyledButton.defaultProps = {
  variant: 'primary',
  isFullWidth: true,
};

const StyledDashedHR = styled.hr`
  border: 0;
  border-top: 2px dashed ${({ theme }) => theme.colors.disabled};
`;

const StyledBox = styled(Box)``;

StyledBox.defaultProps = {
  alignSelf: 'center',
};

const StyledDropdownWrapper = styled(Box)`
  & > div > div {
    margin: auto;
  }
`;

type AddNewProfileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => void;
  data?: any;
};

const NEW_PROFILE_URL = '/household_profiles/new';

export const ADD_PROFILE_MODAL_TYPE = 'AddNewProfile';
export const AddNewProfileModal: React.FC<AddNewProfileModalProps> = ({
  isOpen,
  onToggle,
  onClose,
  data,
}) => {
  const router = useRouter();
  const [selectedProfile, setSelectedProfile] = useState<DropdownSelectOption>(
    data && data.length > 0
      ? {
          label: data[0].name,
          value: data[0].name,
        }
      : {
          label: '',
          value: '',
        },
  );

  const redirectToCreateProfile = () => {
    onClose();

    selectedProfile &&
      selectedProfile.value &&
      router.push({
        pathname: `/${NEW_PROFILE_URL}/`,
        query: {
          basis_household_profile: encodeURI(selectedProfile.value),
          commit: encodeURI('Create from copy'),
        },
      });
  };

  if (!data) {
    return null;
  }

  const profileOptions = data.map((p: any) => ({
    label: p.name,
    value: p.id,
  }));

  return (
    <Modal isOpen={isOpen} setIsOpen={onToggle}>
      <ModalOverlay />
      <ModalContent width="560px">
        <ModalHeader>
          <ModalTitle variant="s" paddingX={'m'}>
            Create new profile
          </ModalTitle>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody flexDirection="column" paddingX="3xl">
          <StyledBox textAlign="center" marginBottom="none" paddingY="3xl">
            <Button as="a" variant="primary" href={NEW_PROFILE_URL}>
              Start from scratch
            </Button>
          </StyledBox>
          <StyledDashedHR />
          <StyledBox
            textAlign="center"
            marginBottom="none"
            paddingY="3xl"
            zIndex="notificationMed"
          >
            <ItalicText>
              Or, choose existing profile as starting point
            </ItalicText>
            <StyledDropdownWrapper textAlign="center" paddingTop="xs">
              <DropDownSelect
                options={profileOptions}
                defaultValue={profileOptions[0]}
                onChange={(newSelection) => {
                  setSelectedProfile(newSelection);
                }}
              />
            </StyledDropdownWrapper>
          </StyledBox>
          <Button
            variant="primary"
            marginX="auto"
            disabled={!selectedProfile}
            onClick={redirectToCreateProfile}
          >
            Create from copy
          </Button>
        </ModalBody>
        <ModalFooter flexDirection={'column'}>
          <Flex justifyContent="flex-end">
            <Button variant="secondary" onClick={onClose}>
              <Text variant="2xs">Cancel</Text>
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
