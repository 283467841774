import styled from '@emotion/styled';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from '@/components/base/text';
import { TextLink } from '@/components/base/text-link';
import { RoundedButton } from '@/components/base/buttons';
import { Flex } from '@/components/base/flex';
import { Box } from '@/components/base/box';
import { Image } from '@/components/base/image';
import { Servings } from '@/components/servings';
import { Dish } from '@/hooks/use-household-dinner';
import { useAllRecipesStore } from '@/store/all-recipes-store';

const StyledHeading = styled(Flex)`
  & > p:first-of-type,
  & > a > p {
    color: ${({ theme }) => theme.colors.white};
    line-height: ${({ theme }) => theme.lineHeights['3xl']};
  }
  .recipe-title-link,
  .recipe-title {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .order-number {
    padding: 0 ${({ theme }) => theme.space.s}px;
  }
  .actions-show-hide {
    border: 1px solid
      ${({ theme }) => theme.colors.dinnerRecipeBoxDishActionsColor};
    height: 26px;
    width: 26px;
    line-height: ${({ theme }) => theme.lineHeights['8xs']};
    margin-left: auto;
    margin-right: ${({ theme }) => theme.space.s}px;
    margin-top: ${({ theme }) => theme.space.xs}px;
  }
  .description {
    display: none;
  }
  ${({ theme }) => theme.mediaQueries.print} {
    background-color: ${({ theme }) => theme.colors.white};
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 60px;
    .recipe-title-link {
      max-width: 70%;
      min-width: 55%;
    }
    & > p:first-of-type,
    & > a > p {
      color: ${({ theme }) => theme.colors.text};
      font-size: ${({ theme }) => theme.fontSizes['xl']};
    }
    & > a > p {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
    ${RoundedButton} {
      display: none;
    }
    .order-number {
      padding-left: ${({ theme }) => theme.space.none}px;
    }
    .description {
      display: block;
      font-size: ${({ theme }) => theme.fontSizes['xs']};
      margin-left: ${({ theme }) => theme.space['2xs']}px;
      max-width: 70%;
      min-width: 55%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

StyledHeading.defaultProps = {
  backgroundColor: 'dinnerRecipeTitleBackgroundColor',
  justifyContent: 'space-between',
  padding: 'xs',
};

const StyledPrintContent = styled(Box)`
  display: none;
  ${({ theme }) => theme.mediaQueries.print} {
    display: flex;
    position: absolute;
    right: 10px;
    ${Image} {
      border-radius: ${({ theme }) => theme.radii['xs']};
      height: 100px;
      margin: 0 ${({ theme }) => theme.space['xs']}px;
    }
    & > div > div {
      &:first-of-type {
        border-bottom: solid 1px ${({ theme }) => theme.colors.textGray55};
      }
      background-color: ${({ theme }) => theme.colors.white};
      p {
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`;

type MainHeadingProps = {
  orderNumber: number;
  dish: Dish;
};

export const MainHeading = ({
  orderNumber,
  dish,
  dish: {
    recipe: {
      id: recipeId,
      title: recipeTitle,
      thumb,
      description,
      prepTime,
      totalPrepTime,
    },
  },
}: MainHeadingProps) => {
  const recipesToHide = useAllRecipesStore((state) => state.recipesToHide);
  const setRecipesToHide = useAllRecipesStore(
    (state) => state?.setRecipesToHide,
  );

  const onClick = () => {
    setRecipesToHide([...recipesToHide, dish]);
  };

  return (
    <StyledHeading>
      <Text variant={'2xlBold'} className="order-number">
        {orderNumber}
      </Text>
      <TextLink
        href={`/recipes/${recipeId}?servings=${dish.servings}`}
        className="recipe-title-link"
      >
        <Text variant={'l'} className="recipe-title">
          {recipeTitle}
        </Text>
      </TextLink>
      <Text className="description">{description}</Text>
      <StyledPrintContent>
        <Image src={thumb} alt="Thumb" />
        <Servings prepTime={prepTime} totalTime={totalPrepTime} />
      </StyledPrintContent>
      <RoundedButton className="actions-show-hide" onClick={onClick}>
        <FontAwesomeIcon icon={faEyeSlash} size="sm" />
      </RoundedButton>
    </StyledHeading>
  );
};
