import { formatDate, fromDateStr } from '@/utils/format-date';
import { useAuthenticatedApi } from './use-authenticated-api';
import { HouseholdStore, useHouseholdStores } from './use-household-stores';
import { useAuthenticatedAxios } from '@/context/api-auth';
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { PaginatedListV1 } from '@/types/api-models';
import { startOfDay } from 'date-fns';
import { logger } from '@/services/logging';
import { AxiosInstance } from 'axios';

export type ShoppingTrip = {
  id: number;
  date: Date;
  uri: string;
  store?: HouseholdStore;
  hasBeenViewed?: boolean;
};

const transformShoppingTrip = (
  response: any,
  stores: HouseholdStore[],
): ShoppingTrip => {
  const store = stores.find(
    (store) => store.id == response.store.split('/')[4],
  );
  return {
    id: response.id,
    date: fromDateStr(response.shopping_date),
    uri: response.resource_uri,
    store,
    hasBeenViewed: response.has_been_viewed,
  };
};

const useShoppingTripsQueryKey = (params: { startDate: Date; endDate: Date }) =>
  [
    'use-shopping-trips',
    'list-by-date',
    {
      startDate: startOfDay(params.startDate),
      endDate: startOfDay(params.endDate),
    },
  ] as const;

export const useShoppingTrips = (startDate: Date, endDate: Date) => {
  const { householdStores } = useHouseholdStores();
  const { axios, isAuthenticated } = useAuthenticatedAxios();
  const {
    data = [],
    isLoading,
    isFetching,
    isError,
    refetch,
    ...rest
  } = useQuery(
    useShoppingTripsQueryKey({ startDate, endDate }),
    async (ctx) => {
      const { startDate, endDate } = ctx.queryKey[2];
      return (
        await axios.get<PaginatedListV1<any>>('/api/v1/shopping_trip', {
          params: {
            shopping_date__range: `${formatDate(startDate)},${formatDate(
              endDate,
            )}`,
            limit: 1000,
          },
        })
      ).data.objects;
    },
    {
      enabled: isAuthenticated,
    },
  );

  const shoppingTrips = data.map((response: any) =>
    transformShoppingTrip(response, householdStores),
  ) as ShoppingTrip[];

  return { shoppingTrips, isLoading, isFetching, isError, refetch, ...rest };
};

export function fetchDeleteShoppingTrip(axios: AxiosInstance) {
  return async (params: { uri: string }) => {
    await axios.delete(params.uri, {});

    return;
  };
}

export function useDeleteShoppingTrip() {
  const { isAuthenticated, axios } = useAuthenticatedAxios();
  const qc = useQueryClient();
  const mutationFn = fetchDeleteShoppingTrip(axios);
  const mutation = useMutation(mutationFn, {
    async onSuccess() {
      await Promise.all([
        qc.invalidateQueries(['use-shopping-trips']),
        qc.invalidateQueries({
          queryKey: ['query-api-authenticated', 'shopping_trip'],
        }),
        qc.invalidateQueries({
          queryKey: ['query-api-authenticated', 'shopping_trip'],
        }),
        qc.invalidateQueries({
          queryKey: ['use-household-dinner'],
        }),
      ]);
    },
  });

  return { isAuthenticated, ...mutation };
}

export interface ShoppingTripByIdParams {
  id: number;
}

export function shoppingTripsByIdQueryKey({ id }: ShoppingTripByIdParams) {
  return ['use-shopping-trips', 'detail', { id }] as const;
}
export type ShoppingTripsByIdQueryKey = ReturnType<
  typeof shoppingTripsByIdQueryKey
>;

export function fetchShoppingTripById(
  axios: AxiosInstance,
): QueryFunction<unknown, ShoppingTripsByIdQueryKey> {
  return async ({ queryKey }) => {
    const { id } = queryKey[2];
    const resp = await axios.get<unknown>(`/api/v1/shopping_trip/${id}`);
    return resp.data;
  };
}
export function useShoppingTripsById(params: ShoppingTripByIdParams) {
  const queryKey = shoppingTripsByIdQueryKey(params);
  const { axios } = useAuthenticatedAxios();

  const query = useQuery(
    shoppingTripsByIdQueryKey(params),
    fetchShoppingTripById(axios),
    {},
  );
  return query;
}
