import { V2HouseholdsavedrecipeApi } from '@/api-client/client-v2/api';
import { useV2Api } from '@/context/api-auth/v2-client-provider';
import {
  computeNetCarbs,
  getAllowedServings,
  Recipe,
  transformRecipeResponse,
} from '@/hooks/use-recipe';
import { get } from '@/services/httpClient';
import { PaginatedListV1 } from '@/types/api-models';
import { SavedRecipeExt } from '@/types/api-models/frontend';
import {
  HouseholdSavedRecipe,
  HouseholdSavedRecipeSerializerNoDetails,
  PaginationV2,
} from '@/types/api-models/v2';
import { getAssetBase } from '@/utils/asset-base';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import {
  QueryFunction,
  QueryFunctionContext,
  useInfiniteQuery,
  useQuery,
} from 'react-query';
import { fetchAllPagesV2 } from './use-all-objects-data';
const endpoint = 'api/v1/household_saved_recipe';
export const transformSavedRecipeResponse = (item: any): SavedRecipe => {
  return {
    ...transformRecipeResponse(item),
    savedRecipeId: item.savedRecipeId,
  };
};

function getImageURL(path: string | null | undefined) {
  const assetBase = getAssetBase();
  return (path && new URL(path, assetBase).href) ?? '';
}
export function transformSavedRecipeV2(
  item: HouseholdSavedRecipe,
): SavedRecipe {
  const recipe = item.recipe_details;
  const allowedServings = getAllowedServings(recipe.allowed_serving_counts);

  const maxAllowedServings = Math.max(...allowedServings);
  const minAllowedServings = Math.min(...allowedServings);

  let meetsProfileFlag: 'green' | 'red' | 'yellow';
  if (recipe.red_fields.length > 0) {
    meetsProfileFlag = 'red';
  } else if (recipe.yellow_fields.length > 0) {
    meetsProfileFlag = 'yellow';
  } else {
    meetsProfileFlag = 'green';
  }
  return {
    savedRecipeId: item.id,
    id: item.recipe_id,
    title: recipe.title,
    image: getImageURL(recipe.image_path),
    thumb: getImageURL(recipe.image_thumb_path),
    uri: `/api/v1/recipe/${recipe.id}`,
    mainIngredients: recipe.main_ingredients!,
    communityRating: recipe.community_rating!,
    pricePerServing: recipe.price_per_serving!,
    ratingCount: recipe.rating_count!,
    description: recipe.description!,
    skillLevel: recipe.skill_level!,
    servingCount: recipe.serving_count!,
    prepTime: recipe.cook_time!,
    totalPrepTime: recipe.total_time!,
    calories: recipe.calories_f!,
    protein: recipe.protein_f!,
    dietaryFiber: recipe.dietary_fiber_f!,
    carbohydrates: recipe.carbohydrates_f!,
    totalSugars: recipe.total_sugars_f!,
    fat: recipe.fat_f!,
    saturatedFat: recipe.saturated_fat_f!,
    transFattyAcid: recipe.trans_fatty_acid_f!,
    cholesterol: recipe.cholesterol_f!,
    sodium: recipe.sodium_f!,
    smartPoints: recipe.ww_points_f!,
    ingredientsText: '',
    instructionsText: '',
    activePrepTime: recipe.active_prep_time!,
    // TODO: update api to support yellow/green/red light
    // for saved recipe,
    meetsProfileFlag,
    meetsAllNutritionRequirementsInProfile: meetsProfileFlag === 'green',
    meetsPartiallyNutritionRequirementsInProfile: meetsProfileFlag === 'yellow',
    meetsNoNutritionRequirementsInProfile: meetsProfileFlag === 'red',
    allowServings: allowedServings,
    netCarbohydrates: computeNetCarbs(
      recipe.carbohydrates_f!,
      recipe.dietary_fiber_f!,
    ),
    source: recipe.source!,
    sourceUrl: recipe.source_url!,
    sourceLogoUrl: recipe.source_logo_url!,
    sourceLogoPath: recipe.source_logo_path!,
    maxAllowedServings,
    minAllowedServings,
    yellowFields: recipe.yellow_fields ?? [],
    redFields: recipe.red_fields ?? [],
  };
}

export type SavedRecipe = Recipe & {
  savedRecipeId: number;
};

export function favoriteRecipesPaginatedQueryKey(params: {
  pageSize?: number;
  recipe?: number | undefined;
  search?: string | undefined;
  showRecipeDetails?: boolean;
}) {
  return [
    'household_saved_recipe',
    'list-frontendV2',
    {
      pageSize: params.pageSize,
      search: params.search || undefined,
      ...params,
    },
  ] as const;
}
type FavoiteRecipePaginatedContext = QueryFunctionContext<
  ReturnType<typeof favoriteRecipesPaginatedQueryKey>
>;

export function favoriteRecipesByRecipeQueryKey(params: {
  recipe: number;
  showRecipeDetails?: boolean;
}) {
  return [
    'household_saved_recipe',
    'list-frontendV2',
    'by-recipe',
    {
      ...params,
    },
  ] as const;
}

interface FetchFavoriteResult extends PaginatedListV1<SavedRecipeExt> {
  pageParam: number;
}

export const fetchFavoritesByRecipeV2 =
  (api: V2HouseholdsavedrecipeApi) =>
  async ({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof favoriteRecipesByRecipeQueryKey>
  >) => {
    const params = queryKey[3];
    const resp = await api.savedRecipesList({ ...params });
    return resp.data;
  };

export const fetchRecipesV2 =
  (api: V2HouseholdsavedrecipeApi) =>
  async ({ pageParam = 0, queryKey }: FavoiteRecipePaginatedContext) => {
    const {
      search,
      pageSize = 10,
      showRecipeDetails = true,
      ...otherParams
    } = queryKey[2];
    const resp = await api.savedRecipesList({
      showRecipeDetails,
      limit: pageSize,
      offset: pageParam * pageSize,
      search: search,

      ...otherParams,
    });

    return { data: resp.data as PaginationV2<HouseholdSavedRecipe>, pageParam };
  };

export interface useFavoriteRecipesPaginatedOptions {
  pageSize?: number;
  search?: string;
}

export function useFavoriteRecipesPaginated({
  pageSize = 10,
  search = undefined,
  ...params
}: useFavoriteRecipesPaginatedOptions) {
  const { api } = useV2Api(V2HouseholdsavedrecipeApi);
  const { data, ...favoritesQuery } = useInfiniteQuery(
    favoriteRecipesPaginatedQueryKey({ pageSize, search, ...params }),
    fetchRecipesV2(api),
    {
      getNextPageParam(lastPage, pages) {
        if (lastPage.data.next) {
          return lastPage.pageParam + 1;
        }
      },
      getPreviousPageParam(lastPage) {
        if (lastPage.data.previous) {
          return lastPage.pageParam - 1;
        }
      },
      select: ({ pages, pageParams }) => {
        return {
          pageParams,
          pages: pages.map(({ data }) => {
            return {
              data,
              totalCount: data.count,

              originalObjects: data.results,
              objects: data.results.map((savedRecipe) =>
                transformSavedRecipeV2(savedRecipe),
              ),
            };
          }),
        };
      },
    },
  );
  const { totalCount, allFavorites } = useMemo(() => {
    if (!data) {
      return {};
    }
    return {
      totalCount: data.pages.length > 0 ? data.pages[0].totalCount : undefined,
      allFavorites: data.pages.flatMap((page) => page.objects),
    };
  }, [data]);

  return {
    ...favoritesQuery,
    data,
    allFavorites,
    totalCount,
  };
}
/**
 *
 * @param recipeId
 * @returns
 */
export const useFavoriteRecipeCheck = (recipeId: number) => {
  const {
    data: savedFavorite,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['query-api-authenticated', `${endpoint}?recipe__in=${recipeId}`],
    async () => {
      const results = await get(`${endpoint}?recipe__in=${recipeId}`);
      const savedRecipe =
        (results && results.objects?.length > 0 && results.objects[0]) || null;

      if (!!savedRecipe) {
        savedRecipe.savedRecipeId = parseInt(
          savedRecipe.recipe.split('/').pop(),
        );
      }

      return savedRecipe;
    },
    { refetchOnWindowFocus: false },
  );

  return { savedFavorite, isLoading, refreshRecipe: refetch, isError };
};

export interface ListParams {
  search?: string;
  limit?: number;
  offset?: number;
  recipe?: number;
  recipe__in?: number[];
}

export type SavedRecipesListKey = Readonly<
  [string, string, { params: ListParams }]
>;

export function queryKeySavedRecipesList(params: ListParams) {
  return ['v2-savedrecipes', 'list', { params }] as const;
}
export function queryKeyAllSavedRecipesListByIds(params: {
  recipe__in: number[];
}) {
  return ['v2-savedrecipes', 'list-all', { params }] as const;
}

export function fetchSavedRecipesList(
  axios: AxiosInstance,
  options?: AxiosRequestConfig<any> | undefined,
) {
  return async (ctx: QueryFunctionContext<SavedRecipesListKey>) => {
    const params = ctx.queryKey[2].params;
    const apiParams = { ...params, ...options?.params };
    return await axios.get('/api/v2/saved-recipes/', {
      ...options,
      params: apiParams,
    });
  };
}

export function fetchSavedRecipesByRecipeIds(
  axios: AxiosInstance,
  maxPages: number = 10,
) {
  return async (
    ctx: QueryFunctionContext<
      ReturnType<typeof queryKeyAllSavedRecipesListByIds>
    >,
  ) =>
    fetchAllPagesV2(axios, '/api/v2/saved-recipes/', {
      params: { recipe__in: ctx.queryKey[2].params.recipe__in?.join(',') },
    });
}
