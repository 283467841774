export function formatNumberWithoutDecimal(
  source: number,
  roundUp = true,
): string {
  if (roundUp) {
    source = Math.round(source);
  }
  return source.toFixed(0);
}

export function formatNumber2Decimal(source: number, roundUp = true): string {
  if (roundUp) {
    source = Math.round(source);
  }
  return source.toFixed(2);
}

export function formatNumber1Decimal(source: number, roundUp = true): string {
  if (roundUp) {
    source = Math.round(source);
  }
  return source.toFixed(1);
}

export function formatMinutesToHumanReadableNumberOfMinsOrHours(
  minutes: number,
) {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;

  if (hrs === 0) {
    return `${minutes}`;
  } else {
    if (mins === 0) {
      return `${hrs}`;
    } else if (mins <= 15) {
      return `${hrs}¼`;
    } else if (mins <= 30) {
      return `${hrs}½`;
    } else if (mins <= 60) {
      return `${hrs}¾`;
    }
  }
}

export function formatMinutesToHumanReadableMinsOrHrs(minutes: number) {
  if (minutes < 60) {
    if (minutes === 1) {
      return 'min';
    } else {
      return 'mins';
    }
  } else {
    if (minutes === 60) {
      return 'hour';
    } else {
      return 'hours';
    }
  }
}
