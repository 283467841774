/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { PaginatedPromoCodeList } from '../models';
// @ts-ignore
import { PromoCode } from '../models';
/**
 * V2PromocodeApi - axios parameter creator
 * @export
 */
export const V2PromocodeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [code]
     * @param {string} [codeIexact]
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promocodesList: async (
      code?: string,
      codeIexact?: string,
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/promocodes/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (codeIexact !== undefined) {
        localVarQueryParameter['code__iexact'] = codeIexact;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this promo code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promocodesRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('promocodesRetrieve', 'id', id);
      const localVarPath = `/api/v2/promocodes/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V2PromocodeApi - functional programming interface
 * @export
 */
export const V2PromocodeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    V2PromocodeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [code]
     * @param {string} [codeIexact]
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async promocodesList(
      code?: string,
      codeIexact?: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedPromoCodeList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.promocodesList(
        code,
        codeIexact,
        limit,
        offset,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this promo code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async promocodesRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCode>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.promocodesRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V2PromocodeApi - factory interface
 * @export
 */
export const V2PromocodeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V2PromocodeApiFp(configuration);
  return {
    /**
     *
     * @param {string} [code]
     * @param {string} [codeIexact]
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promocodesList(
      code?: string,
      codeIexact?: string,
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<PaginatedPromoCodeList> {
      return localVarFp
        .promocodesList(code, codeIexact, limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this promo code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promocodesRetrieve(id: number, options?: any): AxiosPromise<PromoCode> {
      return localVarFp
        .promocodesRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for promocodesList operation in V2PromocodeApi.
 * @export
 * @interface V2PromocodeApiPromocodesListRequest
 */
export interface V2PromocodeApiPromocodesListRequest {
  /**
   *
   * @type {string}
   * @memberof V2PromocodeApiPromocodesList
   */
  readonly code?: string;

  /**
   *
   * @type {string}
   * @memberof V2PromocodeApiPromocodesList
   */
  readonly codeIexact?: string;

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof V2PromocodeApiPromocodesList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof V2PromocodeApiPromocodesList
   */
  readonly offset?: number;
}

/**
 * Request parameters for promocodesRetrieve operation in V2PromocodeApi.
 * @export
 * @interface V2PromocodeApiPromocodesRetrieveRequest
 */
export interface V2PromocodeApiPromocodesRetrieveRequest {
  /**
   * A unique integer value identifying this promo code.
   * @type {number}
   * @memberof V2PromocodeApiPromocodesRetrieve
   */
  readonly id: number;
}

/**
 * V2PromocodeApi - object-oriented interface
 * @export
 * @class V2PromocodeApi
 * @extends {BaseAPI}
 */
export class V2PromocodeApi extends BaseAPI {
  /**
   *
   * @param {V2PromocodeApiPromocodesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2PromocodeApi
   */
  public promocodesList(
    requestParameters: V2PromocodeApiPromocodesListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return V2PromocodeApiFp(this.configuration)
      .promocodesList(
        requestParameters.code,
        requestParameters.codeIexact,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2PromocodeApiPromocodesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2PromocodeApi
   */
  public promocodesRetrieve(
    requestParameters: V2PromocodeApiPromocodesRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2PromocodeApiFp(this.configuration)
      .promocodesRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
