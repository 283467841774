/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { CancellationSurvey } from '../models';
// @ts-ignore
import { PaginatedCancellationSurveyList } from '../models';
// @ts-ignore
import { PatchedCancellationSurvey } from '../models';
/**
 * V1CancellationsurveyApi - axios parameter creator
 * @export
 */
export const V1CancellationsurveyApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CancellationSurvey} cancellationSurvey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyCreate: async (
      cancellationSurvey: CancellationSurvey,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cancellationSurvey' is not null or undefined
      assertParamExists(
        'cancellationSurveyCreate',
        'cancellationSurvey',
        cancellationSurvey,
      );
      const localVarPath = `/api/v1/cancellation-survey/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancellationSurvey,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyDestroy: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancellationSurveyDestroy', 'id', id);
      const localVarPath = `/api/v1/cancellation-survey/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyList: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/cancellation-survey/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {PatchedCancellationSurvey} [patchedCancellationSurvey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyPartialUpdate: async (
      id: number,
      patchedCancellationSurvey?: PatchedCancellationSurvey,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancellationSurveyPartialUpdate', 'id', id);
      const localVarPath = `/api/v1/cancellation-survey/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedCancellationSurvey,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancellationSurveyRetrieve', 'id', id);
      const localVarPath = `/api/v1/cancellation-survey/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {CancellationSurvey} cancellationSurvey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyUpdate: async (
      id: number,
      cancellationSurvey: CancellationSurvey,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancellationSurveyUpdate', 'id', id);
      // verify required parameter 'cancellationSurvey' is not null or undefined
      assertParamExists(
        'cancellationSurveyUpdate',
        'cancellationSurvey',
        cancellationSurvey,
      );
      const localVarPath = `/api/v1/cancellation-survey/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancellationSurvey,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V1CancellationsurveyApi - functional programming interface
 * @export
 */
export const V1CancellationsurveyApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    V1CancellationsurveyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CancellationSurvey} cancellationSurvey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancellationSurveyCreate(
      cancellationSurvey: CancellationSurvey,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CancellationSurvey>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancellationSurveyCreate(
          cancellationSurvey,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancellationSurveyDestroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancellationSurveyDestroy(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancellationSurveyList(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedCancellationSurveyList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancellationSurveyList(
          limit,
          offset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {PatchedCancellationSurvey} [patchedCancellationSurvey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancellationSurveyPartialUpdate(
      id: number,
      patchedCancellationSurvey?: PatchedCancellationSurvey,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CancellationSurvey>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancellationSurveyPartialUpdate(
          id,
          patchedCancellationSurvey,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancellationSurveyRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CancellationSurvey>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancellationSurveyRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {CancellationSurvey} cancellationSurvey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancellationSurveyUpdate(
      id: number,
      cancellationSurvey: CancellationSurvey,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CancellationSurvey>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancellationSurveyUpdate(
          id,
          cancellationSurvey,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V1CancellationsurveyApi - factory interface
 * @export
 */
export const V1CancellationsurveyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V1CancellationsurveyApiFp(configuration);
  return {
    /**
     *
     * @param {CancellationSurvey} cancellationSurvey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyCreate(
      cancellationSurvey: CancellationSurvey,
      options?: any,
    ): AxiosPromise<CancellationSurvey> {
      return localVarFp
        .cancellationSurveyCreate(cancellationSurvey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyDestroy(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .cancellationSurveyDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyList(
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<PaginatedCancellationSurveyList> {
      return localVarFp
        .cancellationSurveyList(limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {PatchedCancellationSurvey} [patchedCancellationSurvey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyPartialUpdate(
      id: number,
      patchedCancellationSurvey?: PatchedCancellationSurvey,
      options?: any,
    ): AxiosPromise<CancellationSurvey> {
      return localVarFp
        .cancellationSurveyPartialUpdate(id, patchedCancellationSurvey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<CancellationSurvey> {
      return localVarFp
        .cancellationSurveyRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this cancellation survey.
     * @param {CancellationSurvey} cancellationSurvey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancellationSurveyUpdate(
      id: number,
      cancellationSurvey: CancellationSurvey,
      options?: any,
    ): AxiosPromise<CancellationSurvey> {
      return localVarFp
        .cancellationSurveyUpdate(id, cancellationSurvey, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancellationSurveyCreate operation in V1CancellationsurveyApi.
 * @export
 * @interface V1CancellationsurveyApiCancellationSurveyCreateRequest
 */
export interface V1CancellationsurveyApiCancellationSurveyCreateRequest {
  /**
   *
   * @type {CancellationSurvey}
   * @memberof V1CancellationsurveyApiCancellationSurveyCreate
   */
  readonly cancellationSurvey: CancellationSurvey;
}

/**
 * Request parameters for cancellationSurveyDestroy operation in V1CancellationsurveyApi.
 * @export
 * @interface V1CancellationsurveyApiCancellationSurveyDestroyRequest
 */
export interface V1CancellationsurveyApiCancellationSurveyDestroyRequest {
  /**
   * A unique integer value identifying this cancellation survey.
   * @type {number}
   * @memberof V1CancellationsurveyApiCancellationSurveyDestroy
   */
  readonly id: number;
}

/**
 * Request parameters for cancellationSurveyList operation in V1CancellationsurveyApi.
 * @export
 * @interface V1CancellationsurveyApiCancellationSurveyListRequest
 */
export interface V1CancellationsurveyApiCancellationSurveyListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof V1CancellationsurveyApiCancellationSurveyList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof V1CancellationsurveyApiCancellationSurveyList
   */
  readonly offset?: number;
}

/**
 * Request parameters for cancellationSurveyPartialUpdate operation in V1CancellationsurveyApi.
 * @export
 * @interface V1CancellationsurveyApiCancellationSurveyPartialUpdateRequest
 */
export interface V1CancellationsurveyApiCancellationSurveyPartialUpdateRequest {
  /**
   * A unique integer value identifying this cancellation survey.
   * @type {number}
   * @memberof V1CancellationsurveyApiCancellationSurveyPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedCancellationSurvey}
   * @memberof V1CancellationsurveyApiCancellationSurveyPartialUpdate
   */
  readonly patchedCancellationSurvey?: PatchedCancellationSurvey;
}

/**
 * Request parameters for cancellationSurveyRetrieve operation in V1CancellationsurveyApi.
 * @export
 * @interface V1CancellationsurveyApiCancellationSurveyRetrieveRequest
 */
export interface V1CancellationsurveyApiCancellationSurveyRetrieveRequest {
  /**
   * A unique integer value identifying this cancellation survey.
   * @type {number}
   * @memberof V1CancellationsurveyApiCancellationSurveyRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for cancellationSurveyUpdate operation in V1CancellationsurveyApi.
 * @export
 * @interface V1CancellationsurveyApiCancellationSurveyUpdateRequest
 */
export interface V1CancellationsurveyApiCancellationSurveyUpdateRequest {
  /**
   * A unique integer value identifying this cancellation survey.
   * @type {number}
   * @memberof V1CancellationsurveyApiCancellationSurveyUpdate
   */
  readonly id: number;

  /**
   *
   * @type {CancellationSurvey}
   * @memberof V1CancellationsurveyApiCancellationSurveyUpdate
   */
  readonly cancellationSurvey: CancellationSurvey;
}

/**
 * V1CancellationsurveyApi - object-oriented interface
 * @export
 * @class V1CancellationsurveyApi
 * @extends {BaseAPI}
 */
export class V1CancellationsurveyApi extends BaseAPI {
  /**
   *
   * @param {V1CancellationsurveyApiCancellationSurveyCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1CancellationsurveyApi
   */
  public cancellationSurveyCreate(
    requestParameters: V1CancellationsurveyApiCancellationSurveyCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1CancellationsurveyApiFp(this.configuration)
      .cancellationSurveyCreate(requestParameters.cancellationSurvey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V1CancellationsurveyApiCancellationSurveyDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1CancellationsurveyApi
   */
  public cancellationSurveyDestroy(
    requestParameters: V1CancellationsurveyApiCancellationSurveyDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1CancellationsurveyApiFp(this.configuration)
      .cancellationSurveyDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V1CancellationsurveyApiCancellationSurveyListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1CancellationsurveyApi
   */
  public cancellationSurveyList(
    requestParameters: V1CancellationsurveyApiCancellationSurveyListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return V1CancellationsurveyApiFp(this.configuration)
      .cancellationSurveyList(
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V1CancellationsurveyApiCancellationSurveyPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1CancellationsurveyApi
   */
  public cancellationSurveyPartialUpdate(
    requestParameters: V1CancellationsurveyApiCancellationSurveyPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1CancellationsurveyApiFp(this.configuration)
      .cancellationSurveyPartialUpdate(
        requestParameters.id,
        requestParameters.patchedCancellationSurvey,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V1CancellationsurveyApiCancellationSurveyRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1CancellationsurveyApi
   */
  public cancellationSurveyRetrieve(
    requestParameters: V1CancellationsurveyApiCancellationSurveyRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1CancellationsurveyApiFp(this.configuration)
      .cancellationSurveyRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V1CancellationsurveyApiCancellationSurveyUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1CancellationsurveyApi
   */
  public cancellationSurveyUpdate(
    requestParameters: V1CancellationsurveyApiCancellationSurveyUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1CancellationsurveyApiFp(this.configuration)
      .cancellationSurveyUpdate(
        requestParameters.id,
        requestParameters.cancellationSurvey,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
