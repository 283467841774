/**
 * Helpers for debouncing react state changes
 */

import { useEffect, EffectCallback, DependencyList } from 'react';

export function useDebouncedEffect(
  effect: EffectCallback,
  deps: DependencyList,
  delayMs: number,
) {
  return useEffect(() => {
    const handler = setTimeout(() => {
      return effect();
    }, delayMs);
    return () => {
      clearTimeout(handler);
    };
  }, [...deps, delayMs]);
}
