import styled from '@emotion/styled';

import { Text } from '@/components/base/text';
import { Box } from '@/components/base/box';
import { NutrittionItem } from '@/components/dinner-recipes/NutritionIttem';
import { Dish } from '@/hooks/use-household-dinner';
import {
  formatNumber1Decimal,
  formatNumber2Decimal,
  formatNumberWithoutDecimal,
} from '@/utils/format-number';
import { useRecipe } from '@/hooks/use-recipe';
import { useEffect, useState } from 'react';

const StyledDetails = styled(Box)`
  color: ${({ theme }) => theme.colors.textGray20};
  .subheading {
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

StyledDetails.defaultProps = {
  paddingX: 'xs',
  paddingY: 'l',
};

const StyledIngredients = styled(StyledDetails)`
  .ingredients {
    font-size: ${({ theme }) => theme.fontSizes['2xs']};
    ul {
      list-style: none;
      padding: 0;
      li {
        display: block;
        margin-top: ${({ theme }) => theme.space.xs}px;

        input {
          margin-right: ${({ theme }) => theme.space.xs}px;
        }
      }
      ${({ theme }) => theme.mediaQueries.print} {
        columns: 2;
      }
    }
    .ing_quantity {
      color: ${({ theme }) => theme.colors.bodyText};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      margin-right: ${({ theme }) => theme.space['2xs']}px;
    }

    .ing_measure {
      color: ${({ theme }) => theme.colors.bodyText};
      font-style: italic;
      font-weight: ${({ theme }) => theme.fontWeights.lighter};
      margin-right: ${({ theme }) => theme.space['2xs']}px;
    }

    .ing_name {
      color: ${({ theme }) => theme.colors.bodyText};
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }
`;

const StyledPreparation = styled(StyledDetails)`
  .preparation {
    font-size: ${({ theme }) => theme.fontSizes['2xs']};
    ol {
      counter-reset: li;
      padding: 0;
      margin-top: ${({ theme }) => theme.space.xs}px;
      li {
        cursor: pointer;
        list-style: none;
        position: relative;
        margin-bottom: ${({ theme }) => theme.space.m}px;
        margin-left: ${({ theme }) => theme.space['2xl']}px;
        padding: ${({ theme }) => theme.space['2xs']}px
          ${({ theme }) => theme.space.xs}px;
        border-top: 2px solid
          ${({ theme }) => theme.colors.dinnerRecipeTitleBackgroundColor};
        background: ${({ theme }) => theme.colors.dinnerRecipePrepBackground};
        border-radius: 0px 0px ${({ theme }) => theme.radii['2xs']};
        :before {
          content: counter(li);
          counter-increment: li;
          position: absolute;
          top: -2px;
          left: -2em;
          box-sizing: border-box;
          width: 2em;
          margin-right: ${({ theme }) => theme.space.xs}px;
          padding: ${({ theme }) => theme.space['2xs']}px;
          border-top: 2px solid
            ${({ theme }) => theme.colors.dinnerRecipeTitleBackgroundColor};
          color: ${({ theme }) => theme.colors.white};
          background: ${({ theme }) =>
            theme.colors.dinnerRecipeTitleBackgroundColor};
          font-weight: ${({ theme }) => theme.fontWeights.bold};
          text-align: center;
          padding-top: ${({ theme }) => theme.space['3xs']}px;
          padding-bottom: ${({ theme }) => theme.space['2xs']}px;
          border-radius: ${({ theme }) => theme.radii['2xs']} 0 0
            ${({ theme }) => theme.radii['2xs']};
        }
      }
    }
  }
`;

const StyledTips = StyledPreparation;

const StyledNutritionFacts = styled(StyledDetails)`
  ${({ theme }) => theme.mediaQueries.print} {
    display: none;
  }
  .subheading {
    font-size: ${({ theme }) => theme.fontSizes['m']};
    margin-bottom: ${({ theme }) => theme.space['2xs']}px;
    text-transform: capitalize;
  }
  * {
    font-size: ${({ theme }) => theme.fontSizes['5xs']};
  }
  .nutrition-body {
    margin-right: ${({ theme }) => theme.space['2xs']}px;
    margin-top: ${({ theme }) => theme.space['2xs']}px;
    border: 0 solid ${({ theme }) => theme.colors.lightBorder};
    border-width: ${({ theme }) => theme.space['2xs']}px 0;
    .amount-per-serving {
      font-size: ${({ theme }) => theme.fontSizes['6xs']};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
  & > p,
  & > .nutrition-body {
    margin-left: ${({ theme }) => theme.space['xs']}px;

    .nutrittion-item {
      border-top: solid ${({ theme }) => theme.space['4xs']}px
        ${({ theme }) => theme.colors.lightBorder};
    }
  }
  .nutrittion-item {
    .values {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      margin-left: ${({ theme }) => theme.space['3xs']}px;
    }
  }
`;

StyledNutritionFacts.defaultProps = {
  marginBottom: 'l',
  marginTop: 'none',
  marginX: 'xs',
};

type DetailsProps = {
  dish: Dish;
};

export const Details = ({
  dish: {
    servings,
    pricePerServing = 0.0,
    recipe: {
      id = 0,
      calories = 0.0,
      protein = 0.0,
      dietaryFiber = 0.0,
      carbohydrates = 0.0,
      totalSugars = 0.0,
      fat = 0.0,
      saturatedFat = 0.0,
      transFattyAcid = 0.0,
      cholesterol = 0.0,
      sodium = 0.0,
      smartPoints = 0,
      instructionsText,
      netCarbohydrates,
      tipsText,
    },
  },
}: DetailsProps) => {
  const { recipe: extendedRecipe } = useRecipe({ recipeId: id, servings });
  const [ingredientsText, setIngredientsText] = useState('');

  useEffect(() => {
    if (extendedRecipe?.ingredientsText) {
      const element = document.createElement('div');
      element.innerHTML = extendedRecipe.ingredientsText;

      element.querySelectorAll('li').forEach((li) => {
        const label = document.createElement('label');

        label.innerHTML = li.innerHTML;

        const input = document.createElement('input');
        input.type = 'checkbox';
        label.prepend(input);

        li.replaceChildren(label);
      });

      setIngredientsText(element.innerHTML);
    }
  }, [extendedRecipe]);

  return (
    <>
      <StyledIngredients>
        <Text as="h4" variant={'xs'} className="subheading">
          Ingredients
        </Text>
        <Box
          dangerouslySetInnerHTML={{ __html: ingredientsText }}
          className="ingredients"
        />
      </StyledIngredients>
      <StyledPreparation>
        <Text as="h4" variant={'xs'} className="subheading">
          Preparation
        </Text>
        <Box
          dangerouslySetInnerHTML={{ __html: instructionsText }}
          className="preparation"
        />
      </StyledPreparation>
      {true && (
        <div>
          <Text as="h4" className="subheading">
            Tips
          </Text>
          <Box>Hi</Box>
        </div>
      )}

      <StyledNutritionFacts>
        <Text as="h4" variant={'xs'} className="subheading">
          Nutrition Facts
        </Text>

        <NutrittionItem label="Servings Per Recipe" value={servings} />
        <NutrittionItem
          label="Price Per Serving"
          value={`$${formatNumber2Decimal(pricePerServing, false)}`}
        />
        <Box className="nutrition-body">
          <Text className="amount-per-serving">Amount Per Serving</Text>

          <NutrittionItem
            label="Calories"
            value={formatNumberWithoutDecimal(calories)}
          />

          <NutrittionItem
            label="Protein"
            value={`${formatNumber1Decimal(protein, false)} g`}
          />

          <NutrittionItem
            label="Total Carbohydrates"
            value={`${formatNumber1Decimal(carbohydrates, false)}g`}
          />

          <Box paddingLeft={'xs'}>
            <NutrittionItem
              label="Dietary Fiber"
              value={`${formatNumber1Decimal(dietaryFiber, false)}g`}
            />

            <NutrittionItem
              label="Net Carbohydrates"
              value={`${formatNumber1Decimal(netCarbohydrates, false)}g`}
            />
            <NutrittionItem
              label="Sugar"
              value={`${formatNumber1Decimal(totalSugars, false)}g`}
            />
          </Box>

          <NutrittionItem
            label="Total Fat"
            value={`${formatNumber1Decimal(fat, false)}g`}
          />

          <Box paddingLeft={'xs'}>
            <NutrittionItem
              label="Saturated Fat"
              value={`${formatNumber1Decimal(saturatedFat, false)}g`}
            />
            <NutrittionItem
              label="Trans Fat"
              value={`${formatNumber1Decimal(transFattyAcid, false)}g`}
            />
          </Box>

          <NutrittionItem
            label="Cholesterol"
            value={`${formatNumber1Decimal(cholesterol, false)}mg`}
          />
          <NutrittionItem
            label="Sodium"
            value={`${formatNumberWithoutDecimal(sodium)}mg`}
          />
          <NutrittionItem
            label="SmartPoints&reg;"
            value={formatNumberWithoutDecimal(smartPoints)}
          />
        </Box>
      </StyledNutritionFacts>
    </>
  );
};
