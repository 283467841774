export const ProfileIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="5" r="2.5" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99513 7.23198C5.8906 7.13802 5.74789 7.09143 5.61359 7.13291C4.38945 7.51096 3.5 8.65151 3.5 9.9998V11.4998C3.5 12.6044 4.39543 13.4998 5.5 13.4998H10.5C11.6046 13.4998 12.5 12.6044 12.5 11.4998V9.9998C12.5 8.65151 11.6106 7.51096 10.3864 7.13291C10.2521 7.09143 10.1094 7.13802 10.0049 7.23198C9.47353 7.70962 8.77071 8.00024 8 8.00024C7.22929 8.00024 6.52647 7.70962 5.99513 7.23198Z"
        fill="currentColor"
      />
    </svg>
  );
};
