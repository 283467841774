import React from 'react';
import { TextInput, TextInputProps } from '../text-input';

export const NumberInput: React.FC<TextInputProps> = ({ name, ...props }) => (
  <TextInput
    {...{
      ...props,
      name,
      type: 'number',
    }}
  />
);
