import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { Box } from '@/components/base/box';
import { Flex } from '@/components/base/flex';
import { Text } from '@/components/base/text';
import { TextLink } from '@/components/base/text-link';

const StyledHeader = styled(Flex)`
  min-height: 20px;
  padding: ${({ theme }) => theme.space.xs}px;
  background: ${({ theme }) => theme.colors.primaryLight200};
`;

StyledHeader.defaultProps = {
  alignItems: 'center',
  justifyContent: 'space-between',
};

const StyledLeftArrowLink = styled(TextLink)`
  color: ${({ theme }) => theme.colors.white};
  width: 1.5em;
  height: 1.5em;
  border-radius: ${({ theme }) => theme.radii['50%']};
  padding: ${({ theme }) => theme.space['3xs']}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

const StyledTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

type SearchRecipeHeaderProps = {
  onClose: () => void;
};

export const SearchRecipeHeader = ({ onClose }: SearchRecipeHeaderProps) => {
  return (
    <StyledHeader>
      <StyledLeftArrowLink
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} width={18} height={18} size="lg" />
      </StyledLeftArrowLink>
      <StyledTitle>Recipe Box</StyledTitle>
      <Box />
    </StyledHeader>
  );
};
