import styled from '@emotion/styled';

export type FadeProps = {
  visible: boolean;
  delay?: number;
};
export const Fade = styled.div<FadeProps>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: visibility ${(props) => props.delay ?? 100}ms linear,
    opacity ${(props) => props.delay ?? 100}ms linear;
`;
