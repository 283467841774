export const LogoutOutIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.5H10V5.5H11V3.5C11 2.94772 10.5523 2.5 10 2.5H4C3.44772 2.5 3 2.94771 3 3.5V12.5C3 13.0523 3.44772 13.5 4 13.5H10C10.5523 13.5 11 13.0523 11 12.5V10.5H10V12.5H4V3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 10L14 8L11.75 6V7H6V9H11.75V10Z"
        fill="currentColor"
      />
    </svg>
  );
};
