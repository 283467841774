import { patch } from '@/services/httpClient';

export type UpdateRateRecipeRequest = {
  recipeId: number;
  rating: number;
};
const updateRateRecipe = (request: UpdateRateRecipeRequest): Promise<any> => {
  return patch(`api/v1/household_recipe_rating/${request.recipeId}`, {
    rating: request.rating,
  });
};

export default updateRateRecipe;
