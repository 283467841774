import React, { ReactElement, ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Tooltip from 'rc-tooltip';
import { faCircleInfo, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text, TextProps } from '@/components/base/text';
import { Overlay } from '@/components/sign-up/referral-code-modal/Overlay';
import 'rc-tooltip/assets/bootstrap_white.css';

export interface InfoTooltipProps extends TextProps {
  title: string;
  description: string | ReactNode;
  placement?: string;
  showQuestionIcon?: boolean;
  icon?: ReactElement;
}

const InfoIcon = styled(FontAwesomeIcon)<{ showicon: boolean | undefined }>`
  cursor: pointer;
  background-color: ${({ theme, showicon }) =>
    showicon
      ? theme.colors.primaryLight200
      : theme.colors.quickstartTabShadowColor};
  border-radius: ${({ showicon }) => (showicon ? 0 : '50%')};
  ${({ theme, showicon }) =>
    showicon &&
    `
    padding: ${theme.space['2xs']}px ${theme.space.xs}px;
  `}
  display: none;
  ${({ theme }) => theme.mediaQueries.m} {
    display: block;
  }
`;

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  title,
  description,
  placement = 'left',
  showQuestionIcon = false,
  icon,
}) => {
  const theme = useTheme();
  const resultIcon = icon ?? (
    <InfoIcon
      showicon={showQuestionIcon ? showQuestionIcon : undefined}
      icon={showQuestionIcon ? faQuestion : faCircleInfo}
      color={theme.colors.householdQuestionaryBackgroundColor}
      size={showQuestionIcon ? '2xs' : 'lg'}
    />
  );

  return (
    <Tooltip
      placement={placement}
      trigger={['hover']}
      overlayStyle={{
        opacity: 1,
        padding: '0',
        backgroundColor: 'transparent',
      }}
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      overlayInnerStyle={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: '0',
        borderRadius: theme.radii['xs'],
        overflow: 'hidden',
      }}
      overlay={
        <Overlay title={title}>
          <Text variant="2xs" color="textGray20">
            {description}
          </Text>
        </Overlay>
      }
    >
      {resultIcon}
    </Tooltip>
  );
};
