import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@/components/base/modal';
import { Flex } from '@/components/base/flex';
import { Box } from '@/components/base/box';
import { Text } from '@/components/base/text';
import { useHousehold } from '@/hooks/use-household';
import { formatDate } from '@/utils/format-date';

import {
  HouseholdDinner,
  useHouseholdDinner,
} from '@/hooks/use-household-dinner';
import { useMealPlanRangeStore } from '@/store/meal-plan-range-store';
import { Rating } from '@/components/base/rating';

import { TextLink } from '@/components/base/text-link';
import { Image } from '@/components/base/image';
import {
  HouseholdRecipeRatingV1,
  RecipeRatingDetails,
  recipeRatingQueryKey,
  RecipeRatingResult,
  useRecipeRatingV2,
  useUserRecipeRatings,
} from '@/hooks/use-user-recipe-ratings';
import updateRateRecipe from '@/services/update-rate-recipe';
import rateRecipe from '@/services/rate-recipe';
import useCommandRequest from '@/hooks/use-command-request';
import { useScoreCardEvent } from '@/hooks/use-score-card-event';
import removeRateRecipe from '@/services/remove-rate-recipe';
import { InfoTooltip } from '@/components/profile/info-tooltip';
import { Stars } from '@/components/base/stars';
import { useTheme } from '@emotion/react';
import { useQueryClient } from 'react-query';

const StyledModalContent = styled(ModalContent)`
  width: 100%;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.l} {
    width: 998px;
  }
`;

const StyledModalTitle = styled(ModalTitle)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  line-height: ${({ theme }) => theme.lineHeights.m};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 10px 0;
`;

const StyledModalBody = styled(Box)`
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
`;

const StyledDinnersWrap = styled(Box)``;
const StyledDinner = styled(Box)``;
const StyledDinnerTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes['s']};
  line-height: ${({ theme }) => theme.lineHeights['9xs']};
  color: ${({ theme }) => theme.colors.textGray55};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin: 10px 0;
`;

const StyledRecipesListItem = styled(Flex)`
  margin: ${({ theme }) => theme.space.l}px 0;
`;

const DISH_WIDTH = 88;
const DISH_HEIGHT = 88;

const StyledDishWrap = styled(TextLink)`
  display: block;
  position: relative;
  cursor: pointer;
`;

const StyledDishImage = styled(Image)`
  width: ${DISH_WIDTH}px;
  height: ${DISH_HEIGHT}px;
  border-radius: ${({ theme }) => theme.radii['2xs']};
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.m} {
    width: ${DISH_WIDTH}px;
    height: ${DISH_HEIGHT}px;
  }
`;

const StyledRecipeTitle = styled(TextLink)`
  color: ${({ theme }) => theme.colors.textGray20};
  font-size: ${({ theme }) => theme.fontSizes.s};

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: ${({ theme }) => theme.space['xs']}px;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mediaQueries.m} {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

const StyledTooltipWrap = styled(Box)``;

const StyledTooltipText = styled(Text)`
  color: ${({ theme }) => theme.colors.rateRecipesToolTipText};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const StyledTooltipStarText = styled(Text)`
  color: ${({ theme }) => theme.colors.rateRecipesToolTipText};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
`;

const StyledTooltipStarHelpText = styled(Text)`
  color: ${({ theme }) => theme.colors.rateRecipesToolTipText};
  font-size: ${({ theme }) => theme.fontSizes['2xs']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

type RateRecipesModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

type StyledFilterOptionTextProps = {
  isSelected?: boolean;
};
const StyledFilterOptionText = styled(Text)<StyledFilterOptionTextProps>`
  cursor: pointer;
  font-weight: ${({ isSelected, theme }) =>
    isSelected ? theme.fontWeights.extraBold : theme.fontWeights.regular};
`;

const findUnrated = (
  allDinners: HouseholdDinner[],
  ratingsByRecipe: Map<number, RecipeRatingResult>,
) => {
  const result: HouseholdDinner[] = allDinners.flatMap((dinner) => {
    const filteredDishes = dinner.dishes.filter((dish) => {
      const rating =
        ratingsByRecipe.get(dish.recipe.id)?.status ?? 'does-not-exist';
      return rating === 'does-not-exist';
    });
    if (filteredDishes.length > 0) {
      return [{ ...dinner, dishes: filteredDishes }];
    } else {
      return [];
    }
  });
  return result;
};
export const RateRecipesModal = ({
  isOpen,
  onClose = () => {},
}: RateRecipesModalProps) => {
  const theme = useTheme();

  const { household } = useHousehold();

  const { startDate, endDate } = useMealPlanRangeStore();
  const {
    dinners = [],
    refetch: refetchDinner,
    status: queryDinnerStatus,
  } = useHouseholdDinner(startDate, endDate);

  const { recipeIds, allItems } = useMemo(() => {
    const recipeIds = dinners.flatMap((dinner) =>
      dinner.dishes.map((dish) => dish.recipe.id),
    );
    const allItems = dinners.sort((dinner1, dinner2) =>
      dinner1.date > dinner2.date ? 1 : -1,
    );

    return { recipeIds, allItems };
  }, [queryDinnerStatus]);
  const queryClient = useQueryClient();

  const { query: recipeRatingQuery, data: recipeRatingData } =
    useRecipeRatingV2(
      { recipeIds: recipeIds },
      {
        enabled: queryDinnerStatus === 'success',
        select(data) {
          return {
            ...data,
            byRecipeId: new Map(
              Object.keys(data.byRecipeId).map((key) => {
                const keyInt = parseInt(key);
                return [keyInt, data.byRecipeId[keyInt]];
              }),
            ),
          };
        },
      },
    );

  const byRecipeId = recipeRatingQuery.data?.byRecipeId ?? new Map();

  const { recipesRatings, refetch: refetchRecipeRatings } =
    useUserRecipeRatings();
  const [showAll, setShowAll] = useState(true);
  const filteredDinners = useMemo(() => {
    if (showAll) {
      return dinners;
    } else {
      return findUnrated(dinners, byRecipeId);
    }
  }, [showAll, queryDinnerStatus, recipeRatingQuery.status]);

  const { sendScoreCardEvent } = useScoreCardEvent();
  const { send: sendRateRecipeCommand } = useCommandRequest();
  const { send: sendDeleteRateRecipeCommand } = useCommandRequest();

  const onClearRating = async (recipeRatingId: number) => {
    const result = await sendDeleteRateRecipeCommand(() =>
      removeRateRecipe(recipeRatingId),
    );

    if (result.completed) {
      const promises: Promise<any>[] = [];
      promises.push(
        queryClient.invalidateQueries(recipeRatingQueryKey(recipeIds)),
      );
      if (refetchRecipeRatings) {
        promises.push(refetchRecipeRatings());
      }
      if (refetchDinner) {
        promises.push(refetchDinner());
      }
      await Promise.all(promises);
    }
  };

  const onRateRecipe = async (
    newValue: number,
    recipeId: number,
    recipeRatingId?: number,
  ) => {
    if (!household) return;

    const addNewRating = !recipeRatingId;
    const result = await sendRateRecipeCommand(() =>
      recipeRatingId
        ? updateRateRecipe({
            recipeId: recipeRatingId,
            rating: newValue,
          })
        : rateRecipe({
            householdId: household.id,
            recipeId: recipeId,
            rating: newValue,
          }),
    );

    if (result.completed) {
      if (refetchRecipeRatings) await refetchRecipeRatings();
      if (refetchDinner) await refetchDinner();
      if (addNewRating) await sendScoreCardEvent('RATE_RECIPE');
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={onClose}>
      <ModalOverlay />
      <StyledModalContent>
        <ModalHeader backgroundColor="modalHeader">
          <Box>
            <StyledModalTitle>How was your meal?</StyledModalTitle>
            <ModalCloseButton
              onClose={onClose}
              iconWidth={18}
              iconHeight={18}
              top={'xl'}
              hoverBackgroundColor={'rgba(0, 0, 0, 0.25)'}
            />
          </Box>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Flex>
              <StyledFilterOptionText
                isSelected={showAll}
                onClick={() => setShowAll(true)}
              >
                Show All
              </StyledFilterOptionText>
              <Text marginLeft={'3xs'} marginRight={'3xs'}>
                |
              </Text>
              <StyledFilterOptionText
                isSelected={!showAll}
                onClick={() => setShowAll(false)}
              >
                Show unrated
              </StyledFilterOptionText>
            </Flex>

            <Flex alignItems={'center'}>
              <Text marginRight={'2xs'}>Why Rate?</Text>

              <InfoTooltip
                placement={'bottom'}
                icon={<FontAwesomeIcon icon={faQuestionCircle} size="sm" />}
                title="Why rate?"
                description={
                  <StyledTooltipWrap>
                    <StyledTooltipText marginBottom={'s'}>
                      Your ratings help DinnerTime get smarter and smarter about
                      suggesting dishes that you’re likely to enjoy. Here’s what
                      the ratings mean:
                    </StyledTooltipText>

                    <Box>
                      <Box marginBottom={'2xs'}>
                        <Flex alignItems={'center'}>
                          <Stars
                            totalStars={1}
                            showEmptyStars={true}
                            color={theme.colors.starDark}
                          />
                          <StyledTooltipStarText marginLeft={'s'}>
                            No Thanks!
                          </StyledTooltipStarText>
                        </Flex>
                        <StyledTooltipStarHelpText>
                          (Dish will not be recommended to you again)
                        </StyledTooltipStarHelpText>
                      </Box>

                      <Box marginBottom={'2xs'}>
                        <Flex alignItems={'center'}>
                          <Stars
                            totalStars={2}
                            showEmptyStars={true}
                            color={theme.colors.starDark}
                          />
                          <StyledTooltipStarText marginLeft={'s'}>
                            Not a big fan.
                          </StyledTooltipStarText>
                        </Flex>
                        <StyledTooltipStarHelpText>
                          (Dish will not likely recommended to you again)
                        </StyledTooltipStarHelpText>
                      </Box>

                      <Flex marginBottom={'2xs'}>
                        <Stars
                          totalStars={3}
                          showEmptyStars={true}
                          color={theme.colors.starDark}
                        />
                        <StyledTooltipStarText marginLeft={'s'}>
                          It&apos;s fine.
                        </StyledTooltipStarText>
                      </Flex>

                      <Flex marginBottom={'2xs'}>
                        <Stars
                          totalStars={4}
                          showEmptyStars={true}
                          color={theme.colors.starDark}
                        />
                        <StyledTooltipStarText marginLeft={'s'}>
                          Like it.
                        </StyledTooltipStarText>
                      </Flex>

                      <Box>
                        <Flex>
                          <Stars
                            totalStars={5}
                            showEmptyStars={true}
                            color={theme.colors.starDark}
                          />
                          <StyledTooltipStarText marginLeft={'s'}>
                            Love it!
                          </StyledTooltipStarText>
                        </Flex>
                        <StyledTooltipStarHelpText>
                          (Dish will not likely recommended to you again)
                        </StyledTooltipStarHelpText>
                      </Box>
                    </Box>
                  </StyledTooltipWrap>
                }
              />
            </Flex>
          </Flex>
        </ModalHeader>

        <StyledModalBody>
          <StyledDinnersWrap>
            {filteredDinners.map((dinner) => (
              <StyledDinner key={dinner.id}>
                <StyledDinnerTitle>
                  Recipes For {formatDate(dinner.date, 'EEEE, LLLL do')}
                </StyledDinnerTitle>

                {dinner.dishes.map((dish) => {
                  const recipeRating = recipesRatings.find(
                    (r) => r.recipeId === dish.recipe.id,
                  );
                  const rating =
                    recipeRating?.rating ?? dish.recipe.communityRating;

                  return (
                    <StyledRecipesListItem key={dish.recipe.id}>
                      <StyledDishWrap
                        href={`/recipes/${dish.recipe.id}`}
                        target={'_blank'}
                      >
                        <StyledDishImage
                          opacity={recipeRating ? 0.4 : 1}
                          alt={dish.recipe.title}
                          src={dish.recipe.thumb}
                        />
                      </StyledDishWrap>

                      <Box width={'100%'} marginLeft={'s'}>
                        <Flex justifyContent={'space-between'}>
                          <Box>
                            <StyledRecipeTitle
                              href={`/recipes/${dish.recipe.id}`}
                              target={'_blank'}
                            >
                              {dish.recipe.title}
                            </StyledRecipeTitle>

                            <Flex opacity={recipeRating ? 0.4 : 1}>
                              <Rating
                                onClearRating={async () => {
                                  if (recipeRating) {
                                    await onClearRating(recipeRating.id);
                                  }
                                }}
                                onChange={async (newValue: number) => {
                                  await onRateRecipe(
                                    newValue,
                                    dish.recipe.id,
                                    recipeRating?.id,
                                  );
                                }}
                                value={rating}
                                readonly={false}
                                highlightFilled={!!recipeRating}
                              />
                              {!recipeRating && (
                                <Text marginLeft={'2xs'}>
                                  ({dish.recipe.ratingCount})
                                </Text>
                              )}
                            </Flex>
                          </Box>
                        </Flex>
                      </Box>
                    </StyledRecipesListItem>
                  );
                })}
              </StyledDinner>
            ))}
          </StyledDinnersWrap>
        </StyledModalBody>
      </StyledModalContent>
    </Modal>
  );
};
