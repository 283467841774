import { isNil } from 'lodash';
const API_DETAIL_URI_RE = /\/api\/(\w+)\/(\w+)\/(\w+)/;
export interface ExtractIdOptions {
  throwIfInvalidUri?: boolean;
  resourceName?: string;
}
export const extractIdFromUri = (
  uri: string,
  { throwIfInvalidUri = false, resourceName }: ExtractIdOptions = {},
): string | null => {
  const onError = (msg: string) => {
    if (throwIfInvalidUri) {
      throw new Error(msg);
    } else {
      return null;
    }
  };
  const matches = uri.match(API_DETAIL_URI_RE);
  if (isNil(matches)) {
    return onError(`uri "${uri}" does not match detail api schema`);
  }

  if (
    resourceName &&
    matches[2].toLowerCase() !== resourceName?.toLowerCase()
  ) {
    return onError(
      `resource '${matches[2]}' does not match desired resource type '${resourceName}'`,
    );
  }

  return matches[3];
};

export function extractIdAsNumberFromUri(
  uri: string,
  options: ExtractIdOptions = {},
): number | null {
  const match = extractIdFromUri(uri, options);
  return match ? parseInt(match, 10) : null;
}
