import { useAuthenticatedAxios } from '@/context/api-auth';
import { useAuthenticatedApi } from '@/hooks/use-authenticated-api';
import { logger as baseLogger } from '@/services/logging';
import { PaginatedListV1 } from '@/types/api-models';
import { extractIdAsNumberFromUri } from '@/utils/uri-utils';
import { AxiosInstance, AxiosResponse } from 'axios';
import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { cloneDeep } from 'lodash';
import { query } from 'express';
import { fetchAllPagesV1 } from './use-all-objects-data';
const logger = baseLogger.child({ module: 'use-user-recipe-ratings' });
export type RecipeRatingDetails = {
  firstRated: Date;
  householdUri: string;
  householdId: number;
  id: number;
  lastRated: Date;
  notes?: string;
  rating: number;
  recipeUri: string;
  recipeId: number;
  resourceUri: string;
};

/**
 * Generic helper converting the types of given keys `TKeys` to optional
 */
type OptionalFields<T, TKeys extends keyof T> = Omit<T, TKeys> &
  Partial<Pick<T, TKeys>>;

type TransformedRecipeRating = OptionalFields<
  RecipeRatingDetails,
  'recipeId' | 'householdId'
>;
export const transformRecipeRatingResponse = (
  response: any,
): TransformedRecipeRating => {
  const recipeId = extractIdAsNumberFromUri(response.recipe) ?? undefined;
  const householdId = extractIdAsNumberFromUri(response.household) ?? undefined;

  return {
    id: response.id,
    firstRated: new Date(response.first_rated),
    rating: response.rating,
    recipeUri: response.recipe,
    recipeId,
    resourceUri: response.resource_uri,
    householdUri: response.household,
    householdId,
    notes: response.notes,
    lastRated: new Date(response.last_rated),
  };
};

export const useUserRecipeRatings = () => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useAuthenticatedApi({
    endpoint: `household_recipe_rating`,
  });

  const recipesRatings = (data as any[]).map(transformRecipeRatingResponse);

  return { recipesRatings, refetch, isLoading, isError };
};

export interface HouseholdRecipeRatingV1 {
  id: number;
  recipe: string;
  rating: number;
  resource_uri: string;
  last_rated?: string;
  first_rated?: string;
}

type UpdateRecipeRating = Pick<HouseholdRecipeRatingV1, 'recipe' | 'rating'> &
  Partial<Omit<HouseholdRecipeRatingV1, 'recipe' | 'rating'>>;

export type RecipeRatingResult =
  | { status: 'saved'; rating: HouseholdRecipeRatingV1 }
  | { status: 'does-not-exist' };

export interface UseRecipeRatingV2Props {
  recipeIds: number[];
}

export interface MutateRecipeRatingProps {
  recipeId: number;
  rating: number;
  currentRating: RecipeRatingResult;
}

export interface ListRecipeRatingResults {
  objects: HouseholdRecipeRatingV1[];
  byRecipeId: Record<number, RecipeRatingResult>;
}

export const RECIPE_RATING_QUERY_KEY_ROOT = 'use-recipe-ratings-v2';
export function recipeRatingQueryKey(recipeIds: number[]) {
  return [RECIPE_RATING_QUERY_KEY_ROOT, { recipeIds: recipeIds }] as const;
}

type RecipeRatingQueryKey = ReturnType<typeof recipeRatingQueryKey>;

function fetchRecipeRatingResult(
  axios: AxiosInstance,
): QueryFunction<ListRecipeRatingResults, RecipeRatingQueryKey> {
  return async ({ queryKey }) => {
    const recipeIds = queryKey[1].recipeIds;
    const objects = await fetchAllPagesV1<HouseholdRecipeRatingV1>(
      axios,
      '/api/v1/household_recipe_rating',
      { params: { recipe__in: recipeIds.join(',') } },
    );
    const byRecipeId: Record<number, RecipeRatingResult> = {};
    // since we're primarily fetching recipe ratings by recipe id, not the id of the household_recipe_rating object
    // we want to transform the response to be indexed by recipeId, and provide *explicit* state on whether the
    // household_recipe_rating for the given recipe has been created or not
    const excludedRecipes = new Set(recipeIds); // keep track of recipes that aren't fetched, so we can return them as a status: does-not-exist
    for (const rating of objects) {
      const recipeId = extractIdAsNumberFromUri(rating.recipe);
      if (recipeId !== null) {
        byRecipeId[recipeId] = { status: 'saved', rating };
        excludedRecipes.delete(recipeId);
      }
    }
    for (const recipeId of excludedRecipes) {
      byRecipeId[recipeId] = { status: 'does-not-exist' };
    }
    return { objects, byRecipeId };
  };
}

type RecipeRatingQueryData = PaginatedListV1<HouseholdRecipeRatingV1>;
type SelectedRecipeRatingQueryData = RecipeRatingQueryData & {
  lookup: Map<number, HouseholdRecipeRatingV1>;
};

export function useRecipeRatingV2<TData = ListRecipeRatingResults>(
  props: UseRecipeRatingV2Props,
  options: Exclude<
    UseQueryOptions<
      ListRecipeRatingResults,
      unknown,
      TData,
      RecipeRatingQueryKey
    >,
    'queryFn' | 'queryKey'
  > = {},
) {
  const { axios, isAuthenticated } = useAuthenticatedAxios();
  const queryClient = useQueryClient();
  const householdRatingsQuery = useQuery(
    recipeRatingQueryKey(props.recipeIds),
    fetchRecipeRatingResult(axios),
    {
      ...options,
    },
  );

  return {
    data: householdRatingsQuery.data,
    query: householdRatingsQuery,
  };
}
