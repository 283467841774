import React from 'react';
import styled from '@emotion/styled';
import { format } from 'fecha';

import { Flex } from '@/components/base/flex';
import { Text } from '@/components/base/text';

function getCurrentMonth() {
  const now = new Date();
  return format(now, 'MMM');
}

interface PointsIndicatorProps {
  score: number;
  onClick?: () => void;
}

const Points = styled(Flex)`
  background-color: #18a744;
  height: fit-content;
  position: relative;
  padding: 0 5px;
  &:before {
    border-top: 9px solid transparent;
    border-left: 5px solid #fff;
    border-bottom: 9px solid transparent;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
  }
`;

const Month = styled(Text)`
  background-color: #18a744;
  height: fit-content;
  opacity: 0.8;
  position: relative;
  &:after {
    border-top: 9px solid transparent;
    border-right: 5px solid #fff;
    border-bottom: 9px solid transparent;
    content: '';
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
  }
`;

export const PointsIndicator: React.FC<PointsIndicatorProps> = ({
  score = 0,
  onClick,
}) => {
  return (
    <Flex
      cursor="pointer"
      alignItems="center"
      paddingLeft={['m', 'm', 'm', 'none']}
      flex={['1', '1', '1', 'none']}
      onClick={onClick}
    >
      <Points cursor="pointer">
        <Text variant="6xsBold" color="secondary" marginX="2xs">
          {score}
        </Text>
        <Text variant="6xs" color="secondary">
          pts
        </Text>
      </Points>
      <Month variant="6xs" color="secondary" paddingX="2xs" paddingRight="s">
        {getCurrentMonth()}
      </Month>
    </Flex>
  );
};
