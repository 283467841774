import { dateFromStr } from '@/utils/format-date';
import { useAuthenticatedApi } from './use-authenticated-api';

export type HouseholdStore = {
  id: number;
  name: string;
  uri: string;
  displayOrder: number;
  saleEndDate?: Date;
};

const transformHouseholdStore = (response: any): HouseholdStore => {
  return {
    id: response.id,
    name: response.name,
    uri: response.resource_uri,
    displayOrder: response.disp_order,
    saleEndDate: response.sale_end_date && dateFromStr(response.sale_end_date),
  };
};

export const useHouseholdStores = () => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useAuthenticatedApi({
    endpoint: `household_store`,
  });

  const householdStores = data.map(transformHouseholdStore) as HouseholdStore[];

  return { householdStores, isLoading, isError, refetch };
};
