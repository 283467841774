import { patch } from '@/services/httpClient';
import { useEffect } from 'react';
import { useAuthenticatedApi } from './use-authenticated-api';
import { AxiosInstance } from 'axios';
import { PaginatedListV1 } from '@/types/api-models';
import { ApiError, isAxiosError } from '@/utils/errors';

export type HouseholdProfile = {
  id: number;
  name: string;
  household: string;
  resourceURI: string;
  isDefault: boolean;
  servings: string;
  prepTime: string;
  totalTime: string;
  budget: string;
  weightMgmt: string;
  specialtyDiet: string;
  convenienceAffinity: string;
  skillLevel: string;
  missingCookingEquipment: Array<string>;
  cookingEquipment: Array<string>;
  timeOptions: Array<string>;
  religiousCulturalRestrictions: Array<string>;
  allergies: Array<string>;
  baseIngredientAllergies: Array<string>;
  medicalRestrictions: Array<string>;
  likedBaseIngredients: Array<string>;
  dislikedBaseIngredients: Array<string>;
};

const getArrayOfStrings = (response: Array<any> | undefined) => {
  return response && response.length > 0
    ? response.map((a: any) => `${a.id}`)
    : [''];
};

const transformHouseholdProfileResponse = (response: any): HouseholdProfile => {
  return {
    id: response.id,
    name: response.name,
    household: response.household,
    resourceURI: response.resource_uri,
    isDefault: response.is_default,
    servings: response.servings,
    prepTime: `${response.prep_time}`,
    totalTime: `${response.total_time}`,
    budget: `${response.budget}`,
    weightMgmt: response.weight_mgmt,
    specialtyDiet: `${response.specialty_diet?.resource_uri}`,
    convenienceAffinity: `${response.convenience_affinity}`,
    skillLevel: `${response.skill_level}`,
    missingCookingEquipment: getArrayOfStrings(
      response.missing_cooking_equipment,
    ),
    cookingEquipment: getArrayOfStrings(response.cooking_equipment),
    timeOptions: getArrayOfStrings(response.time_options),
    religiousCulturalRestrictions: getArrayOfStrings(
      response.religious_cultural_restrictions,
    ),
    allergies: getArrayOfStrings(response.allergies),
    baseIngredientAllergies: getArrayOfStrings(
      response.baseingredient_allergies,
    ),
    medicalRestrictions: getArrayOfStrings(response.medical_restrictions),
    likedBaseIngredients: getArrayOfStrings(response.liked_base_ingredients),
    dislikedBaseIngredients: getArrayOfStrings(
      response.disliked_base_ingredients,
    ),
  };
};

export const setPrebuiltProfiles = async (householdId: number) => {
  const STATUS_BAD_REQUEST = 400;
  try {
    const resp = await patch(`/api/v1/set_built_in_profiles/${householdId}`, {
      built_in_profile_created: true,
    });
    return resp.data;
  } catch (e: unknown) {
    if (isAxiosError(e) && e.response?.status === STATUS_BAD_REQUEST) {
      // Because of weird pre-existing backend behavior,
      // we should accept 400 errors as valid
      return e.response.data;
    } else if (e instanceof ApiError && e.status === STATUS_BAD_REQUEST) {
      return e.data;
    } else {
      throw e;
    }
  }
};

export const useHouseholdProfiles = () => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useAuthenticatedApi({
    endpoint: `household_profile`,
  });

  const profiles = data
    .filter((x: any) => !x.hide_in_lists)
    .sort((x: any, y: any) =>
      x.is_default ? -1 : x.name.localeCompare(y.name),
    )
    .map(transformHouseholdProfileResponse) as HouseholdProfile[];

  const defaultProfile = profiles.find((profile) => profile.isDefault);

  return {
    profiles,
    defaultProfile,
    isLoading,
    isError,
    reloadProfiles: refetch,
  };
};

const QUERY_PROFILE_KEY = 'query-profile-v2';

export function queryProfileListKey(params: {
  limit?: number;
  offset?: number;
}): [typeof QUERY_PROFILE_KEY, typeof params] {
  return [QUERY_PROFILE_KEY, { ...params }];
}

export async function fetchListProfiles(
  axios: AxiosInstance,
  { limit, offset }: { limit?: number; offset?: number },
) {
  const resp = await axios.get<PaginatedListV1<any>>(
    '/api/v1/household_profile',
    {
      params: {
        limit,
        offset,
      },
    },
  );
  return { data: resp.data };
}
