import useCommandRequest from '@/hooks/use-command-request';
import { post } from '@/services/httpClient';
import { useNotificationCenter } from '@/hooks/use-notification-center';
import { fromDateStr } from '@/utils/format-date';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { logger as baseLogger } from '@/services/logging';

const logger = baseLogger.child({ module: '@/hooks/use-score-card-event' });

export type ScoreCardEventType =
  | 'OPEN_MONTHLY_DISH'
  | 'CLICK_MONTHLY_DISH'
  | 'EDIT_PROFILE'
  | 'EDIT_PANTRY'
  | 'ADD_TO_PANTRY_FROM_LIST'
  | 'VIEW_ALL_IN_ONE'
  | 'ADD_SAVED_RECIPE'
  | 'SAVE_RECIPE'
  | 'REQUIRE_INGREDIENT'
  | 'EDIT_MEAL_PLAN'
  | 'ADD_FIRST_PROFILE'
  | 'VIEW_SHOPPING_LIST'
  | 'RATE_RECIPE'
  | 'VIEW_RECIPE'
  | 'SEARCH_RECIPE_BOX'
  | 'VIEW_MEAL_PLANNER'
  | 'EDIT_SHOPPING_LIST'
  | 'OPEN_CUSTOM_REMINDER'
  | 'CLICK_CUSTOM_REMINDER'
  | 'ADD_PROFILE'
  | 'CHANGE_PROFILE'
  | 'ADD_SHOPPING_TRIP';

export type ScoreCardEventResponse = {
  action: string;
  eventTimestamp: Date;
  household: string;
  id: number;
  location: string;
  messages: { order: string; text: string; type: string }[];
  monthlyPoints: number;
  points: number;
  resourceUri: string;
  scorecardItem: string;
  totalPoints: number;
  yearToDatePoints: number;
};

const transform = (response: any): ScoreCardEventResponse => {
  return {
    action: response.action,
    eventTimestamp: fromDateStr(response.event_timestamp),
    household: response.household,
    id: response.id,
    location: response.location,
    messages: response.messages.map((x: any) => {
      return {
        order: x.order,
        text: x.text,
        type: x.type,
      };
    }),
    monthlyPoints: response.monthly_points,
    points: response.points,
    resourceUri: response.resource_uri,
    scorecardItem: response.scorecard_item,
    totalPoints: response.total_points,
    yearToDatePoints: response.year_to_date_points,
  };
};

export const useScoreCardEvent = () => {
  const { send } = useCommandRequest();
  const { showPointsEarned } = useNotificationCenter();
  const queryClient = useQueryClient();

  const { asPath } = useRouter();

  const sendScoreCardEvent = async (eventType: ScoreCardEventType) => {
    const result = await send(() =>
      post<any>('api/v1/household_scorecard_event', {
        action: eventType,
        uri: asPath,
      }),
    );

    if (result.completed) {
      const scoreCardInfo = transform(result.data);
      if (scoreCardInfo.messages.length > 0) {
        logger.debug('invalidating household query');
        showPointsEarned(scoreCardInfo.messages[0].text);

        await queryClient.invalidateQueries({
          queryKey: ['query-api-authenticated', 'household'],
        });
      }
    }
  };

  return { sendScoreCardEvent };
};
