import { ShoppingTrip } from '@/hooks/use-shopping-trips';
import { formatDate } from '@/utils/format-date';
import { Flex } from '@/components/base/flex';
import { Text } from '@/components/base/text';

type ShoppingTripTitleProps = {
  trip: ShoppingTrip;
  variant?: 'small' | 'large';
};

export const ShoppingTripTitle: React.FC<ShoppingTripTitleProps> = ({
  trip,
  variant = 'small',
}) => (
  <Flex alignItems="center" cursor="pointer">
    <Text variant={variant === 'small' ? '4xsBold' : 'sBold'}>
      {formatDate(trip.date, 'E, MMM d')}{' '}
    </Text>
    <Text variant="4xs" ml="xs">
      - {trip.store?.name}
    </Text>
  </Flex>
);
