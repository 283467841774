import { sliceOf } from '@/utils/stores';
import { create, StateCreator, UseBoundStore } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import { immer } from 'zustand/middleware/immer';
import {
  createStore,
  Mutate,
  StoreApi,
  StoreMutatorIdentifier,
} from 'zustand/vanilla';
import { createContext } from 'zustand-di';
import {
  createRecipeBoxSlice,
  initialSearchOptions,
  RecipeBoxSlice,
} from './recipe-box-store';
import { PropsWithChildren, useCallback, useEffect } from 'react';
export type { RecipeBoxSearchOptions } from './recipe-box-store';
interface State {
  moveActionTriggeredBy: number | null;
  refetchDinners: boolean | null;
  newShoppingTripDates: Date[] | null;
}
interface Actions {
  setMoveActionTriggeredBy: (triggeredBy: number | null) => void;
  setRefetchDinners: (refresh: boolean | null) => void;

  setNewShoppingTripDates: (dates: Date[] | null) => void;

  /**
   * cleanup logic for temporary state like openForShoppingDate and search
   * by ingredient
   * @returns
   */
  onCloseRecipeBox: () => void;
}

interface MealPlanCalendarState extends State, Actions {}

export const createMealPlanCalendarSlice = sliceOf<
  MealPlanCalendarState & RecipeBoxSlice,
  [['zustand/immer', never]],
  [],
  MealPlanCalendarState
>((set, get) => {
  return {
    moveActionTriggeredBy: null,
    setMoveActionTriggeredBy: (triggeredBy) =>
      set({ moveActionTriggeredBy: triggeredBy }),
    refetchDinners: false,
    setRefetchDinners: (refresh) => set({ refetchDinners: refresh }),
    recipeBoxSearch: null,

    newShoppingTripDates: null,
    setNewShoppingTripDates: (dates) => set({ newShoppingTripDates: dates }),
    onCloseRecipeBox: () => {
      set((state) => {
        return {
          recipeBoxSearch: {
            ...(state.recipeBoxSearch ?? initialSearchOptions),
            dinnerDate: undefined,
            ingredients: [],
          },
        };
      });
    },
  };
});
const createCombinedSlice: StateCreator<
  MealPlanCalendarState & RecipeBoxSlice,
  [['zustand/immer', never]],
  []
> = (...a) => ({
  ...createMealPlanCalendarSlice(...a),
  ...createRecipeBoxSlice(...a),
});

export const createMealPlanCalendarStore = createStore(() =>
  subscribeWithSelector(immer(createCombinedSlice)),
);

export const useMealPlanCalendarStore = create<
  MealPlanCalendarState & RecipeBoxSlice
>()(
  devtools(subscribeWithSelector(immer(createCombinedSlice)), {
    name: 'dt.meal-plan-calendar-store',
    trace: process.env.NODE_ENV === 'development',
  }),
);
