export function getProfileIconColor(profileId: string) {
  return `rgb(${redColorValue(profileId)},${greenColorValue(
    profileId,
  )},${blueColorValue(profileId)})`;
}

export function redColorValue(profileId: string) {
  return colorHashFunction(profileId, 2531);
}

export function greenColorValue(profileId: string) {
  return colorHashFunction(profileId, 2521);
}

export function blueColorValue(profileId: string) {
  return colorHashFunction(profileId, 2113);
}

export function colorHashFunction(profileId: string = '0', prime: number) {
  const key = (profileId.match(/(\d+)$/) ?? ['0'])[0];

  return (parseInt(key) * prime) % 255;
}
