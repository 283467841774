import { useAuthenticatedAxios } from '@/context/api-auth';
import { formatDate } from '@/utils/format-date';
import { AxiosInstance } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { unknown } from 'zod';
import { Household } from '../use-household';
import { HouseholdProfile } from '../use-household-profiles';
import { HouseholdDinner } from '../use-household-dinner';
import { useCallback, useReducer } from 'react';

export interface MutationVars {
  recipeId: number;
  dinnerId: number;
  isMainDish: boolean;
  householdId: number;
}

function getKey() {
  return ['add-recipe-to-dinner'] as const;
}

type MutationOptionsWithoutFn<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = Omit<
  MutationOptions<TData, TError, TVariables, TContext>,
  'mutationFn' | 'mutationKey'
>;

async function fetchAddRecipeToDinner(
  axios: AxiosInstance,
  vars: MutationVars,
) {
  return axios.post('api/v1/household_dinner_dish', {
    household: `/api/v1/household/${vars.householdId}`,
    household_dinner: `/api/v1/household_dinner/${vars.dinnerId}`,
    status_code: 'A',
    is_main_dish: vars.isMainDish,
    recipe: `/api/v1/recipe/${vars.recipeId}`,
  });
}
export function useAddRecipeToDinner(
  options: MutationOptionsWithoutFn<
    unknown,
    unknown,
    MutationVars,
    unknown
  > = {},
) {
  const { axios, isAuthenticated } = useAuthenticatedAxios();
  const mut = useMutation(
    getKey(),
    async (vars: MutationVars) => {
      return (await fetchAddRecipeToDinner(axios, vars)).data;
    },
    { ...options },
  );
  return mut;
}

interface AddRecipeToDateVars {
  householdId: number;
  profileId: number;
  standingShoppingTrip: string;
  dinnerDate: Date;
  recipeId: number;
}

async function fetchAddRecipeToDate(
  axios: AxiosInstance,
  vars: AddRecipeToDateVars,
) {
  const dinner = await axios.post<{ id: number }>(`api/v1/household_dinner`, {
    household: `/api/v1/household/${vars.householdId}`,
    profile: `/api/v1/household_profile/${vars.profileId}`,
    dinner_date: formatDate(vars.dinnerDate),
    shopping_trip: vars.standingShoppingTrip,
    status_code: 'A',
  });
  return fetchAddRecipeToDinner(axios, {
    recipeId: vars.recipeId,
    isMainDish: false,
    dinnerId: dinner.data.id,
    householdId: vars.householdId,
  });
}

export function useAddRecipeToDate(
  options: MutationOptionsWithoutFn<
    unknown,
    unknown,
    AddRecipeToDateVars,
    unknown
  > = {},
) {
  const { axios, isAuthenticated } = useAuthenticatedAxios();
  const mut = useMutation(
    getKey(),
    async (vars: AddRecipeToDateVars) => {
      return (await fetchAddRecipeToDate(axios, vars)).data;
    },
    { ...options },
  );
  return mut;
}
