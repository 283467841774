import { Box } from '@/components/base/box';
import { Flex } from '@/components/base/flex';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
export const StyledNutritionFactValue = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const StyledNutritionFact = styled(Flex)(({ theme, ...props }) => ({
  fontSize: theme.fontSizes['4xs'],
  borderRadius: theme.radii['xs'],
  padding: `${theme.space['xs']}px`,
  background: '#fafafa',
  border: `1px solid ${theme.colors.white}`,
  flexDirection: 'column',
  flex: 1,
  whiteSpace: 'nowrap',
  margin: '0 0.25em',
  textAlign: 'center',
  color: theme.colors.textGray20,
  display: 'none',

  '&.has-warning-yellow': {
    border: `2px solid ${theme.colors.trafficLightOrange}`,
  },

  '&:hover': {
    background: 'rgba(256, 256, 256, 0.6)',
  },

  [theme.mediaQueries.m]: {
    display: 'flex',
  },
}));

const StyledNutritionFactName = styled(Box)`
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes['6xs']};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export interface NutritionFactProps {
  factValue: ReactNode;
  factName: ReactNode;
  hasWarning?: undefined | 'yellow';
}

export function NutritionFact({
  factValue,
  factName,
  hasWarning,
}: NutritionFactProps) {
  let classes: string[] = [];
  switch (hasWarning) {
    case 'yellow':
      classes.push('has-warning-yellow');
      break;
  }

  return (
    <StyledNutritionFact className={classes.join(' ')}>
      <StyledNutritionFactValue>{factValue}</StyledNutritionFactValue>
      <StyledNutritionFactName>{factName}</StyledNutritionFactName>
    </StyledNutritionFact>
  );
}
