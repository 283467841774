/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { HouseholdSubscription } from '../models';
// @ts-ignore
import { PaginatedHouseholdSubscriptionList } from '../models';
// @ts-ignore
import { PatchedHouseholdSubscription } from '../models';
/**
 * V1HouseholdsubscriptionApi - axios parameter creator
 * @export
 */
export const V1HouseholdsubscriptionApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {HouseholdSubscription} householdSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionCreate: async (
      householdSubscription: HouseholdSubscription,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'householdSubscription' is not null or undefined
      assertParamExists(
        'subscriptionCreate',
        'householdSubscription',
        householdSubscription,
      );
      const localVarPath = `/api/v1/subscription/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        householdSubscription,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionDestroy: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('subscriptionDestroy', 'id', id);
      const localVarPath = `/api/v1/subscription/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionList: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/subscription/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {PatchedHouseholdSubscription} [patchedHouseholdSubscription]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionPartialUpdate: async (
      id: number,
      patchedHouseholdSubscription?: PatchedHouseholdSubscription,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('subscriptionPartialUpdate', 'id', id);
      const localVarPath = `/api/v1/subscription/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedHouseholdSubscription,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('subscriptionRetrieve', 'id', id);
      const localVarPath = `/api/v1/subscription/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {HouseholdSubscription} householdSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionUpdate: async (
      id: number,
      householdSubscription: HouseholdSubscription,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('subscriptionUpdate', 'id', id);
      // verify required parameter 'householdSubscription' is not null or undefined
      assertParamExists(
        'subscriptionUpdate',
        'householdSubscription',
        householdSubscription,
      );
      const localVarPath = `/api/v1/subscription/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication cookieAuth required

      // authentication jwtAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tastyPieAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        householdSubscription,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V1HouseholdsubscriptionApi - functional programming interface
 * @export
 */
export const V1HouseholdsubscriptionApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    V1HouseholdsubscriptionApiAxiosParamCreator(configuration);
  return {
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {HouseholdSubscription} householdSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionCreate(
      householdSubscription: HouseholdSubscription,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSubscription>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.subscriptionCreate(
          householdSubscription,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionDestroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.subscriptionDestroy(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionList(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedHouseholdSubscriptionList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.subscriptionList(
          limit,
          offset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {PatchedHouseholdSubscription} [patchedHouseholdSubscription]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionPartialUpdate(
      id: number,
      patchedHouseholdSubscription?: PatchedHouseholdSubscription,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSubscription>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.subscriptionPartialUpdate(
          id,
          patchedHouseholdSubscription,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSubscription>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.subscriptionRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {HouseholdSubscription} householdSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionUpdate(
      id: number,
      householdSubscription: HouseholdSubscription,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HouseholdSubscription>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.subscriptionUpdate(
          id,
          householdSubscription,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V1HouseholdsubscriptionApi - factory interface
 * @export
 */
export const V1HouseholdsubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V1HouseholdsubscriptionApiFp(configuration);
  return {
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {HouseholdSubscription} householdSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionCreate(
      householdSubscription: HouseholdSubscription,
      options?: any,
    ): AxiosPromise<HouseholdSubscription> {
      return localVarFp
        .subscriptionCreate(householdSubscription, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionDestroy(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .subscriptionDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionList(
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<PaginatedHouseholdSubscriptionList> {
      return localVarFp
        .subscriptionList(limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {PatchedHouseholdSubscription} [patchedHouseholdSubscription]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionPartialUpdate(
      id: number,
      patchedHouseholdSubscription?: PatchedHouseholdSubscription,
      options?: any,
    ): AxiosPromise<HouseholdSubscription> {
      return localVarFp
        .subscriptionPartialUpdate(id, patchedHouseholdSubscription, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<HouseholdSubscription> {
      return localVarFp
        .subscriptionRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * A viewset that provides the standard actions get_quaryset household__user = request.user
     * @param {number} id A unique integer value identifying this household subscription.
     * @param {HouseholdSubscription} householdSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionUpdate(
      id: number,
      householdSubscription: HouseholdSubscription,
      options?: any,
    ): AxiosPromise<HouseholdSubscription> {
      return localVarFp
        .subscriptionUpdate(id, householdSubscription, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for subscriptionCreate operation in V1HouseholdsubscriptionApi.
 * @export
 * @interface V1HouseholdsubscriptionApiSubscriptionCreateRequest
 */
export interface V1HouseholdsubscriptionApiSubscriptionCreateRequest {
  /**
   *
   * @type {HouseholdSubscription}
   * @memberof V1HouseholdsubscriptionApiSubscriptionCreate
   */
  readonly householdSubscription: HouseholdSubscription;
}

/**
 * Request parameters for subscriptionDestroy operation in V1HouseholdsubscriptionApi.
 * @export
 * @interface V1HouseholdsubscriptionApiSubscriptionDestroyRequest
 */
export interface V1HouseholdsubscriptionApiSubscriptionDestroyRequest {
  /**
   * A unique integer value identifying this household subscription.
   * @type {number}
   * @memberof V1HouseholdsubscriptionApiSubscriptionDestroy
   */
  readonly id: number;
}

/**
 * Request parameters for subscriptionList operation in V1HouseholdsubscriptionApi.
 * @export
 * @interface V1HouseholdsubscriptionApiSubscriptionListRequest
 */
export interface V1HouseholdsubscriptionApiSubscriptionListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof V1HouseholdsubscriptionApiSubscriptionList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof V1HouseholdsubscriptionApiSubscriptionList
   */
  readonly offset?: number;
}

/**
 * Request parameters for subscriptionPartialUpdate operation in V1HouseholdsubscriptionApi.
 * @export
 * @interface V1HouseholdsubscriptionApiSubscriptionPartialUpdateRequest
 */
export interface V1HouseholdsubscriptionApiSubscriptionPartialUpdateRequest {
  /**
   * A unique integer value identifying this household subscription.
   * @type {number}
   * @memberof V1HouseholdsubscriptionApiSubscriptionPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedHouseholdSubscription}
   * @memberof V1HouseholdsubscriptionApiSubscriptionPartialUpdate
   */
  readonly patchedHouseholdSubscription?: PatchedHouseholdSubscription;
}

/**
 * Request parameters for subscriptionRetrieve operation in V1HouseholdsubscriptionApi.
 * @export
 * @interface V1HouseholdsubscriptionApiSubscriptionRetrieveRequest
 */
export interface V1HouseholdsubscriptionApiSubscriptionRetrieveRequest {
  /**
   * A unique integer value identifying this household subscription.
   * @type {number}
   * @memberof V1HouseholdsubscriptionApiSubscriptionRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for subscriptionUpdate operation in V1HouseholdsubscriptionApi.
 * @export
 * @interface V1HouseholdsubscriptionApiSubscriptionUpdateRequest
 */
export interface V1HouseholdsubscriptionApiSubscriptionUpdateRequest {
  /**
   * A unique integer value identifying this household subscription.
   * @type {number}
   * @memberof V1HouseholdsubscriptionApiSubscriptionUpdate
   */
  readonly id: number;

  /**
   *
   * @type {HouseholdSubscription}
   * @memberof V1HouseholdsubscriptionApiSubscriptionUpdate
   */
  readonly householdSubscription: HouseholdSubscription;
}

/**
 * V1HouseholdsubscriptionApi - object-oriented interface
 * @export
 * @class V1HouseholdsubscriptionApi
 * @extends {BaseAPI}
 */
export class V1HouseholdsubscriptionApi extends BaseAPI {
  /**
   * A viewset that provides the standard actions get_quaryset household__user = request.user
   * @param {V1HouseholdsubscriptionApiSubscriptionCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1HouseholdsubscriptionApi
   */
  public subscriptionCreate(
    requestParameters: V1HouseholdsubscriptionApiSubscriptionCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1HouseholdsubscriptionApiFp(this.configuration)
      .subscriptionCreate(requestParameters.householdSubscription, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * A viewset that provides the standard actions get_quaryset household__user = request.user
   * @param {V1HouseholdsubscriptionApiSubscriptionDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1HouseholdsubscriptionApi
   */
  public subscriptionDestroy(
    requestParameters: V1HouseholdsubscriptionApiSubscriptionDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1HouseholdsubscriptionApiFp(this.configuration)
      .subscriptionDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * A viewset that provides the standard actions get_quaryset household__user = request.user
   * @param {V1HouseholdsubscriptionApiSubscriptionListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1HouseholdsubscriptionApi
   */
  public subscriptionList(
    requestParameters: V1HouseholdsubscriptionApiSubscriptionListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return V1HouseholdsubscriptionApiFp(this.configuration)
      .subscriptionList(
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * A viewset that provides the standard actions get_quaryset household__user = request.user
   * @param {V1HouseholdsubscriptionApiSubscriptionPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1HouseholdsubscriptionApi
   */
  public subscriptionPartialUpdate(
    requestParameters: V1HouseholdsubscriptionApiSubscriptionPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1HouseholdsubscriptionApiFp(this.configuration)
      .subscriptionPartialUpdate(
        requestParameters.id,
        requestParameters.patchedHouseholdSubscription,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * A viewset that provides the standard actions get_quaryset household__user = request.user
   * @param {V1HouseholdsubscriptionApiSubscriptionRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1HouseholdsubscriptionApi
   */
  public subscriptionRetrieve(
    requestParameters: V1HouseholdsubscriptionApiSubscriptionRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1HouseholdsubscriptionApiFp(this.configuration)
      .subscriptionRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * A viewset that provides the standard actions get_quaryset household__user = request.user
   * @param {V1HouseholdsubscriptionApiSubscriptionUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1HouseholdsubscriptionApi
   */
  public subscriptionUpdate(
    requestParameters: V1HouseholdsubscriptionApiSubscriptionUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V1HouseholdsubscriptionApiFp(this.configuration)
      .subscriptionUpdate(
        requestParameters.id,
        requestParameters.householdSubscription,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
