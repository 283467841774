/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { RecengineTokenObtain } from '../models';
// @ts-ignore
import { TokenRefresh } from '../models';
// @ts-ignore
import { TokenVerify } from '../models';
/**
 * V2TokenApi - axios parameter creator
 * @export
 */
export const V2TokenApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {RecengineTokenObtain} recengineTokenObtain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenCreate: async (
      recengineTokenObtain: RecengineTokenObtain,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'recengineTokenObtain' is not null or undefined
      assertParamExists(
        'tokenCreate',
        'recengineTokenObtain',
        recengineTokenObtain,
      );
      const localVarPath = `/api/v2/token/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        recengineTokenObtain,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenRefreshCreate: async (
      tokenRefresh: TokenRefresh,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenRefresh' is not null or undefined
      assertParamExists('tokenRefreshCreate', 'tokenRefresh', tokenRefresh);
      const localVarPath = `/api/v2/token/refresh/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tokenRefresh,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} tokenVerify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenVerifyCreate: async (
      tokenVerify: TokenVerify,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenVerify' is not null or undefined
      assertParamExists('tokenVerifyCreate', 'tokenVerify', tokenVerify);
      const localVarPath = `/api/v2/token/verify/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tokenVerify,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V2TokenApi - functional programming interface
 * @export
 */
export const V2TokenApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = V2TokenApiAxiosParamCreator(configuration);
  return {
    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {RecengineTokenObtain} recengineTokenObtain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenCreate(
      recengineTokenObtain: RecengineTokenObtain,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RecengineTokenObtain>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tokenCreate(
        recengineTokenObtain,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenRefreshCreate(
      tokenRefresh: TokenRefresh,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenRefreshCreate(
          tokenRefresh,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} tokenVerify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenVerifyCreate(
      tokenVerify: TokenVerify,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenVerify>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenVerifyCreate(tokenVerify, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V2TokenApi - factory interface
 * @export
 */
export const V2TokenApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V2TokenApiFp(configuration);
  return {
    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {RecengineTokenObtain} recengineTokenObtain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenCreate(
      recengineTokenObtain: RecengineTokenObtain,
      options?: any,
    ): AxiosPromise<RecengineTokenObtain> {
      return localVarFp
        .tokenCreate(recengineTokenObtain, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenRefreshCreate(
      tokenRefresh: TokenRefresh,
      options?: any,
    ): AxiosPromise<TokenRefresh> {
      return localVarFp
        .tokenRefreshCreate(tokenRefresh, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} tokenVerify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenVerifyCreate(
      tokenVerify: TokenVerify,
      options?: any,
    ): AxiosPromise<TokenVerify> {
      return localVarFp
        .tokenVerifyCreate(tokenVerify, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for tokenCreate operation in V2TokenApi.
 * @export
 * @interface V2TokenApiTokenCreateRequest
 */
export interface V2TokenApiTokenCreateRequest {
  /**
   *
   * @type {RecengineTokenObtain}
   * @memberof V2TokenApiTokenCreate
   */
  readonly recengineTokenObtain: RecengineTokenObtain;
}

/**
 * Request parameters for tokenRefreshCreate operation in V2TokenApi.
 * @export
 * @interface V2TokenApiTokenRefreshCreateRequest
 */
export interface V2TokenApiTokenRefreshCreateRequest {
  /**
   *
   * @type {TokenRefresh}
   * @memberof V2TokenApiTokenRefreshCreate
   */
  readonly tokenRefresh: TokenRefresh;
}

/**
 * Request parameters for tokenVerifyCreate operation in V2TokenApi.
 * @export
 * @interface V2TokenApiTokenVerifyCreateRequest
 */
export interface V2TokenApiTokenVerifyCreateRequest {
  /**
   *
   * @type {TokenVerify}
   * @memberof V2TokenApiTokenVerifyCreate
   */
  readonly tokenVerify: TokenVerify;
}

/**
 * V2TokenApi - object-oriented interface
 * @export
 * @class V2TokenApi
 * @extends {BaseAPI}
 */
export class V2TokenApi extends BaseAPI {
  /**
   * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
   * @param {V2TokenApiTokenCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2TokenApi
   */
  public tokenCreate(
    requestParameters: V2TokenApiTokenCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2TokenApiFp(this.configuration)
      .tokenCreate(requestParameters.recengineTokenObtain, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   * @param {V2TokenApiTokenRefreshCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2TokenApi
   */
  public tokenRefreshCreate(
    requestParameters: V2TokenApiTokenRefreshCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2TokenApiFp(this.configuration)
      .tokenRefreshCreate(requestParameters.tokenRefresh, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
   * @param {V2TokenApiTokenVerifyCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2TokenApi
   */
  public tokenVerifyCreate(
    requestParameters: V2TokenApiTokenVerifyCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return V2TokenApiFp(this.configuration)
      .tokenVerifyCreate(requestParameters.tokenVerify, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
