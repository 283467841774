import AuthService from '@/services/auth-service';
import { getApiClient } from 'api-client/axios';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { isNil } from 'lodash';
import { PaginatedListV1 } from '@/types/api-models';
import { useAuthenticatedAxios } from '@/context/api-auth';
import { PaginationV2 } from '@/types/api-models/v2';
import { omit } from 'lodash';

type AllObjectsApiProps = {
  endpoint: string;
  enabled?: boolean;
  /**
   * Optional, if set, limit to the given number of pages.
   * Prevents
   */
  maxPages?: number;
};

export interface FetchAllPagesResult<T> {
  objects: T[];
  totalCount: number;
}

// non-recursive version of `loadAllPages`
/**
 * Makes successive GET requests on a resource that returns paginated
 * responses until all pages have been retrieved, or the number of pages
 * fetched excedes `maxPages` parameter.
 *
 * this helper is designed for paginated responses using under the
 * v1 api. /api/v2/ resources use a separate pagination schema
 * not supported by this function
 * @param axios axios client object
 * @param url Request URI
 * @param options Additional Axios request options
 * @param maxPages Maximum number of page fetch requests needed
 * to be performed
 * @param defaultLimit The limit parameter to be used when fetching pages
 * . Defaults to 1000 to minimize the number of pages needed to be fetched
 * @returns
 */
export async function fetchAllPagesV1WithCount<T = any>(
  axios: AxiosInstance,
  url: string,
  options: AxiosRequestConfig,
  maxPages?: number,
  defaultLimit: number = 1000,
): Promise<FetchAllPagesResult<T>> {
  const objects: T[] = [];
  let params: AxiosRequestConfig['params'];
  if (
    options.params instanceof URLSearchParams ||
    typeof options.params === 'string'
  ) {
    const urlParams = new URLSearchParams(options.params);
    if (!urlParams.has('limit')) {
      urlParams.set('limit', defaultLimit.toString(10));
    }
    params = urlParams;
  } else {
    params = {
      limit: defaultLimit,
      ...options.params,
    };
  }
  let resp = await axios.get<PaginatedListV1<T>>(url, {
    ...options,
    params,
  });
  objects.push(...resp.data.objects);
  let nextPage = resp.data.meta.next;
  for (let i = 1; isNil(maxPages) || i < maxPages; ++i) {
    if (!nextPage || nextPage === url) {
      break;
    }
    resp = await axios.get(nextPage, {
      ...options,
      params: {
        /// note: we omit the `params` from given in the `options` object
        // because user-provided parameters are included in data.meta.next
      },
    });
    nextPage = resp.data.meta.next;
    objects.push(...resp.data.objects);
  }
  const totalCount = resp.data.meta.total_count;

  return { objects, totalCount };
}

/**
 * Makes successive GET requests on a resource that returns paginated
 * responses until all pages have been retrieved, or the number of pages
 * fetched excedes `maxPages` parameter.
 *
 * this helper is designed for paginated responses using under the
 * v1 api. /api/v2/ resources use a separate pagination schema
 * not supported by this function
 * @param axios axios client object
 * @param url Request URI
 * @param options Additional Axios request options
 * @param maxPages Maximum number of page fetch requests needed
 * to be performed
 * @param defaultLimit The limit parameter to be used when fetching pages
 * . Defaults to 1000 to minimize the number of pages needed to be fetched
 * @returns
 */
export async function fetchAllPagesV1<T = any>(
  axios: AxiosInstance,
  url: string,
  options: AxiosRequestConfig,
  maxPages?: number,
  defaultLimit: number = 1000,
): Promise<T[]> {
  const { objects } = await fetchAllPagesV1WithCount(
    axios,
    url,
    options,
    maxPages,
    defaultLimit,
  );
  return objects;
}

export const useAllObjectsData = <T = any>({
  endpoint,
  enabled = true,
  maxPages,
}: AllObjectsApiProps) => {
  const [path, queryParams] = endpoint.split('?');
  const { axios, isAuthenticated } = useAuthenticatedAxios();
  const {
    data: result = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['query-api-authenticated-paginated', path, queryParams],
    () => fetchAllPagesV1<T>(axios, endpoint, {}),
    {
      enabled,
    },
  );

  return { data: result, isLoading, isError, refetch };
};

export async function fetchAllPagesV2<T>(
  axios: AxiosInstance,
  endpoint: string,
  options: AxiosRequestConfig,
  maxPages: number = 10,
) {
  let allResults: T[] = [];
  const firstPageRes = await axios.get<PaginationV2<T>>(endpoint, options);
  let currentPage = firstPageRes.data;
  const optionsWithoutParams = omit(options, 'params');
  for (let page = 1; page < maxPages; ++page) {
    allResults.push(...currentPage.results);
    if (!currentPage.next) {
      break;
    }
    currentPage = (await axios.get(currentPage.next, optionsWithoutParams))
      .data;
  }
  return allResults;
}
