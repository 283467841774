import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTrash } from '@fortawesome/free-solid-svg-icons';

import { NavSubItem } from '@/components/navigation-bar/NavSubItem';
import { useMealPlanRangeStore } from '@/store/meal-plan-range-store';
import { ShoppingTrip, useShoppingTrips } from '@/hooks/use-shopping-trips';
import {
  firstDayOfWeek,
  formatDate,
  nextDate,
  previousDate,
} from '@/utils/format-date';
import { Flex } from '@/components/base/flex';
import { Text } from '@/components/base/text';
import styled from '@emotion/styled';
import { ClassNames, useTheme } from '@emotion/react';
import { ShoppingTripTitle } from './ShoppingTripTitle';
import { Button } from '../base/buttons';

const StyledListAction = styled(Flex)`
  cursor: pointer;
  padding: ${({ theme }) => theme.space['4xs']}px
    ${({ theme }) => theme.space.s}px;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.colors.navItemActionBackground};
    border-radius: ${({ theme }) => theme.radii['xs']};
  }

  .list-action-icon {
    color: ${({ theme }) => theme.colors.primaryLight200};
  }
`;

StyledListAction.defaultProps = {
  alignItems: 'center',
};

type NavShoppingListProps = {
  onRemoveTrip: (trip: ShoppingTrip) => void;
};

export const NavShoppingList: FC<NavShoppingListProps> = ({ onRemoveTrip }) => {
  const theme = useTheme();

  const startDate = previousDate(firstDayOfWeek(new Date()), 7);
  const endDate = nextDate(startDate, 27);
  const { shoppingTrips } = useShoppingTrips(startDate, endDate);

  return (
    <ClassNames>
      {({ css }) => (
        <>
          {shoppingTrips
            .sort((trip1, trip2) => (trip1.date > trip2.date ? -1 : 1))
            .map((trip) => (
              <NavSubItem
                key={trip.id}
                className={css`
                  &:hover {
                    ${StyledListAction} {
                      .list-action-icon {
                        color: ${theme.colors.white};
                      }

                      &:hover {
                        .list-action-delete {
                          color: ${theme.colors.warningBtnHover};
                        }
                      }
                    }
                  }
                `}
                href={`/shopping_lists/${formatDate(trip.date)}`}
              >
                <Flex flexDirection="column" flexGrow="1">
                  <ShoppingTripTitle trip={trip} />
                  <Flex alignItems="center" justifyContent="space-around">
                    <StyledListAction>
                      <FontAwesomeIcon
                        className="list-action-icon"
                        icon={faList}
                        size="xs"
                      />
                      <Text ml="xs">View List</Text>
                    </StyledListAction>
                    <Button
                      variant="transparent"
                      onClick={(e) => {
                        e.preventDefault();
                        onRemoveTrip(trip);
                      }}
                    >
                      <StyledListAction>
                        <FontAwesomeIcon
                          className="list-action-icon list-action-delete"
                          icon={faTrash}
                          size="xs"
                        />
                        <Text ml="xs">Remove Trip</Text>
                      </StyledListAction>
                    </Button>
                  </Flex>
                </Flex>
              </NavSubItem>
            ))}
        </>
      )}
    </ClassNames>
  );
};
