import { useTheme } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import {
  Rating as StarRating,
  RatingProps as StarRatingProps,
} from 'react-simple-star-rating';
import { Flex } from '@/components/base/flex';
import { Box } from '@/components/base/box';
import { Text } from '@/components/base/text';
import styled from '@emotion/styled';

export interface RatingProps extends Pick<StarRatingProps, 'allowFraction'> {
  /** Inital rating to fill stars */
  value: number;

  /** Highlight filled stars with yellow color **/
  highlightFilled?: boolean;

  /** Callback when rating changes */
  onChange?: (value: number) => void;

  /** Additional class name */
  className?: string;

  /** Show a tooltip with live values */
  showTooltip?: boolean;

  /** Callback when clear rating */
  onClearRating?: () => void;

  /** Readonly means that the rate cannot change */
  readonly?: boolean;
  /**  */
  starRatingOptions?: Pick<StarRatingProps, 'allowFraction'>;
}

const StyledClearRating = styled(Box)`
  width: 9px;
  height: 18px;
  cursor: pointer;
`;

const StyledTooltip = styled(Text)`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.fontSizes['4xs']};
  line-height: ${({ theme }) => theme.lineHeights['11xs']};
  min-height: 12px;
  min-width: 120px;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  padding-top: ${({ theme }) => theme.space['2xs']}px;
  padding-left: ${({ theme }) => theme.space['3xs']}px;
  text-align: left;
  color: ${({ theme }) => theme.colors.textGray20};
`;

const tooltipArray = [
  'No thanks!',
  'Not a big fan.',
  `It's fine.`,
  'Like it.',
  'Love it!',
];

export const Rating = ({
  value,
  highlightFilled = false,
  onChange,
  className,
  showTooltip,
  onClearRating,
  readonly,
  allowFraction = true,
}: RatingProps) => {
  const theme = useTheme();
  const [rating, setRating] = useState(0);
  const [tooltipMessage, setTooltipMessage] = useState('');

  useEffect(() => {
    setRating(value);
  }, [value]);

  const onPointerMove = (newValue: number) => {
    const newRating = Math.ceil(newValue);
    if (!readonly) {
      setRating(newRating);
    }

    const tooltipIndex = newRating - 1;
    if (tooltipIndex <= tooltipArray?.length)
      setTooltipMessage(tooltipArray[tooltipIndex]);
    else setTooltipMessage(newRating.toString());
  };

  const onPointerLeave = () => {
    if (!readonly) {
      setRating(value);
    }
    setTooltipMessage('');
  };

  const wrappedOnChange = (value: number) => {
    if (onChange) {
      // round up to nearest whole number
      const roundedUp = Math.ceil(value);
      setRating(roundedUp);
      onChange(roundedUp);
    }
  };

  return (
    <Flex>
      {!readonly && (
        <StyledClearRating
          onMouseEnter={() => setTooltipMessage('Clear my rating')}
          onMouseLeave={() => setTooltipMessage('')}
          onClick={onClearRating}
        ></StyledClearRating>
      )}
      <Flex flexWrap={'wrap'} alignItems={'center'}>
        <StarRating
          {...{ allowFraction }}
          showTooltip={false}
          className={className}
          size={18}
          initialValue={rating}
          disableFillHover
          fillColor={
            highlightFilled
              ? theme.colors.ratingFillColor
              : theme.colors.ratingInitialFillColor
          }
          emptyColor={theme.colors.ratingEmptyColor}
          onPointerMove={onPointerMove}
          onPointerLeave={onPointerLeave}
          onClick={wrappedOnChange}
          data-testid="rating"
        />
        {showTooltip && <StyledTooltip>{tooltipMessage}</StyledTooltip>}
      </Flex>
    </Flex>
  );
};
